@import 'variables.scss';
@import 'mixin';

.news-wrap {
  background-color: $bg-col-white;
  margin-bottom: 20px;

  &.news-wrap-v2 {
    .publication {
      justify-content: flex-end;
      color: $col-manatee;

      @media screen and (max-width: 480px) {
        justify-content: unset;
      }

      &__date {
        margin-left: 5px;
      }
    }

    .title {
      @media screen and (max-width: 480px) {
        margin: 0;
      }
    }
  }

  .container {
    max-width: 1170px;
    margin: 0 auto;
  }

  &__header {
    padding: 35px 0;

    .wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      margin-right: 30px;

      h1 {
        font-family: $Manrope-ExtraBold;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: unset;
        margin: 0;
      }
    }
  }

  .publication {
    display: flex;
    justify-content: space-between;
    max-width: 223px;
    width: 100%;

    &__date {
      color: $col-manatee;
    }

    &__views-wrap {
      display: flex;
      align-items: center;
    }

    &__views {
      font-family: $Manrope-Bold;
      color: $col-sapphire;
      margin-right: 20px;
    }
  }

  .bonuses {
    display: flex;
    align-items: center;
    font-family: $Manrope-Bold;
    color: $col-sapphire;
    margin: 10px 0;

    &.bonuses-with-registration {
      justify-content: space-between;

      .progress-bar {
        color: #0a2352;
        display: flex;
        align-items: center;
        position: relative;
        font-family: $Manrope-Bold;

        .bar {
          position: relative;
          display: block;
          width: 100px;
          height: 10px;
          background: #e6ebf2;
          margin-right: 15px;

          &::after {
            content: '';
            display: block;
            width: var(--progress, 0);
            max-width: 100%;
            height: 10px;
            background-color: $bg-col-dodger-blue;
            position: absolute;
          }
        }
      }
    }

    &__wrap {
      display: flex;
      align-items: center;
    }

    .indicator {
      position: relative;
      width: 150px;
      height: 30px;
      background-color: $bg-col-solitude;
      margin-right: 20px;

      .progress {
        transition: 0.3s;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        width: 0;
        max-width: 100%;
        height: 100%;
        background-color: $bg-col-dodger-blue;
      }

      &__progress {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    p {
      margin: 0 10px 0 0;
      font-size: 16px;
    }

    .current-bonus {
      font-size: 20px;

      &__currency {
        font-size: 16px;
        font-family: $Manrope-Regular;
      }
    }
  }
}

.preloader-wrap {
  height: 60px;
  width: 100%;
  @include centering-mod-h;
}

.news-detail {
  font-size: 16px;
  font-family: $Manrope-Medium;
  color: $col-sapphire;

  &__img-wrap {
    position: relative;

    .discount {
      max-width: max-content;
      top: 10px;
      left: 10px;
    }

    img {
      width: 100%;
      display: block;
      height: auto;
      margin-bottom: 21px;
    }
  }

  &__news-cnt {
    padding-bottom: 10px;
  }

  .map-wrap {
    position: relative;
    padding-bottom: 50%; // This is the aspect ratio
    height: 0;
    overflow: hidden;

    @media screen and (max-width: 480px) {
      padding-bottom: 100%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.news-cnt {
  line-height: 30px;

  .marker {
    background-color: yellow;
  }

  a {
    //color: $col-blueLight;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:visited {
      //color: $col-blue;
    }
  }

  &.container {
    max-width: 800px;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $col-sapphire;
    font-family: $Manrope-ExtraBold;
    margin-bottom: 40px;
  }

  h2 {
    font-size: 19px;
    line-height: 30px;
  }

  h3 {
    font-size: 18px;
    line-height: 30px;
  }

  > img {
    margin: 0 40px 30px 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  p {
    line-height: 30px;
    margin-bottom: 40px;
  }

  figure {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  figcaption {
    padding: 15px 0;
    width: 100%;
    min-height: 60px;

    font-family: $Manrope-Bold;
    font-size: 14px;
    text-transform: uppercase;
    color: $col-manatee;

    background-color: #ebeef2;
    border-radius: 0 0 2px 2px;
  }

  > ol,
  > ul {
    margin: 0 40px 30px 0;

    a {
      color: $col-dodger-blue;
    }
  }

  > ol {
    list-style-type: decimal;
    list-style-position: outside;
    margin-left: 18px;

    li {
      padding-left: 15px;
    }

    ol {
      list-style-type: lower-alpha;

      li {
        position: relative;

        &:before {
          content: ')';
          position: absolute;
          top: 0;
          left: -3px;
        }
      }
    }
  }

  > ul {
    li {
      position: relative;
      padding-left: 33px;

      &:before {
        content: '';
        position: absolute;
        top: 12px;
        left: 1px;

        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $bg-col-dodger-blue;
      }
    }
  }

  blockquote {
    text-align: center;
    background-color: #ebeef2;
    border-radius: 2px;
    padding: 40px 42px 35px;
    margin: 41px 0 39px;

    p {
      margin-bottom: 13px;

      &:last-child {
        font-size: 14px;
        text-transform: uppercase;
        color: $col-manatee;
        margin-bottom: 2px;
      }
    }
  }

  &__news-slider {
    float: left;
  }

  .news-slider {
    max-width: 260px;
    width: 100%;
    margin: 0 41px 12px 0;

    ul {
      &:first-child {
        margin-bottom: 20px;

        li {
          img {
            max-width: 260px !important;
            width: 100% !important;
            height: 150px !important;
          }
        }
      }

      &:last-child {
        position: relative;
        max-width: 210px;
        margin-left: 29px;

        li {
          &.slick-current {
            opacity: 1;
          }

          opacity: 0.2;

          img {
            object-fit: cover;
            width: 60px;
            height: 60px;
          }
        }
      }

      li {
        &:before {
          display: none;
        }
      }
    }

    .slick-arrow {
      position: absolute;
      width: 20px;
      height: 20px;
      border: none;
      top: 20px;
      background-color: transparent;
    }

    .slick-prev,
    .slick-next {
      &:before,
      &:after {
        content: '';
        position: absolute;

        width: 10px;
        height: 2px;
        background-color: black;
      }
    }

    .slick-prev {
      left: -28px;

      &:before {
        top: 6px;
        left: 4px;
        transform: rotate(137deg);
      }

      &:after {
        top: 12px;
        left: 4px;
        transform: rotate(42deg);
      }
    }

    .slick-next {
      right: -20px;

      &:before {
        top: 6px;
        left: 6px;
        transform: rotate(45deg);
      }

      &:after {
        top: 12px;
        left: 6px;
        transform: rotate(133deg);
      }
    }
  }
}

.triple-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  &__item {
    width: 31%;
  }

  &__img-wrap {
    height: 200px;
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }
}

.double-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    max-width: 48.3%;
    width: 100%;
  }

  &__img-wrap {
    margin-bottom: 10px;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1024px) {
  .news-detail {
    &__news-cnt {
      padding-bottom: 0;
    }

    > img {
      margin-bottom: 20px;
    }
  }

  .news-cnt {
    p {
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 20px;
    }

    figure {
      margin: 0 0 20px;
    }

    ul,
    ol {
      margin-bottom: 26px;
    }
  }
}

@media screen and (max-width: 768px) {
  .news-cnt {
    > img {
      margin: 0 0 20px;
    }

    blockquote {
      margin: 41px 0 20px;
    }
  }

  .triple-block {
    margin: 0 auto;
  }

  .double-block {
    &__img-wrap {
      margin-top: 56px;
    }
  }
}

@media screen and (max-width: 767px) {
  .triple-block {
    &__item {
      width: 48.2%;
    }
  }
}

@media screen and (max-width: 600px) {
  .news-slider {
    float: unset;
    margin: 56px auto 20px;
  }

  .double-block {
    flex-direction: column;
    align-items: center;

    &__item {
      max-width: 360px;
    }
  }

  .double-list {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .news-wrap {
    &__header {
      .wrap {
        flex-direction: column;
        align-items: unset;
      }

      .title {
        margin: 0 0 20px;
      }
    }

    .bonuses {
      align-items: unset;
      flex-direction: column;

      margin: 20px 0 0 0;

      &__wrap {
        margin-top: 20px;
      }

      .indicator {
        width: 100px;
      }
    }
  }

  .news-cnt {
    > ul {
      li {
        padding-left: 20px;
      }
    }
  }

  .triple-block {
    &__item {
      width: 100%;
    }

    .news-cnt__text {
      padding: 0;
    }
  }
}
