.margin-50 {
  margin: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.pointer-events {
  &__none {
    pointer-events: none;
  }
}

.opacity-02 {
  opacity: 0.2;
}

.col-text-sunglow {
  color: $col-sunglow;
}

.text-initial {
  text-transform: initial;
}

.svg-shrink-0 {
  svg {
    flex-shrink: 0;
  }
}

.text-lowercase {
  text-transform: lowercase;
}

.relative {
  position: relative;
}
