@import 'variables.scss';
@import 'mixin';

.details-goods-wrap {
  &.tec-doc {
    .details-goods {
      grid-template-areas: 'goods-info goods-info original-numbers' 'characteristics applied original-numbers';

      grid-template-columns: 0.83fr 0.83fr 1fr;
    }

    .original-numbers {
      padding-bottom: 60px;
      margin-bottom: 0;

      .cnt-wrap {
        max-height: 460px;
      }

      .list {
        li {
          margin-bottom: 0;
        }

        &__title {
          max-width: 120px;

          font-family: $Manrope-Bold;
          color: $col-sapphire;
          text-transform: uppercase;
        }

        .no-cnt {
          .list__title {
            width: 220px;
            font-family: $Manrope-Regular;
            color: $col-manatee;
            max-width: unset;
            text-transform: none;
          }
        }
      }
    }

    .characteristics {
      .cnt-wrap {
        max-height: 200px;
      }
    }

    .applied {
      .list {
        &__title {
          max-width: 100px;

          font-family: $Manrope-Bold;
          color: $col-sapphire;
        }

        &__value {
          font-family: $Manrope-Regular;
          color: $col-dodger-blue;
        }
        li {
          margin-bottom: 0;
        }

        .no-cnt {
          .list__title {
            width: 220px;
            font-family: $Manrope-Regular;
            color: $col-manatee;
            max-width: unset;
          }
        }
      }
    }

    .about-product {
      &.active {
        .cnt-wrap {
          max-height: unset;
        }
      }
    }
  }

  .description {
    margin-bottom: 20px;
  }
}

.details-goods {
  display: grid;
  grid-template-areas: 'goods-info characteristics' 'description characteristics';
  grid-template-columns: 1.69fr 1fr;
  grid-template-rows: [goods-info] 240px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
}

.about-product {
  position: relative;
  padding-bottom: 60px;
  min-height: 320px;

  height: 100%;
  background-color: $bg-col-white;

  margin-bottom: 0;

  &__title {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6ebf2;
    height: 60px;
    padding: 0 20px;

    h4 {
      font-family: $Manrope-Regular;
      margin: 0;
    }
  }

  .cnt-wrap {
    max-height: 200px;
    overflow: hidden;
  }

  .list {
    padding: 20px;
    color: $col-manatee;

    background-color: $bg-col-white;

    .no-cnt {
      font-family: $Manrope-Regular;
      color: $col-manatee;

      .list__title {
        width: 220px;
        max-width: 220px;
      }
    }

    li {
      display: flex;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      max-width: 190px;
      width: 100%;
      margin: 0 20px 0 0;
    }

    &__value {
      font-family: $Manrope-Bold;
      color: $col-sapphire;
      width: 100%;
      margin: 0;
      button {
        transition: 0.3s;
        padding: 0 5px 0 0;
        &:hover {
          color: $col-sapphire;
        }
        &:after {
          content: ',';
        }

        &:last-child {
          padding: 0;
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__desc {
    min-height: 200px;
    overflow: hidden;

    color: $col-manatee;
    padding: 20px;

    p {
      margin-top: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-review {
    right: 20px;
    bottom: 20px;
  }

  &.active {
    .cnt-wrap {
      position: absolute;
      z-index: 9;
      width: 100%;
      max-height: unset;

      box-shadow: 0 10px 22px -7px black;

      background-color: $bg-col-white;
    }

    .list {
      padding-bottom: 60px;
    }

    .about-product {
      &__desc {
        padding-bottom: 60px;
        height: unset;
      }
    }
  }
}

.goods-info {
  display: flex;
  justify-content: space-between;
  grid-area: goods-info;
  background-color: $bg-col-white;
  padding: 20px 10px 20px 20px;

  .without-capacity {
    .goods-info {
      &__buy-wrapper {
        width: 60%;
      }
    }

    .brand {
      width: 40%;
    }
  }

  &__buy-wrapper {
    width: 44%;
    order: 6;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .buy {
      margin-left: 20px;
    }

    .product-price {
      justify-content: unset;
      &__cost {
        white-space: nowrap;
      }
    }
  }

  .product-img {
    @include centering-mod-h;
    position: relative;

    max-width: 300px;
    width: 100%;

    margin-right: 15px;

    .discount {
      width: 130px;
      height: 30px;
    }

    .small-squares {
      position: absolute;
      bottom: 0;
      left: 0;
      flex-direction: unset;
      align-items: unset;

      &__item {
        margin: 0 4px 0 0;
      }
    }
  }

  &__code-wrap {
    order: 2;
    width: 70%;
    display: flex;
  }

  &__right-side {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;

    color: $col-sapphire;

    z-index: 999;
  }

  .column {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 60px;
  }

  .favorites {
    position: absolute;
    top: -16px;
    right: -5px;
    z-index: 99;
  }

  .product-name {
    order: 1;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    font-family: $Manrope-Bold;
    font-size: 20px;
    line-height: 30px;
    width: 90%;
    align-items: unset;
    height: unset;
  }

  .vendor-code {
    display: flex;
    align-items: center;
    margin-right: 15px;

    &__v-code {
      max-width: 140px;
    }

    .btn-copy {
      margin: 0 10px 0 20px;
    }
  }

  .analogues {
    .btn {
      width: 180px;
      height: 30px;
    }
  }

  .number-product {
    order: 3;
    width: 30%;
    justify-content: flex-end;

    &__wrapper {
      display: flex;
      justify-content: flex-end;
      margin: 0 -5px;
    }

    &__product-count {
      margin: 5px;
    }
  }

  .brand {
    order: 4;
    display: flex;
    align-items: center;

    font-family: $Manrope-Bold;
    text-transform: uppercase;

    width: 26%;

    &__img {
      margin-right: 20px;
    }
  }

  .volume {
    order: 5;
    width: 30%;
    min-width: 100px;
    position: relative;
    left: unset;
    top: unset;
    flex-direction: unset;
    flex-wrap: wrap;

    background-color: $bg-col-white;
  }

  .product-price {
    font-size: 20px;
    font-family: $Manrope-Bold;

    &__cost {
      margin-right: 8px;
    }

    &__currency {
      font-size: 14px;
    }
  }

  .buy {
    min-width: 120px;

    &__wrap {
      position: relative;
      display: flex;
    }

    .buy-count {
      max-width: 90px;
      width: 100%;
      flex-shrink: 1;

      font-family: $Manrope-Bold;
      color: $col-manatee;
      background-color: $bg-col-solitude;
    }

    .count {
      cursor: pointer;
      position: absolute;
      top: -12px;
      right: -11px;
      width: 30px;
      height: 30px;
      background-color: $bg-col-red;
      border-radius: 50%;
      color: $col-white;
      @include centering-mod-h;

      &__close {
        display: none;
      }

      &:hover {
        font-size: 0;

        .count {
          &__close {
            display: block;
          }
        }
      }

      &.disabled {
        background-color: $bg-col-solitude;
        cursor: default;
        opacity: 1;
        pointer-events: none;
        position: absolute;
      }
    }
  }
}

.original-numbers {
  grid-area: original-numbers;

  .list {
    button {
      color: $col-dodger-blue;
    }
  }
}

.characteristics {
  grid-area: characteristics;
  .cnt-wrap {
    max-height: 460px;
  }
}

.description {
  min-height: 320px;
  grid-area: description;
  background-color: $bg-col-white;
}

.inf-brand {
  background-color: $bg-col-white;

  &__title {
    display: flex;
    align-items: center;

    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #e6ebf2;

    h4 {
      font-family: $Manrope-Regular;
      margin: 0;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;

    color: $col-manatee;
    padding: 20px;
  }

  &__left-wrap {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;

    max-width: 650px;
    width: 100%;
    height: max-content;
    margin-right: 20px;
  }

  &__img-wrap {
    max-width: 190px;
    width: 100%;
    margin-right: 20px;

    @include centering-mod-h;
  }

  &__desc {
    position: relative;
    max-width: 1150px;
    min-height: 200px;
    width: 100%;
    padding-bottom: 35px;

    .text-wrap {
      position: relative;
      max-height: 200px;
      overflow: hidden;
      &.active {
        height: auto;
        max-height: unset;
        &:before {
          display: none;
        }
      }
    }

    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.brand-details {
  &__name {
    display: block;
    margin-bottom: 20px;

    font-family: $Manrope-Bold;
    font-size: 16px;
    color: $col-sapphire;
  }

  .lists-wrap {
    a {
      color: $col-dodger-blue;
      &:hover {
        color: $col-sapphire;
      }
    }
  }

  .list,
  .list-links {
    &__item {
      display: flex;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      min-width: 110px;
      max-width: 110px;
      margin: 0 20px 0 0;
      overflow-wrap: anywhere;
    }

    &__value {
      font-family: $Manrope-Bold;
      color: $col-sapphire;

      overflow-wrap: anywhere;

      width: 100%;

      margin: 0;
    }
  }

  .list {
    margin-bottom: 20px;
  }

  .list-links {
    &__item {
      margin-bottom: 0;
    }
  }
}

.components-cars {
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  width: 100%;

  &__wrap {
    background-color: $bg-col-white;
    box-shadow: 0 0 150px 20px #e5e5e5;
  }

  .btn-close {
    width: 60px;
    height: 60px;
    background-color: transparent;
    &:hover {
      background-color: $bg-col-red;
      path {
        fill: white;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 20px;
    min-height: 60px;

    border-bottom: 1px solid #e6ebf2;
    h3 {
      margin: 0;
    }
  }

  .components-list {
    &__wrap {
      overflow: auto;
      max-height: 487px;
    }

    .titles-row,
    .cnt-row {
      padding: 0;
      min-height: 60px;
      p {
        margin: 0;
      }

      .label-wrap {
        width: max-content;
      }
    }

    .col-title,
    .column {
      padding: 18px 20px;
    }

    .cnt-row {
      font-family: $Manrope-Bold;
    }

    .model-title,
    .model {
      width: 36%;
    }
    .release-title,
    .release {
      width: 30%;
    }

    .model-title,
    .model {
      min-width: 152px;
    }

    .modification-title,
    .modification {
      width: 34%;
      a {
        color: $col-dodger-blue;
      }
    }

    .release-title,
    .release {
      min-width: 175px;
    }
  }
}

@media screen and (max-width: 1836px) {
  .goods-info {
    &__buy-wrapper {
      width: 48%;
    }

    .vendor-code {
      .btn-copy {
        margin: 0 5px 0 10px;
      }
    }

    .brand {
      width: 32%;
    }

    .volume {
      width: 20%;
    }
  }
}

@media screen and (max-width: 1535px) {
  .details-goods-wrap {
    &.tec-doc {
      .original-numbers {
        .cnt-wrap {
          max-height: 500px;
        }
      }
    }
  }

  .characteristics {
    .cnt-wrap {
      max-height: 500px;
    }
  }

  .details-goods {
    grid-template-rows: [goods-info] 280px;
  }

  .goods-info {
    &__code-wrap {
      width: 100%;
    }

    &__buy-wrapper {
      width: 59%;
    }

    .number-product {
      order: 4;
      width: 50%;
    }

    .brand {
      width: 50%;
      order: 3;
    }

    .volume {
      width: 41%;
    }

    .without-capacity {
      .goods-info {
        &__buy-wrapper {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .details-goods-wrap {
    &.tec-doc {
      .details-goods {
        grid-template-areas: 'goods-info goods-info' 'characteristics applied' 'original-numbers original-numbers';
        grid-template-columns: 1fr 1fr;
      }

      .about-product {
        .cnt-wrap {
          max-height: 200px;
        }
      }
    }
  }

  .details-goods {
    grid-template-areas: 'goods-info goods-info' 'characteristics description';
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;

    .description {
      margin-right: 0;
    }
  }

  .characteristics {
    .cnt-wrap {
      max-height: 200px;
    }
  }

  .goods-info {
    &__buy-wrapper {
      justify-content: flex-end;
      width: 44%;
    }

    &__code-wrap {
      width: 70%;
    }

    .product-name {
      width: 90%;
    }

    .number-product {
      order: 3;
      width: 30%;
    }

    .brand {
      width: 26%;
    }

    .volume {
      width: 30%;
      min-width: 100px;
    }

    .buy {
      .buy-count {
        width: 100%;
        flex-shrink: 1;
      }
    }

    .without-capacity {
      .goods-info {
        &__buy-wrapper {
          justify-content: flex-end;
          width: 60%;
        }
      }

      .brand {
        width: 40%;
      }
    }
  }

  .goods-info {
    margin-right: 0;
  }

  .inf-brand {
    &__wrap {
      flex-direction: column;
    }

    &__left-wrap {
      max-width: 100%;
      margin-right: 0;
    }

    .brand-details {
      width: 100%;
      .lists-wrap {
        display: flex;

        .list {
          width: 65%;
          margin-right: 20px;
        }
        .list-links {
          width: 35%;
          min-width: 280px;
        }
      }
    }
  }

  .components-cars {
    .components-list {
      &__wrap {
        max-height: 360px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .details-goods {
    grid-template-rows: [goods-info] 300px;
  }

  .goods-info {
    .column {
      height: 62px;
    }

    .product-name {
      margin-bottom: 10px;
    }
  }

  .components-cars {
    max-width: 700px;
  }
}

@media screen and (max-width: 1023px) {
  .goods-info {
    &__code-wrap {
      width: 100%;
    }

    &__buy-wrapper {
      width: 59%;
    }
    .number-product {
      order: 4;
      width: 50%;
    }

    .brand {
      width: 50%;
      order: 3;
    }

    .volume {
      width: 41%;
    }
    .without-capacity {
      .goods-info {
        &__buy-wrapper {
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .brand-details {
    .lists-wrap {
      .list-links {
        &__value {
          overflow-wrap: anywhere;
        }
      }
    }
  }
}

@media screen and (max-width: 959px) {
  .inf-brand {
    &__img-wrap {
      max-width: 100%;
      margin-bottom: 20px;
    }

    &__left-wrap {
      flex-direction: column;
    }
    .brand-details {
      .lists-wrap {
        .list-links {
          width: 35%;
          min-width: 280px;
        }
      }
    }
    .brand-details {
      margin-bottom: 40px;

      .lists-wrap {
        flex-direction: column;

        .list {
          width: 100%;
          margin-bottom: 20px;
        }

        .list-links {
          width: 100%;
          min-width: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 853px) {
  .goods-info {
    .volume {
      width: 31%;
      min-width: 120px;
    }

    .vendor-code {
      margin-right: 20px;

      .btn-copy {
        margin: 0 5px 0 15px;
      }
    }

    &__buy-wrapper {
      width: 68%;
    }
  }
}

@media screen and (max-width: 767px) {
  .details-goods-wrap {
    &.tec-doc {
      .details-goods {
        grid-template-areas: 'goods-info' 'characteristics' 'applied' 'original-numbers';
        grid-template-columns: 1fr;
      }

      .original-numbers {
        padding-bottom: 60px;
        margin-bottom: 0;
      }

      .goods-info {
        .number-product {
          width: 100%;
        }

        .brand {
          width: 100%;
        }
      }
    }
  }

  .details-goods {
    grid-template-areas: 'goods-info goods-info' 'characteristics characteristics' 'description description';
    grid-template-rows: unset;
  }
  .goods-info {
    position: relative;
    flex-direction: column;
    padding: 20px 10px;

    &.tec-doc {
      .brand {
        width: 100%;
      }
    }

    &__code-wrap {
      flex-direction: column;
      align-items: unset !important;

      .vendor-code {
        margin-bottom: 20px;
      }
    }

    .product-img {
      max-width: 100%;
      padding: 0 10px 100px;
      margin-right: 0;

      .small-squares {
        display: none;
      }

      .discount {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .discount {
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
    &__right-side {
      max-width: 100%;
      position: unset;
    }

    .column {
      width: 100%;
      height: auto;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .favorites {
      top: 0;
      right: 0;
    }

    .volume {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 999;

      flex-direction: column;
      width: 50px;
      min-width: unset;
      height: auto;
      padding: 0;
    }
  }

  .components-cars {
    max-width: 600px;
  }
}

@media screen and (max-width: 767px) {
  .goods-info {
    &__buy-wrapper {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 600px) {
  .components-cars {
    &__wrap {
      overflow: hidden;
    }

    .components-list {
      overflow: auto;

      .titles-row {
        min-width: 600px;
      }

      &__wrap {
        max-height: 230px;
        min-width: 600px;
      }
    }
  }
}
