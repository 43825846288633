@import 'variables.scss';
@import 'mixin';

@media screen and (max-width: 1680px) {
  .cat-grid-l-fil {
    .description-list {
      top: 100px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .cat-grid-l-fil {
    .description-list {
      top: 53px;
    }
  }
}

@media screen and (max-width: 1540px) {
  .description-list {
    top: 53px;
  }
}

@media screen and (max-width: 1440px) {
  .cat-grid-l-fil {
    .description-list {
      top: 60px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .user-profile-info {
    width: 320px;
    position: fixed;
    top: 0;
    height: 100vh;
    bottom: 0;
    right: 60px;
    &__name {
      font-family: $Manrope-Medium;
      text-align: center;
      margin-bottom: 30px;
    }
    &__code {
      font-family: $Manrope-Regular;
      text-align: center;
    }
    &__email {
      text-align: center;
      margin-bottom: 30px;
    }

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 56px;
      height: 56px;
      z-index: 9999;
      background-color: transparent;
    }

    .profile-footer__wrap {
      margin-top: 40px;
    }

    .user-profile-info__wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .user-profile-info-arrears,
    .user-profile-info-overdue {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      &__text {
        width: 100%;
        min-height: 60px;
        padding-right: 10px;
        font-family: $Manrope-Regular;
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
        }
      }
      &__sum {
        width: 100%;
        height: 60px;
        @include centering-mod-h;
        &-red {
          background-color: #ff0000;
        }
      }

      .user-balance {
        &-default {
          color: $col-white !important;
        }
        &-red {
          color: $col-red !important;
        }
      }
    }
  }

  .pagination-wrap {
    justify-content: center;
  }
}

@media screen and (max-width: 960px) {
  .alice-carousel__prev-btn {
    bottom: 0;
    right: 60px;
  }
  .alice-carousel__next-btn {
    bottom: 0;
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  .break {
    width: 0;
    display: none;
    a {
      width: 100%;
    }
  }
}

@media screen and (max-width: 760px) {
  .user-profile-info {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    width: 100%;

    &__name {
      font-family: $Manrope-Medium;
      text-align: center;
    }
    &__code {
      font-family: $Manrope-Regular;
      text-align: center;
    }
    &__email {
      text-align: center;
    }

    &-action-btn__wrap {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      z-index: 9999;
      .log-out {
        width: 60px;
        height: 60px;
        background-color: transparent;
        @include centering-mod-h;
      }
      .close-button {
        width: 60px;
        height: 60px;
        background-color: transparent;
        @include centering-mod-h;
      }
    }

    .profile-footer__wrap {
      position: relative;
      bottom: unset;
      right: unset;
      top: unset;
      width: 280px;
      margin: 0 auto;
      margin-top: 40px;
    }

    .user-profile-info-arrears,
    .user-profile-info-overdue {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .balance-info__row {
        justify-content: flex-start;
      }

      &__text {
        width: 100%;
        height: 60px;
        padding-right: 10px;
        font-family: $Manrope-Regular;
      }
      &__sum {
        width: 100%;
        height: 60px;
        @include centering-mod-h;
        &-red {
          background-color: #ff0000;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .m-hide,
  .previous-page,
  .next-page {
    width: 55px;
    height: 55px;
    a {
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 425px) {
  .nav-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
  }
}
