//begin fonts
$Manrope-Regular: 'Manrope-Regular';
$Manrope-Medium: 'Manrope-Medium';
$Manrope-Bold: 'Manrope-Bold';
$Manrope-ExtraBold: 'Manrope-ExtraBold';
//end fonts

//begin background-color
$bg-col-sapphire: #0a2352;
$bg-col-white: #ffffff;
$bg-col-solitude-l: #f0f3f7;
$bg-col-astronaut: #3b4f74;
$bg-col-red: #ff0000;
$bg-col-solitude: #e6ebf2;
$bg-col-mediumSeaGreen: #27b95b;
$bg-col-sunglow: #fecc30;
$bg-col-manatee: #8f94b3;
$bg-col-dodger-blue: #1d8eff;
$bg-col-gamboge: #e49b0f;
$bg-col-blue-stone: #175f55;
$bg-col-alice-blue: #f5f7fa;
$bg-col-swans-down: #d4f1de;
//end background-color

//begin color
$col-sunglow: #fecc30;
$col-mediumSeaGreen: #27b95b;
$col-white: #ffffff;
$col-astronaut: #3b4f74;
$col-sapphire: #0a2352;
$col-solitude: #e6ebf2;
$col-red: #ff0000;
$col-manatee: #8f94b3;
$col-dodger-blue: #1d8eff;
$col-gamboge: #e49b0f;
//end color
