@import 'variables.scss';
@import 'mixin';

@keyframes preloading {
  to {
    transform: rotate(365deg);
  }
}

.vehicle-info {
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 710px;
  width: 100%;
  @media screen and (max-width: 810px) {
    max-width: 630px;
    //@media screen and (max-height: 770px) {
    //  top: 200px;
    //}
  }

  @media screen and (max-width: 480px) {
    top: 150px;
    transform: translate(-50%, 0);
  }

  &__wrap {
    background-color: $bg-col-white;
    box-shadow: 0 0 150px 20px #e5e5e5;
  }

  .btn-close {
    width: 60px;
    height: 60px;
    background-color: transparent;

    &:hover {
      background-color: $bg-col-red;

      path {
        fill: white;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 20px;
    min-height: 60px;

    border-bottom: 1px solid #e6ebf2;

    h3 {
      margin: 0;
    }
  }

  &__cnt {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 40px;
    max-height: 560px;
    overflow: auto;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    @media screen and (max-width: 480px) {
      //top: 200px;
      @media screen and (max-height: 780px) {
        max-height: 390px;
      }

      @media screen and (max-height: 680px) {
        max-height: 335px;
      }

      @media screen and (max-height: 500px) {
        max-height: 200px;
      }
    }
  }

  .list {
    max-width: 300px;
    width: 100%;
    @media screen and (max-width: 600px) {
      max-width: unset;
    }

    &:first-child {
      margin-right: 30px;
      @media screen and (max-width: 600px) {
        margin-right: 0;
      }
    }

    .item {
      display: flex;
      &__title,
      &__value {
        max-width: 140px;
        width: 100%;
        @media screen and (max-width: 600px) {
          max-width: unset;
          width: 50%;
        }

        p {
          margin: 10px 0;
        }
      }

      &__title {
        font-family: $Manrope-Medium;
        color: $col-manatee;
        margin-right: 20px;
      }

      &__value {
        font-family: $Manrope-Bold;
        color: $bg-col-sapphire;
      }
    }
  }
}

.loading-wrap {
  width: 60px;
  height: 60px;
  @include centering-mod-h;

  .loading-anim {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-left: 4px solid #0a2352;
    border-right: 4px solid #0a2352;
    border-top: 4px solid #0a2352;
    border-bottom: 4px solid #e6ebf2;
    animation: preloading 2s linear infinite;
  }
}

.nav-bottom {
  &.catalog-cart-auto {
    .nav-bottom {
      &__left {
        justify-content: space-between;
      }
    }
  }
}

.car-search {
  display: flex;
  flex-wrap: wrap;

  &__link-wrap {
    display: flex;
    width: 25%;
    min-height: 60px;
    border-right: 1px solid #e6ebf2;
    border-bottom: 1px solid #e6ebf2;
    background-color: $bg-col-white;
  }
}

.car-brand,
.car-model {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;

  font-family: $Manrope-Bold;
  text-transform: uppercase;
  color: $col-sapphire;
  padding: 15px;
  position: relative;

  &__img-wrap {
    width: 72px;
    position: relative;
    padding-top: 46px;
    margin-left: -15px;
    //margin-right: 20px;

    &.default {
      width: unset;
      padding-top: unset;
      img {
        width: 72px;
        // height: 26px;

        position: unset;
        left: unset;
        top: unset;
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
    }
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }
  &__production-years {
    font-family: $Manrope-Regular;
    position: absolute;
    bottom: 2px;
  }
}

.car-model {
  text-transform: none;
}

.filter-car {
  background-color: transparent;

  .img-car {
    width: 200px;
    position: relative;
    padding-top: 25.44%;
    margin: 15px auto 35px;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: auto;
    }
  }

  .filter-list {
    padding-bottom: unset;
  }

  .filter-car-list {
    background-color: $bg-col-white;
    margin-bottom: 20px;

    .sub-list {
      padding: 0 17px 20px 20px;

      &__item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__btns-wrap {
        display: flex;
      }
    }

    .select-wrap {
      font-family: $Manrope-Bold;
      color: $col-manatee;
      padding: 0 60px 0 20px;
      border-bottom: 1px solid #e6ebf2;

      input {
        border: none;
        width: 100%;
        &::placeholder {
          color: $col-manatee;
        }
      }

      &.selected {
        color: $col-sapphire;
        input {
          &::placeholder {
            color: inherit;
          }
        }
      }

      &:before {
        right: 25px;
      }

      &__list {
        width: 100%;
      }
    }

    .btn-close {
      @include centering-mod-h;
      width: 60px;
      height: 60px;
    }
  }

  .filter-car-result {
    position: relative;
    background-color: $bg-col-white;
    margin-bottom: 20px;

    .btn-inf {
      position: absolute;
      z-index: 99;
      right: 20px;
      top: 170px;
    }

    .sub-list {
      margin: 0;
      padding: 20px 17px 20px 20px;

      &__item {
        margin-bottom: 20px;
        font-family: $Manrope-Regular;
        color: $col-manatee;
        span {
          margin-left: 10px;
        }

        &:nth-child(2) {
          font-family: $Manrope-Bold;
          color: $col-sapphire;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__btns-wrap {
        display: flex;
      }
    }

    &__item {
      padding: 0 20px;
      border-bottom: 1px solid #e6ebf2;
    }

    &__btns-wrap {
      display: flex;
      padding: 0 20px 20px;
    }

    .btn-close {
      @include centering-mod-h;
      width: 100px;
      height: 60px;

      &:hover {
        color: $col-white;
      }
    }
  }

  .filter-list {
    background-color: $bg-col-white;

    //&__item {
    //  border: none;
    //  padding: 0;
    //  cursor: unset;
    //}

    .sub-list {
      margin: 0;
      max-height: 500px;
      overflow: auto;

      &__item {
        width: 100%;
        text-align: start;
        padding: 10px 17px 10px 20px;

        button {
          width: 100%;
          padding: 10px 17px 10px 20px;
          text-align: start;
        }

        strong,
        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        strong {
          color: $col-sapphire;
          text-transform: uppercase;
        }

        p {
          font-family: $Manrope-Regular;
          color: $col-manatee;
          margin: 0;
        }
      }
    }
  }
}

.table {
  width: 100%;
  background-color: $bg-col-white;

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6ebf2;
    min-height: 60px;
    padding: 0 10px;

    &__item {
      font-family: $Manrope-Regular;
      font-size: 14px;
      color: $col-sapphire;

      width: 13.7%;
      padding: 0 10px;
    }

    .modification-title {
      width: 17.85%;
    }
  }

  .link {
    width: 100%;
    text-align: unset;
    display: flex;
    align-items: center;
    min-height: 60px;

    font-family: $Manrope-Bold;
    color: $col-sapphire;

    border-bottom: 1px solid #e6ebf2;
    padding: 0 10px;

    &__item {
      width: 13.7%;
      padding: 0 10px;
    }

    .modification {
      width: 17.85%;
    }
  }

  .general-info {
    display: none;
  }
}

.category-tabs {
  .input-wrap {
    input {
      font-family: $Manrope-Bold;
      color: $col-manatee;
      width: calc(100% - 40px);
      height: 60px;

      border: none;
      border-bottom: 1px solid #e6ebf2;
      padding: 0 20px;
      &::placeholder {
        color: inherit;
      }
    }
  }

  .no-node-results {
    width: 100%;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $col-manatee;
    font-family: $Manrope-Regular;
  }

  .tab-list {
    overflow: hidden;

    .main-desc {
      font-family: $Manrope-Bold;
      @media screen and (max-width: 374px) {
        overflow-wrap: anywhere;
      }
    }

    a {
      display: flex;
      align-items: center;
      color: $col-sapphire;
      min-height: 60px;
    }

    li {
      min-height: 60px;
      background-color: $bg-col-white;
    }

    &__indicator {
      @include centering-mod-h;
      flex-shrink: 0;
      width: 60px;
      height: 60px;

      &.active {
        svg {
          transform: rotate(180deg);
        }
      }

      svg {
        transition: all 0.25s ease-in-out;
        transform: rotate(90deg);
      }
    }

    &__tab-img {
      @include centering-mod-h;
      margin-right: 24px;
    }

    &__desc {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      min-height: inherit;
      transition: 0.3s;

      &.active {
        color: $col-dodger-blue;
      }

      .node-btn {
        height: 60px;
        text-align: start;
        &:hover {
          color: $col-dodger-blue;
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: -50%;
        bottom: 0;
        width: 200%;
        height: 1px;

        background-color: $bg-col-solitude;
      }

      &:hover {
        color: $col-dodger-blue;
      }

      > .btn-square {
        margin-left: 27px;
      }

      &_hover-disabled {
        &:hover {
          color: unset;
        }
      }
    }

    &__sublist {
      padding-left: 60px;
    }

    .dropdown-slidedown {
      transition-duration: 0.2s;
      width: 100%;
      overflow: unset;
      overflow-y: clip;
    }

    &__link {
      padding-left: 60px;
    }
  }
}

//begin carSearch
@media screen and (max-width: 1365px) {
  .table {
    .header {
      display: none;
    }

    .link {
      // align-items: unset;
      flex-wrap: wrap;

      .modification {
        width: 33%;
      }

      .engine-volume {
        width: 17%;
      }

      &__item {
        width: 25%;
        display: none;
      }

      .general-info {
        display: block;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .car-search {
    &__link-wrap {
      width: 33.33%;
    }
  }
}

@media screen and (max-width: 1151px) {
  .nav-bottom {
    &.catalog-cart-auto {
      .nav-bottom {
        &__left {
          flex-direction: column;
          justify-content: unset;
          align-items: unset;
          .select-wrap {
            width: max-content;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .filter-car {
    .filter-car-result,
    .filter-car-list,
    .filter-list {
      width: 50%;
      margin-bottom: 0;

      .sub-list {
        overflow: auto;
        // display: none;
        width: 320px;
        height: 100%;
        max-height: unset;
        position: fixed;
        z-index: 99999;
        top: 0;

        background-color: $bg-col-white;
        padding: 0 0 20px;
        margin: 0;

        &_left {
          left: 0;
        }

        &_right {
          right: 0;
        }

        &__title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 60px;

          color: $col-sapphire;
          font-family: $Manrope-Bold;

          margin-bottom: 35px;
          border-bottom: 1px solid #e6ebf2;

          .btn-close {
            background-color: transparent;

            &:hover {
              svg {
                path {
                  fill: blue;
                }
              }
            }
          }
        }

        &__wrap {
          margin-bottom: 20px;
        }
      }
    }

    .filter-car-result {
      .sub-list {
        //display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__item {
          padding: 0 20px;
        }
      }
    }

    .filter-list {
      .sub-list {
        //display: block;
        &__title {
          margin: 0;
        }

        &__item {
        }

        .btn-close {
          width: 30px;
          padding: 0;
        }
      }
    }

    .filter-car-list {
      .sub-list {
        //display: block;
        &__title {
          padding: 0 20px;
          margin-bottom: 0;
        }
      }
    }

    .btn-display {
      font-family: $Manrope-Bold;
      color: $col-sapphire;
      justify-content: center;
      text-align: center;

      .indicator {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .nav-bottom {
    .back {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .car-search {
    &__link-wrap {
      width: 100%;
    }
  }

  .car-brand,
  .car-model {
    &__img-wrap {
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 480px) {
  .table {
    .list {
      .link {
        &__item {
          width: 100%;
        }

        .modification {
          width: 100%;
        }
      }
    }
  }

  .category-tabs {
    .tab-list {
      &__sublist {
        padding-left: 10px;
      }

      > .btn-square {
        margin-left: 15px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .car-search {
    &__link-wrap {
      width: 100%;
    }
  }

  .car-brand,
  .car-model {
    &__img-wrap {
      margin-right: 20px;
    }
  }
}

//end carSearch
