.loyalty-program {
  font-family: $Manrope-Bold;
  margin-bottom: 20px;

  .item-unread {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    top: 24px;
    left: 5px;
  }

  .loyalty-columns {
    .col-title,
    .column {
      position: relative;
      &:first-child {
        width: 26.18%;
      }

      &:nth-child(2) {
        width: 13.09%;
        min-width: 180px;
        span {
          &:first-child {
            font-size: 20px;
            margin-right: 4px;
          }
          &:last-child {
            font-family: $Manrope-Medium;
          }
        }
      }

      &:nth-child(3) {
        width: 15.71%;
        min-width: 230px;
      }

      &:nth-child(4) {
        width: 33.51%;
      }

      &:last-child {
        width: 11.49%;
        min-width: 200px;
        text-align: end;
        a {
          color: $col-dodger-blue;
          &:hover {
            color: $col-sapphire;
          }
        }
      }
    }
    &.end .column:not(:nth-child(3)):not(:last-child) {
      opacity: 0.2;
    }

    &.end {
      .column:nth-child(3) {
        color: $bg-col-red;
      }
    }
    .cut-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 1600px) {
  .loyalty-program {
    .delivery-information-title,
    .delivery-information {
      width: 40%;
    }
  }
}

@media screen and (max-width: 1279px) {
  .loyalty-program {
    overflow: hidden;
    overflow-x: auto;

    .titles-row,
    .cnt-row {
      min-width: 1280px;
    }
  }
}
