@import 'variables.scss';
@import 'mixin';

.page-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px);

  font-family: $Manrope-Medium;
  color: $col-sapphire;
  text-align: center;
  .wrap {
  }

  &__title {
    h1 {
      position: relative;
      font-family: $Manrope-ExtraBold;
      z-index: 1;
      font-size: 160px;
      line-height: 100px;
      margin-bottom: 30px;
      &:before {
        content: '404';
        position: absolute;
        z-index: -1;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);

        font-size: 300px;
        line-height: 210px;
        color: $col-white;
      }
    }
  }

  &__text {
    margin-bottom: 50px;
  }

  &__btn-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  &__feedback {
    a {
      color: $col-sapphire;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 760px) {
  .page-error {
    height: calc(100vh - 160px);
  }
}

@media screen and (max-width: 480px) {
  .page-error {
    &__title {
      h1 {
        font-size: 100px;
        line-height: 90px;
        &:before {
          font-size: 165px;
          line-height: 140px;
        }
      }
    }

    &__text {
      margin-bottom: 30px;
    }

    &__btn-wrap {
      margin-bottom: 20px;
    }
  }
}
