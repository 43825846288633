@import 'variables.scss';
@import 'mixin';

.dashboard {
  .slider-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.1%;
  }

  .alice-carousel__stage-item {
    padding: 1px;
  }

  .slider {
    &:first-child {
      width: 49.35%;
    }
    &:last-child {
      width: 49.7%;
    }
    position: relative;
    max-height: 420px;

    &__list {
      li {
        a {
          display: block;
          width: 100%;
          height: 100%;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    &__btns-wrap {
      position: absolute;
      right: 20px;
      bottom: 20px;

      display: flex;

      .btn-square {
        background-color: $bg-col-white;

        &:hover {
          background-color: $bg-col-solitude;

          @media screen and (max-width: 1023px) {
            background-color: $bg-col-white;
          }

          svg {
            path {
              fill: #0a2352;
            }
          }
        }
      }
    }
  }

  .info-blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.54%;

    &__item {
      position: relative;
      width: 23.84%;
      min-height: 420px;
      margin: 0 0.54% 1.1%;
    }

    .manager.dark {
      background-color: #3b4f74;
      color: #fff;

      .manager {
        &__title h3 {
          color: #fff;
        }
        &__img-wrap {
          opacity: 0.1;
        }
      }
    }

    .manager,
    .trading-agent {
      &__title {
        position: relative;
        z-index: 10;

        h3 {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      &__photo-wrap {
        position: absolute;
        top: 20%;
        right: 50%;
        transform: translateX(50%);
        @include centering-mod-h;
        width: 220px;
        height: 220px;
        border-radius: 50%;
        border: 30px solid #e6ebf2;

        img {
          border-radius: 50%;
        }
      }

      .manager-data,
      .trading-agent-data {
        position: relative;
        z-index: 99;

        &__full-name {
          margin-bottom: 20px;
        }

        &__phone {
          display: block;
          cursor: pointer;
          font-family: $Manrope-ExtraBold;
          font-size: 20px;
          line-height: 30px;
        }

        .vendor-code {
          display: flex;
          align-items: center;

          margin-right: 15px;

          a {
            color: $col-dodger-blue;
            overflow-wrap: anywhere;
          }

          .btn-copy {
            margin-right: 10px;
          }
        }
      }

      &__bottom {
        @include flex-space-between-mod;

        .btn {
          width: 140px;
          min-width: 140px;
        }
      }
    }

    .trading-agent {
      &__photo-wrap {
        &:before {
          content: '';
          position: absolute;
          width: 135%;
          height: 135%;
          border-radius: 50%;
          box-shadow: 0 0 0 0 #f5f7fa;
          transition: 0.3s;
        }
      }

      &:hover {
        .trading-agent {
          &__photo-wrap {
            &:before {
              box-shadow: 0 0 0 14px #f5f7fa;
            }
          }
        }
      }
    }

    .manager {
      &__img-wrap {
        position: absolute;
        top: 46%;
        right: 0;
        transform: translateY(-50%);

        img {
          display: block;
        }
      }
    }

    .arrears-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background-color: $bg-col-white;
      color: $col-sapphire;
      padding: 20px;

      &__img-wrap {
        position: absolute;
        top: 46%;
        right: 0;
        transform: translateY(-50%);
      }

      &__title {
        position: relative;
        z-index: 10;

        h3 {
          margin: 0 0 26px 0;
        }
      }

      .arrears,
      .overdue {
        position: relative;
        z-index: 99;

        max-width: 180px;
        width: 100%;
        flex-direction: column;
        align-items: unset;

        p {
          padding: 0;
          text-align: unset;
          margin-bottom: 20px;
        }
      }

      &__overdue {
        margin-right: 30px;
      }

      .arrears {
        display: flex;
        flex-direction: unset;
        justify-content: space-between;

        max-width: 100%;

        &__block {
          max-width: 180px;
          width: 100%;
        }

        &__link-wrap {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          color: $col-dodger-blue;
          transition: 0.3s;
        }

        a {
          color: $col-dodger-blue;
        }
      }

      &__overdue {
        margin-right: 30px;
      }

      .arrears {
        &__wrap {
          font-size: 20px;
        }
      }

      .overdue {
        &__wrap {
          font-size: 20px;
          text-align: unset;
          color: $col-sapphire;

          padding: 0;
          background-color: transparent;

          .balance-info__row {
            justify-content: flex-start;
            font-size: 14px;
            padding: 0;
          }

          &_col-gamboge {
            color: $col-gamboge;
          }

          &_col-red {
            color: $col-red;
          }
        }

        &__text {
          max-width: 225px;
          width: 100%;
          margin-bottom: 25px !important;
        }
      }

      &__top {
        display: flex;
        position: relative;
        z-index: 99;
        // margin-bottom: 55px;
      }

      &:hover {
        .arrears {
          &__link-wrap {
            color: $col-sapphire;
          }
        }
      }
    }
  }

  .list-recomm-goods {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.54%;

    &__item {
      position: relative;
      width: 23.85%;
      min-height: 420px;
      margin: 0 0.54% 1.1%;

      .accumulative-shares {
        .title {
          h3 {
            font-size: 19px;
          }
        }

        &_plug {
          h3 {
            color: white;
            height: 100%;
            margin-top: 0;
          }
        }
      }
    }

    .accumulative-shares {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      background-color: $bg-col-astronaut;
      color: $col-white;
      padding: 20px;

      &__single-chart {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
      }

      &__btns-wrap {
        position: absolute;
        right: 20px;
        bottom: 20px;

        display: flex;

        .btn-square {
          background-color: $bg-col-white;

          &:hover {
            background-color: $bg-col-solitude;

            svg {
              path {
                fill: #0a2352;
              }
            }
          }
        }
      }

      &__title {
        position: relative;
        z-index: 10;

        max-width: 200px;
        width: 100%;

        h3 {
          margin-top: 0;
          color: $col-white;
        }
      }

      &__bottom {
        max-width: 260px;
        width: 100%;
      }

      &__desc {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        font-size: 16px;
        margin-bottom: 20px;
      }

      &__date-wrap {
        margin: 0;
      }
    }

    .recommended-products {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      overflow: hidden;

      color: $col-white;
      background-color: $bg-col-sapphire;
      padding: 20px;

      &__title {
        position: relative;
        z-index: 5;

        h3 {
          margin-top: 0;
          color: $col-white;
        }
      }

      &__img-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        max-width: 312px;
        width: 100%;
        height: 280px;

        .duplicate-img {
          filter: grayscale(100%);
          opacity: 0.2;
          z-index: 1;
        }

        img {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &__desc {
        position: relative;
        z-index: 5;
        max-width: 240px;
        width: 100%;
        margin-bottom: 0;
      }
    }

    .product-big-card,
    .product-smaller-card {
      display: flex;
      justify-content: space-between;
      width: 48.78%;
      max-width: 900px;
      background-color: $bg-col-white;
      color: $col-sapphire;
      padding: 20px;

      &.card-shadow {
        z-index: 999;
      }

      .right-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 50%;

        position: relative;
      }

      .product-img {
        @include centering-mod-h;

        &__link {
          position: relative;

          .small-squares {
            z-index: 99;
            position: absolute;
            top: unset;
            right: unset;
            left: 50%;
            bottom: -10px;
            transform: translateX(-50%) rotate(-90deg);
          }
        }

        .discount {
          width: 135px;
          font-size: 15px;
          padding: 9px;
          top: 20px;
          left: 20px;
        }
      }

      .favorites {
        position: absolute;
        top: 0;
        right: 0;
      }

      .product-name {
        max-width: 375px;
        width: 100%;

        font-family: $Manrope-ExtraBold;
        font-size: 20px;
        line-height: 30px;

        margin-bottom: 40px;

        &__text {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }

      .vendor-code {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
          margin-right: 20px;
        }

        .btn-copy {
          margin-right: 10px;
        }
      }

      .brand {
        display: flex;
        align-items: center;
        font-family: $Manrope-Bold;
        margin-bottom: 26px;

        &__img {
          margin-right: 20px;
        }

        &__img-wrap {
          margin-right: 20px;
        }

        &__text {
          text-transform: uppercase;
        }
      }

      &__wrap {
        @include flex-space-between-mod;
        margin-bottom: 35px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .product-info {
        &__text {
          display: none;
        }
      }

      .number-product {
        &__wrapper {
          display: flex;
          margin: 0 -5px;
        }

        &__product-count {
          margin: 5px;
        }
      }

      .product-price {
        width: 50%;
        font-size: 20px;
        justify-content: unset;

        margin-right: 15px;

        &__wrap {
          display: flex;
          flex-wrap: wrap;
        }

        &__cost {
          white-space: nowrap;
          margin-right: 10px;
        }

        &__currency {
          font-size: 14px;
        }
      }

      .buy {
        &__wrap {
          display: flex;
          position: relative;
        }

        .buy-count {
          max-width: 90px;
          background-color: $bg-col-solitude;
          border: none;
        }

        .count {
          width: 30px;
          height: 30px;
          top: -12px;
          right: -11px;
          cursor: pointer;
          color: $col-white;

          &:hover {
            font-size: 0;

            .count {
              &__close {
                display: block;
              }
            }
          }

          &__close {
            display: none;
          }
        }
      }
    }

    .product-big-card {
      .product-img {
        width: 50%;
        margin-right: 30px;
      }

      &__wrap {
        position: relative;
      }

      .promo-points {
        @media (min-width: 992px) {
          width: 60px;
          height: 60px;
        }
      }
    }

    .product-smaller-card {
      width: 23.84%;
      flex-direction: column;

      &__wrap {
        margin-bottom: 13px;
        position: relative;

        &:last-child {
          align-items: center;
          margin-bottom: 0;
        }
      }

      .product-img {
        margin: 0 0 13px 0;

        .discount {
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .product-name {
        max-width: 100%;
        display: flex;
        font-size: 16px;
        line-height: 20px;
        margin: 0;

        &__text {
          -webkit-line-clamp: 2;
        }
      }

      .brand {
        margin-bottom: 0;
      }

      .vendor-code {
        margin: 0 0 13px 0;
      }
    }
    @media screen and (max-width: 1279px) {
      .list-recomm-goods__item.promo-card.loyalty {
        order: -1;
      }
      .list-recomm-goods__item.promo-card.promotions {
        order: -2;
      }
    }
  }
}

///progress-circle
.single-chart {
  width: 200px;
  justify-content: space-around;
}

.circular-chart {
  display: block;
}

.circle-bg {
  fill: #0a2352;
  stroke: #ffffff;
  stroke-width: 5.8;
}

.circle {
  fill: none;
  stroke-width: 6;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart .circle {
  stroke: #1d8eff;
}

.percentage {
  fill: #ffffff;
  font-family: sans-serif;
  font-size: 0.3em;
  text-anchor: middle;
}

///progress-circle

@media screen and (max-width: 1720px) {
  .dashboard {
    .info-blocks {
      .trading-agent,
      .manager {
        .trading-agent-data,
        .manager-data {
          &__phone {
            margin-bottom: 20px;
          }

          .vendor-code {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }

        &:hover {
          .trading-agent {
            &__photo-wrap {
              &:before {
                box-shadow: 0 0 0 10px #f5f7fa;
              }
            }
          }
        }

        &__photo-wrap {
          top: 6%;
          right: 6.8%;
          transform: unset;
          width: 180px;
          height: 180px;

          border: 20px solid #e6ebf2;

          &:before {
            width: 128%;
            height: 128%;
          }

          img {
            width: 140px;
            height: 140px;
          }
        }

        &__bottom {
          flex-direction: column;
          align-items: unset;

          .btn {
            width: 100%;
          }
        }
      }

      .manager {
        &__img-wrap {
          top: 12.5%;
          transform: unset;

          img {
            width: 130px;
            height: 120px;
          }
        }
      }

      .arrears-block {
        &__title {
          h3 {
            margin-bottom: 23px;
          }
        }

        &__img-wrap {
          top: 12.5%;
          transform: unset;

          img {
            width: 130px;
            height: 150px;
          }
        }

        .arrears {
          > p {
            margin-top: 0;
          }
        }
      }
    }

    .list-recomm-goods {
      .accumulative-shares {
        .single-chart {
          width: 110px;
          top: 5.5%;
          right: 5.5%;
          transform: unset;
        }

        &__bottom {
          max-width: 100%;
          margin-bottom: 75px;
        }
      }

      .product-big-card {
        .product-name {
          &__text {
            margin-right: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .dashboard {
    .info-blocks {
      .title {
        h3 {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .accumulative-shares {
        .title {
          h3 {
            font-size: 15px;
          }
        }
      }

      .arrears-block {
        &__top {
          margin-bottom: -40px;
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .dashboard {
    .slider-wrap {
      margin-bottom: 1.582%;

      .slider {
        width: 49.2%;
      }
    }

    .info-blocks {
      margin: 0 -0.791%;

      .accumulative-shares {
        .title {
          h3 {
            font-size: 19px;
          }
        }
      }

      .title {
        h3 {
          font-size: 20px;
          line-height: 30px;
        }
      }

      &__item {
        width: 48.44%;
        margin: 0 0.78% 1.56%;
      }
    }

    .list-recomm-goods {
      margin: 0 -0.791%;

      &__item {
        margin: 0 0.78% 1.565%;

        &:nth-child(3) {
          // order: -1;
        }
      }

      .recommended-products,
      .accumulative-shares,
      .promo-card,
      .product-smaller-card {
        width: 48.43%;
      }

      .recommended-products,
      .accumulative-shares {
        order: -1;
      }

      .product-big-card {
        width: 100%;
        max-width: unset;

        .product-name {
          max-width: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard {
    .slider-wrap {
      margin-bottom: 2.165%;

      .slider {
        width: 48.9%;
      }
    }

    .info-blocks {
      margin: 0px -1.09%;

      &__item {
        width: 47.88%;
        margin: 0 1.06% 2.12%;
      }
    }

    .list-recomm-goods {
      margin: 0 -1.083%;

      &__item {
        margin: 0 1.061% 2.12%;
      }

      .recommended-products,
      .accumulative-shares,
      .promo-card,
      .product-smaller-card {
        width: 47.87%;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .dashboard {
    .slider-wrap {
      flex-direction: column;
      margin-bottom: 0;

      .slider {
        width: 100%;
        margin-bottom: 20px;

        &__btns-wrap {
          right: 0;
          bottom: 0;
        }
      }
    }

    .info-blocks {
      .arrears-block {
        .arrears,
        .overdue {
          height: unset;

          > p {
            display: block;
          }
        }

        .arrears {
          &__wrap {
            width: 85%;
            padding: 20px 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    .info-blocks {
      margin: 0px -1.498%;

      &__item {
        width: 47.08%;
        margin: 0 1.46% 2.932%;
      }

      .arrears-block {
        .overdue {
          &__wrap {
            width: unset;
            padding: 20px 5px;
          }
        }
      }
    }

    .list-recomm-goods {
      margin: 0px -1.498%;

      &__item {
        margin: 0 1.46% 2.932%;
      }

      .recommended-products,
      .accumulative-shares,
      .promo-card,
      .product-smaller-card {
        width: 47.08%;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .dashboard {
    .info-blocks {
      &__item {
        width: 100%;
        margin: 0 0 20px;
      }
    }

    .list-recomm-goods {
      &__item {
        width: 100%;
        margin: 0 0 20px;
      }
    }

    .list-recomm-goods {
      &__item {
        width: 100%;
        margin: 0 0 20px;
      }

      .recommended-products,
      .accumulative-shares,
      .promo-card,
      .product-big-card,
      .product-smaller-card {
        width: 100%;
      }

      .product-big-card {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .dashboard {
    .info-blocks {
      .trading-agent,
      .manager {
        &__photo-wrap {
          width: 160px;
          height: 160px;
          border: 10px solid #e6ebf2;
        }

        &:hover {
          .trading-agent,
          .manager {
            &__photo-wrap {
              &:before {
                box-shadow: unset;
              }
            }
          }
        }
      }
    }
  }
}

.promo-card {
  background: #0a2352;
  padding: 20px;
  color: $col-white;

  .alice-carousel {
    height: 100%;
    > div {
      height: 100%;
    }
    .alice-carousel__wrapper {
      height: 100%;
    }

    .alice-carousel__prev-btn-item {
      left: 0;
      bottom: 0;
    }
    .alice-carousel__next-btn-item {
      right: auto;
      left: 60px;
      bottom: 0;
    }
    .alice-carousel__stage-item {
      padding: 0;
    }
  }

  &.loyalty {
    background: #3b4f74 url('../images/dashboard/promo-card-coins.png') right center no-repeat;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__title {
    font-family: $Manrope-ExtraBold;
    font-size: 20px;
    line-height: 150%;
    max-width: 120px;
    color: #fff;
  }

  &__date {
    background-color: #0a2352;
    color: #fff;
    font-family: $Manrope-Bold;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.04em;
    text-align: center;
    @media screen and (max-width: 1600px) {
      padding: 5px 4px;
    }
  }

  &__progressbar {
    color: #fff;
    font-family: $Manrope-Bold;
    text-align: right;
    color: #fff;
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-top: 10px;
    position: relative;

    .bar {
      position: relative;
      display: block;
      width: 100px;
      height: 10px;
      background: #e6ebf2;
      margin-right: 15px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: var(--progress, 0);
        height: 10px;
        background-color: #1d8eff;
      }
    }
  }

  &__bg-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
    img {
      width: 100%;
      height: 90%;
      object-fit: contain;
      object-position: center;
    }
    @media (max-width: 991px) {
      width: auto;
      height: auto;
      left: -20px;
      top: -20px;
      right: -20px;
      bottom: -20px;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__content {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.promo-card-content {
  &--loyalty {
    align-items: flex-start;
    flex-direction: column;

    p:first-child {
      font-family: $Manrope-Medium;
      font-size: 14px;
      line-height: 143%;
      color: #fff;
      margin: 0;
    }
    p.focus {
      font-family: $Manrope-ExtraBold;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #fff;
      padding: 15px 24px;
      background-color: #0a2352;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;

    .circular-chart {
      width: 200px;
      height: 200px;
    }
  }

  &__date {
    position: absolute;
    left: 0;
    bottom: 20px;
    background-color: #3b4f74;
    color: #fff;
    font-family: $Manrope-Bold;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.04em;
    text-align: center;
  }
}
.promo-card-footer {
  &__desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-family: var(--font-family);
    font-family: $Manrope-Bold;
    font-size: 16px;
    line-height: 125%;
    color: #fff;
    margin: 10px 0 20px;
  }

  &__btns {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-left: 130px;
  }

  &__btns--more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 0 20px;
    color: $col-sapphire;
    font-family: $Manrope-Bold;
    min-height: 60px;
    margin-right: 1px;
    &:hover {
      background-color: #e6ebf2;
    }
  }
}
