@import 'variables.scss';
@import 'mixin';

.details-company {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;

  .title {
    h4 {
      font-family: $Manrope-Regular;
    }
  }

  &__btn-wrap {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 210px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-bottom: 1px solid #e6ebf2;
    min-height: 60px;
  }

  &__top-block {
    margin-bottom: 20px;
  }

  .btn-copy {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  &__top-text {
    font-family: $Manrope-Bold;
    color: $col-sapphire;
    text-align: center;
    margin: 0 0 20px 0;

    span {
      font-family: $Manrope-Regular;
    }
  }

  .top-block {
    background-color: $bg-col-white;
  }

  .list-wrap {
    display: flex;
    position: relative;
    padding: 20px;
    width: 100%;
  }

  .list-details {
    color: $col-sapphire;

    li {
      font-family: $Manrope-Regular;
    }

    &__details-calc {
      word-wrap: break-word;
    }

    &.right {
      width: 100%;
      margin-left: 40px;
    }

    &__item {
      font-family: $Manrope-Bold !important;
      color: $col-sapphire !important;
      padding-right: 40px;
    }

    li {
      color: $col-manatee;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .bottom-blocks {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &__left,
    &__right {
      max-width: 440px;
      width: 100%;
      background-color: $bg-col-white;
      padding-bottom: 20px;
    }

    &__left {
      margin-right: 20px;
    }

    .list-wrap {
      padding-bottom: 40px;

      .btn-copy {
        bottom: 0;
      }
    }

    .list-details {
      &.right {
        margin-left: 60px;
        li {
          padding-right: 0;
          overflow-wrap: anywhere;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .details-company {
    .bottom-blocks {
      flex-direction: column;

      &__left,
      &__right {
        max-width: 100%;
      }

      &__left {
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .details-company {
    &__btn-wrap {
      justify-content: center;
    }

    .bottom-blocks {
      &__left {
        margin-right: 0;
      }
    }

    .list-details {
      &.right {
        margin-left: 20px;
      }
    }
  }
}
