@import 'variables.scss';
@import 'mixin';

@keyframes delete-list {
  0% {
    opacity: 1;
    max-height: 290px;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    max-height: 0px;
    min-height: 0px;
  }
}

@keyframes delete-card {
  0% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

.select-warehouse_notification {
  @include centering-mod-h;
  background-color: $bg-col-astronaut;
  color: $col-white;
  height: 60px;
}

.remain-count {
  @include centering-mod-h;
  max-width: 90px;
  width: 100%;
  min-width: 60px;
  height: 60px;

  color: $col-manatee;
  background-color: $bg-col-solitude;

  border: none;
  padding: 0 20px;
}

.basket-wrap {
  color: $col-sapphire;

  .select-wrap {
    color: $col-manatee;
    background-color: $bg-col-white;

    &__list {
      width: 100%;

      li {
        justify-content: space-between;
      }
    }

    .selected {
      color: $col-sapphire;
    }

    &.forced {
      &:before {
        border-top: 5px solid $col-red;
      }

      .select-wrap {
        &__val {
          color: $col-red;
        }
      }
    }
  }

  &__top-wrap {
    margin-bottom: 20px;
  }

  &__step-desc {
    font-family: $Manrope-Bold;
  }

  &__step-number {
    display: inline-block;
    width: 12px;
    margin-right: 25px;
  }

  .result-row {
    .total-sum {
      margin-left: 20px;
    }
  }

  .fields {
    position: relative;
    display: flex;
    flex-direction: column;

    font-family: $Manrope-Bold;

    max-width: 840px;
    width: 100%;
    padding-left: 45px;

    &.disabled {
      opacity: 0.3;

      .fields {
        &__btn-wrap {
          .btn {
            &:hover {
              background-color: $bg-col-sapphire;
            }
          }
        }
      }
    }

    .field-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      min-height: 60px;

      > p {
        font-family: $Manrope-Medium;
      }

      &.textarea {
        position: relative;
        align-items: unset;

        textarea {
          padding: 20px;

          &::placeholder {
            color: $col-manatee;
          }
        }

        .form-field_error {
          top: 20px;
          bottom: unset;
        }

        .form-field_error-bottom {
          bottom: -20px;
          right: 0;
        }
      }

      .input-wrap {
        position: relative;
        max-width: 670px;
        width: 100%;

        &_small-field {
          max-width: 440px;
          width: 100%;
        }

        input {
          width: 100%;
          height: 60px;
          border: none;
          padding: 0 20px;

          &:disabled {
            opacity: 1;
          }
        }
      }

      p {
        flex-shrink: 0;
        width: 270px;
        margin-right: 84px;
      }

      .select-wrap {
        max-width: 440px;
        width: 100%;
        height: 60px;
        padding: 0 20px;
      }

      input::placeholder {
        color: $col-manatee;
      }

      .select-wrap {
        padding: 0 40px 0 20px;

        &.small-field {
          max-width: 325px;
          width: 100%;
        }

        &:before {
          right: 25px;
        }
      }

      .select-wrap_blocked {
        cursor: default;

        &:before {
          display: none;
        }
      }

      input[disabled] {
        background-color: $bg-col-white;
      }

      textarea {
        max-width: 670px;
        width: 100%;
        height: 120px;
        border: none;
        resize: none;
      }
    }
  }
}

.time-select {
  display: flex;
  flex-wrap: wrap;
  max-width: 670px;
  width: 100%;

  label {
    display: flex;
    align-items: center;
    width: 25%;

    p {
      width: unset !important;
    }
  }

  input[type='radio'] {
    margin-right: 20px;
    flex-shrink: 0;
  }

  &.day-dispatch {
    label {
      width: 50%;
    }
  }
}

.free-shipping {
  display: flex;
  justify-content: flex-end;
  font-family: $Manrope-Medium;

  color: $col-white;

  margin-bottom: 20px;

  &__desc-wrap {
    // max-width: 670px;
    max-width: 440px;

    width: 100%;

    background-color: $bg-col-white;
    color: $col-red;
    padding: 20px;
  }

  &__desc-wrap-transparent {
    max-width: 440px;
    width: 100%;
    padding: 20px;

    p {
      margin: 0;
      color: $col-red;
      text-align: center;
    }
  }
}

.submit-wrap {
  // max-width: 670px;
  max-width: 840px;

  width: 100%;
  @include flex-space-between-mod;

  .next-shipment {
    font-family: $Manrope-Medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 58%;
    margin-right: 20px;
    padding-left: 45px;

    &__time {
      width: 90px;
      font-size: 20px;
      line-height: 20px;
      margin-left: 25px;
    }
  }

  &__btn-wrap {
    // width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    .btn {
      width: 210px;

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}

.step-first {
  display: flex;
  //flex-direction: column;

  .basket-wrap {
    &__step-desc {
      flex-shrink: 0;
      width: 270px;
      margin-right: 129px;
    }
  }

  .select-wrap {
    max-width: 440px;
    width: 100%;
    //flex-shrink: 0;

    padding: 0 45px 0 20px;

    &:before {
      right: 25px;
    }
  }

  &__dispatch {
    margin-left: 40px;
  }
}

.step-two {
  margin-bottom: 40px;

  &__step-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    p {
      width: 270px;
      margin-right: 129px;
    }
  }

  &__btns-wrap {
    display: flex;
    justify-content: space-between;

    .btn {
      &:first-child {
        margin-right: 20px;
      }

      width: 210px;

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
      }

      .return {
        margin-right: 0;
      }
    }
  }
}

.warehouse-wrap {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  max-width: 840px;
  width: 100%;
  margin-bottom: 20px;
}

.dispatch {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: $Manrope-Bold;
  margin-bottom: 20px;

  &__time {
    font-family: $Manrope-Regular;
    font-size: 20px;
    line-height: 20px;
    width: 90px;

    margin-left: 24px;

    display: flex;
    align-items: center;
  }

  &__express-message {
    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    p {
      color: $col-manatee;
      max-width: 440px !important;
      text-align: center;
      width: 100% !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      @include centering-mod-h;
      font-family: $Manrope-Regular;
    }
  }

  p {
    display: flex;
    width: 245px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.basket {
  background-color: $bg-col-white;
  margin-bottom: 20px;

  &.remaining-goods {
    .vendor-code-title,
    .vendor-code {
      width: 14%;
    }

    .prod-price-title,
    .product-price {
      width: 9%;
    }

    .total-price-title,
    .total-price {
      width: 12%;
    }

    .quantity-goods {
      justify-content: center;

      input {
        text-align: center;
      }
    }
  }

  .titles-row {
    position: relative;
    padding: 0 20px;

    .col-title {
      min-height: 60px;
      display: flex;
      align-items: center;
    }

    .col-title {
      padding: 10px;
    }
  }

  .cnt-row {
    position: relative;
    font-family: $Manrope-Bold;
    padding: 0 20px;

    &.deleted {
      overflow: hidden;
      animation: delete-list 0.1s;
      animation-fill-mode: forwards;
      pointer-events: none;
      margin: unset;
    }
  }

  input[type='checkbox'] {
    &.checkbox-blue {
      width: 28px;
      height: 28px;
    }

    &:checked {
      &:before {
        top: 8px;
        left: 15px;
      }

      &:after {
        top: 13px;
        left: 10px;
      }
    }
  }

  .column {
    min-height: 100px;
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .checkbox-title,
  .checkbox-wrap {
    position: relative;
    width: 60px;
    flex-shrink: 0;
    @include centering-mod-h;

    svg {
      position: absolute;
      right: -24px;
    }
  }

  .prod-img-title,
  .product-img {
    width: 5.9%;
    min-width: 130px;
    position: relative;
    justify-content: center;

    .small-squares {
      z-index: 99;
      position: absolute;
      top: 40px;
      left: 81%;
    }
  }

  .product-name-title,
  .product-name {
    width: 16%;
    min-width: 180px;

    &__text {
      font-family: $Manrope-Bold;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  .vendor-code-title,
  .vendor-code {
    width: 11.7%;
    min-width: 140px;

    .btn-copy {
      margin-right: 6%;
    }

    &__v-code {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  .brand-title,
  .brand {
    width: 12.7%;
    min-width: 140px;

    &__img {
      margin-right: 9.3%;
    }

    &__text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  .prod-price-title,
  .product-price {
    width: 7.3%;
    min-width: 104px;
  }

  .product-price {
    justify-content: flex-end;

    &__wrap {
      text-align: end;
    }

    &__cost {
      white-space: nowrap;
    }

    &__currency {
      margin-left: 15px;
    }
  }

  .quantity-goods-title,
  .quantity-goods {
    width: 13%;
    min-width: 163px;

    input {
      max-width: 90px;
      min-width: 60px;
      height: 60px;
      width: 100%;

      color: #8f94b3;
      background-color: #e6ebf2;
      border: none;
      padding: 0 16px;
    }
  }

  .accessible-title,
  .accessible {
    width: 5%;
    min-width: 100px;

    .warning {
      display: flex;
      align-items: center;
      color: $col-red;

      svg {
        margin-right: 18px;
      }
    }
  }

  .total-price-title,
  .total-price {
    width: 9%;
    min-width: 132px;
    overflow-wrap: anywhere;

    &__currency {
      overflow-wrap: normal;
    }

    &__cost {
      font-size: 20px;
    }
  }

  .total-price {
    justify-content: flex-end;

    &__wrap {
      text-align: end;
    }

    &__cost {
      white-space: nowrap;
    }

    &__currency {
      margin-left: 15px;
    }
  }

  .num-prod-title,
  .number-product {
    width: 190px;
    min-width: 170px;

    &__item,
    .product-count {
      min-width: 30px;
      margin: 5px;
    }
  }

  .num-prod-title {
    &__wrapper {
      display: flex;
      margin: 0 -5px;
    }
  }

  .number-product {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      height: 100%;
      margin: 0 -5px;
    }
  }

  .delete-title,
  .delete {
    // width: 4%;
    min-width: 60px;
    z-index: 10;
  }

  .checkbox-label {
    @include centering-mod-h;
    cursor: pointer;
    width: 60px;
    height: 60px;
  }

  .brand {
    text-transform: uppercase;
  }

  .quantity-goods-title {
    span {
      width: 60px;
    }
  }
}

.cnt-row-delete {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 9991;
  @include flex-space-between-mod;
  background-color: $bg-col-white;
  width: 100%;
  height: 100%;
  color: $col-sapphire;
  border-bottom: 1px solid #e6ebf2;
  padding-left: 8.5%;

  p {
    padding: 0 20px;
    margin: 0 auto;
  }
}

.return {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: $Manrope-Bold;
  color: $col-sapphire;
  margin-right: 40px;

  &__countdown {
    @include centering-mod-h;
    width: 30px;
    height: 30px;
    border: 2px solid #ff0000;
    border-radius: 50%;
    color: $col-red;
    margin-right: 10px;
  }
}

.step-three {
  &__top {
    margin-bottom: 40px;
  }
}

.order-result {
  background-color: $bg-col-white;
  padding: 100px 0;
  margin-bottom: 100px;

  &__container {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    svg {
      margin: 10px 0 50px;
    }

    p {
      max-width: 400px;
      width: 100%;

      font-family: $Manrope-Bold;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      margin: 0;
    }
  }

  &__desc-wrap {
    p {
      color: $col-manatee;
      margin-bottom: 20px;
    }
  }

  &__btns-wrap {
    @include flex-space-between-mod;

    max-width: 440px;
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1680px) {
  .basket {
    .product-name-title,
    .product-name {
      width: 13%;
    }

    .prod-price-title,
    .product-price {
      width: 10%;
    }

    .brand-title,
    .brand {
      width: 10%;
    }
  }
}

@media screen and (max-width: 1440px) {
  .step-two {
    .basket {
      .column {
        min-height: 90px;
      }

      .titles-row {
        display: none;
      }

      .cnt-row {
        position: relative;
        flex-wrap: wrap;
        padding-left: 100px;
        justify-content: space-between;
      }

      .checkbox-wrap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
      }

      .checkbox-title,
      .checkbox-wrap {
        width: 45px;
      }

      .product-name {
        order: 2;
        width: 32.3%;
      }

      .vendor-code {
        width: 20%;
      }

      .brand {
        width: 20%;
      }

      .product-price {
        order: 3;
        width: 19%;
        justify-content: flex-end;
      }

      .quantity-goods {
        order: 3;
        width: 18%;
        min-width: 230px;

        .number {
          min-width: 90px;
        }
      }

      .accessible {
        order: 1;
        width: 8%;
        min-width: 95px;
      }

      .total-price {
        order: 1;
        width: 22%;
        justify-content: flex-end;
      }

      .number-product {
        order: 2;
        width: 20%;
        flex-wrap: wrap;
        min-width: 140px;
      }

      .delete {
        padding: 0 10px;
        order: 1;
        width: 6.46%;
        min-width: 80px;
      }
    }
  }
}

@media screen and (max-width: 1535px) {
  .warehouse-wrap {
    flex-shrink: unset;
  }

  .dispatch {
    max-width: 800px;
    width: 100%;
  }

  .step-first {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1365px) {
  .basket {
    &.remaining-goods {
      .column {
        min-height: 90px;
      }

      .product-img {
        width: 12%;
      }

      .product-name {
        order: 2;
        width: 38%;
      }

      .vendor-code {
        width: 25%;
      }

      .brand {
        justify-content: center;
        width: 33%;
      }

      .product-price {
        justify-content: center;
        order: 2;
        width: 26%;
      }

      .quantity-goods {
        order: 2;
        margin-right: 0;
        width: unset;
        min-width: 90px;
      }

      .total-price {
        order: 3;
        width: 22%;
        justify-content: flex-end;
      }

      .number-product {
        justify-content: center;
        order: 0;
        width: 21%;
      }

      .delete {
        order: 0;
        width: 6.46%;
      }

      .cnt-row {
        padding: 0 40px;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 1151px) {
  .step-two {
    .basket {
      .number-product {
        width: 140px;
      }

      .product-name {
        width: 36.2%;
      }

      .product-price {
        width: 17.7%;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .basket-wrap {
    .basket {
      display: flex;
      flex-wrap: wrap;

      margin: 0 -1.1%;

      background-color: unset;

      &.remaining-goods {
        .brand {
          justify-content: start;
        }

        .quantity-goods {
          order: 2;
          width: 50%;
        }
      }

      .column {
        padding: 5px 0;
        min-height: unset;
      }

      .cnt-row-delete {
        flex-direction: column;
        justify-content: center;

        padding: 20px;

        p {
          padding: 0;
        }

        .return {
          margin: 20px 0 0 0;
        }
      }

      .cnt-row {
        width: 31.1%;
        height: auto;
        padding: 20px;
        margin: 0 1.1% 20px;

        &.deleted {
          padding: unset;
          overflow: hidden;
          animation: delete-card 0.2s;
          animation-fill-mode: forwards;
          pointer-events: none;
          margin: unset;

          div {
            overflow: hidden;
            min-width: 240px;
          }
        }
      }

      .checkbox-wrap {
        z-index: 99;
        top: 4.6%;
        transform: unset;
      }

      .checkbox-title,
      .checkbox-wrap {
        width: 60px;
      }

      .product-img {
        width: 100%;

        padding: 0 0 10px 0;

        &__link {
          position: relative;
        }

        .small-squares {
          top: 20px;
          right: -12px;
          left: unset;
        }

        .discount {
          left: 50%;
          transform: translateX(-50%);
          width: 130px;
          top: -20px;
        }

        .hidden-img {
          display: none;
        }
      }

      .product-name {
        order: 0;
        width: 100%;

        margin-bottom: 10px;
      }

      .vendor-code {
        width: 100%;
      }

      .brand {
        width: 100%;

        margin-bottom: 4px;
      }

      .product-price {
        order: 0;
        width: 50%;
        justify-content: start;

        margin-bottom: 8px;

        &__wrap {
          text-align: unset;
        }

        &__cost {
          margin-right: 15px;
        }

        &__currency {
          margin-left: 0;
        }
      }

      .quantity-goods {
        width: 100%;
        order: 1;
        justify-content: center;

        margin-bottom: 5px;
      }

      .accessible {
        justify-content: center;
        order: 0;

        margin-bottom: 8px;
      }

      .total-price {
        order: 1;
        width: 50%;
        justify-content: start;
        min-width: unset;

        &__wrap {
          text-align: left;
        }

        &__cost {
          white-space: nowrap;
          margin-right: 15px;
        }

        &__currency {
          margin-left: unset;
        }
      }

      .number-product {
        justify-content: flex-end;
        width: 50%;
        min-width: unset;

        &__wrapper {
          flex-wrap: wrap;
          justify-content: flex-end;
        }
      }

      .delete {
        position: absolute;
        top: 3.3%;
        right: 0;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .step-first {
    .basket-wrap {
      &__step-desc {
        margin-right: 0;
      }
    }

    .dispatch {
      &__express-message {
        margin-left: auto;

        p {
          max-width: 380px !important;
        }
      }
    }

    .select-wrap {
      max-width: 380px;
    }
  }

  .basket-wrap {
    .fields {
      padding-left: 0;

      .field-wrap {
        p {
          margin-right: unset;
        }
      }
    }

    .submit-wrap {
      .next-shipment {
        padding-left: 0;
      }
    }
  }

  .warehouse-wrap {
    max-width: 100%;
    justify-content: space-between;
  }

  .step-two {
    &__step-wrap {
      justify-content: space-between;

      p {
        margin-right: 0;
      }
    }

    &__btns-wrap {
      .btn {
        width: 180px;
      }
    }
  }

  .basket-wrap {
    .basket {
      .cnt-row {
        width: 47.8%;
      }
    }

    .result-row {
      flex-direction: column;
      align-items: unset;
      justify-content: unset;

      height: 100%;

      padding: 10px 20px;

      &__left {
        margin-bottom: 10px;
      }

      &__right {
        margin-right: 0;

        p {
          margin: 0;
        }
      }
    }

    .order-result {
      padding: 50px 0;
      margin-bottom: 50px;
    }

    .fields {
      max-width: 100%;

      .field-wrap {
        justify-content: space-between;

        textarea {
          max-width: 380px;
        }

        p {
          width: 225px;
          margin-right: 0;
        }

        .select-wrap {
          max-width: 380px;
        }

        .input-wrap {
          &_small-field {
            max-width: 380px;
          }
        }
      }
    }

    .submit-wrap {
      max-width: 100%;

      &__btn-wrap {
        width: 100%;
        max-width: 270px;
      }

      .next-shipment {
        width: 66%;
      }
    }
  }

  .free-shipping {
    &__desc-wrap {
      max-width: 380px;
    }
    &__desc-wrap-transparent {
      max-width: 380px;
    }
  }
}

@media screen and (max-width: 700px) {
  .step-first {
    &__dispatch {
      justify-content: unset;
      flex-direction: column;
      margin-left: 0;
    }

    .dispatch {
      align-items: unset;

      &__text {
        width: 120px;
      }

      p {
        margin-left: 0;
      }

      //

      &__express-message {
        margin: 0 auto;

        p {
          max-width: unset !important;
        }
      }
    }
  }

  // .warehouse-wrap {
  //   flex-direction: column;
  //   align-items: unset;
  // }

  .step-two {
    &__step-wrap {
      flex-direction: column;
      align-items: unset;
    }
  }
}

@media screen and (max-width: 599px) {
  .basket-wrap {
    .basket {
      justify-content: center;

      .cnt-row-delete,
      .cnt-row {
        min-width: 280px;
        width: 100%;
      }
    }

    .result-row {
      p {
        justify-content: space-between;
        height: 30px;
        margin: 0;
      }

      &__left {
        flex-direction: column;
        margin-bottom: 0;
      }
    }

    .fields {
      padding-left: 0;

      .field-wrap {
        flex-direction: column;
        align-items: unset;

        textarea {
          max-width: 100%;
        }

        .select-wrap {
          max-width: 100%;
        }

        .input-wrap {
          &_small-field {
            max-width: 100%;
          }
        }
      }
    }

    .submit-wrap {
      flex-direction: column;
      align-items: unset;

      .next-shipment {
        max-width: 360px;
        width: 100%;
        margin-bottom: 20px;
        padding-left: 0;
      }

      &__btn-wrap {
        max-width: unset;
        justify-content: center;
      }
    }
  }

  .free-shipping {
    &__desc-wrap {
      max-width: 100%;
    }
    &__desc-wrap-transparent {
      max-width: 100%;
    }
  }

  .time-select {
    label {
      width: 33.3%;
    }

    &.day-dispatch {
      label {
        width: 50%;
      }
    }
  }

  .step-first {
    .warehouse-wrap {
      flex-direction: column;
      align-items: unset;
      margin-right: 0;
    }

    .select-wrap {
      margin: 0;
      max-width: 100%;
    }
  }

  .step-two {
    &__btns-wrap {
      position: relative;

      width: 100%;
      min-width: 280px;

      margin: 0 auto 20px;

      &:before {
        content: '';
        position: absolute;

        left: 50%;
        transform: translateX(-50%);

        width: 1px;
        height: 100%;

        background-color: $bg-col-solitude;
      }

      .btn {
        width: 50%;
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .submit-wrap {
    &__btn-wrap {
      .btn {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .time-select {
    &.day-dispatch {
      label {
        width: 100%;
      }
    }
  }

  .step-two {
    &__btns-wrap {
      .btn {
        padding: 0;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}
