@import 'variables.scss';
@import 'mixin';

.downloads-list {
  li {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 900px;
    width: 100%;
    height: 60px;

    font-family: $Manrope-Bold;
    color: $col-sapphire;

    background-color: $bg-col-white;
    padding: 0 20px;

    strong {
      color: $col-dodger-blue;
      margin-left: 30px;
    }

    &:hover {
      color: $col-white;
      strong {
        color: $col-white;
      }
      background-color: $bg-col-dodger-blue;
    }
  }
}
