@import 'variables.scss';
@import 'mixin';

.nav-bottom {
  &.report {
    .nav-bottom {
      &__left {
        max-width: 1420px;
        width: 100%;
        @include flex-space-between-mod;
        margin-right: 15px;

        .wrapper {
          @include flex-space-between-mod;
          max-width: 580px;
          width: 100%;
          margin-right: 30px;
        }

        .date-wrap {
          display: flex;
          justify-content: space-between;
          max-width: 340px;
          width: 100%;
        }

        .input-wrap {
          input {
            width: 210px;
            height: 60px;
            border: none;

            font-size: 14px;
            line-height: 20px;
            padding: 0 20px;

            &::placeholder {
              color: $col-manatee;
            }
          }
        }

        .debt {
          font-family: $Manrope-Bold;
          max-width: 300px;
          width: 100%;
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__right {
        width: 380px;
      }
    }
  }

  &.report-details {
    .nav-bottom {
      &__left {
        max-width: 1400px;
        width: 100%;
        @include flex-space-between-mod;
        margin-right: 15px;

        .wrapper {
          @include flex-space-between-mod;
          max-width: 506px;
          width: 100%;
          //margin-right: 30px;
        }

        .order,
        .about-document {
          font-family: $Manrope-Bold;
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__right {
        width: 380px;
      }
    }
  }
}

.report-table {
  .cnt-row {
    &:hover {
      background-color: $bg-col-swans-down;
      @media (hover: none) {
        background-color: $bg-col-white;
      }
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }

  .date-title,
  .date {
    width: 10.3%;
    min-width: 175px;
    font-family: $Manrope-Regular;
  }

  .document-title,
  .document {
    width: 28%;
  }

  .number-title,
  .number {
    width: 8%;
    min-width: 145px;
  }

  .f-title,
  .f {
    display: none;
    width: 4%;
    min-width: 70px;
  }

  .expense-title,
  .expense {
    width: 10%;
    min-width: 100px;
  }

  .expense {
    white-space: nowrap;
  }

  .income-title,
  .income {
    width: 11.5%;
    min-width: 195px;
  }

  .payment-term-title,
  .payment-term {
    width: 12.8%;
    min-width: 145px;
  }

  .balance-title,
  .balance {
    width: 6%;
    min-width: 100px;
  }

  .balance {
    white-space: nowrap;
  }

  .department-title,
  .department {
    width: 11.8%;
  }

  .date,
  .payment-term {
    color: $col-manatee;
  }

  .document {
    font-size: 16px;
    line-height: 20px;
    font-family: $Manrope-Bold;
  }

  .number {
    font-family: $Manrope-Bold;
    color: $col-dodger-blue;

    // a {
    //   font-family: $Manrope-Bold;
    //   color: $col-dodger-blue;
    // }
  }

  .income {
    font-family: $Manrope-Bold;
    text-align: end;
  }

  .currency {
    font-family: $Manrope-Medium;
    margin-left: 20px;
  }
}

.cash-order {
  &__inf-row {
    margin-bottom: 20px;
  }

  .order-inf {
    &__title {
      display: flex;
      justify-content: center;
      min-height: 60px;
      padding: 0 20px;
      margin-bottom: 20px;
    }

    &__result-row {
      margin-bottom: 20px;
    }
  }

  .titles-row,
  .cnt-row {
    padding: 0 0;
  }

  .numbering-title,
  .numbering {
    width: 4.4%;
    min-width: 70px;
  }

  .payment-date-title,
  .payment-date {
    width: 8.5%;
    min-width: 165px;
  }

  .payment-number-title,
  .payment-number {
    width: 5.6%;
    min-width: 84px;

    a {
      color: $col-dodger-blue;
    }
  }

  .document-title,
  .document {
    width: 27%;
  }

  .invoice-number-title,
  .invoice-number {
    //width: 12.8%;
    //min-width: 100px;
    min-width: 100px;
  }

  .date-title,
  .date {
    width: 10.3%;
    min-width: 170px;
    font-family: $Manrope-Regular;
  }

  .total-amount-title,
  .total-amount {
    width: 13%;
  }

  .calculated-title,
  .calculated {
    width: 11%;
  }

  .debt-balance-title,
  .debt-balance {
    width: 12.6%;
  }

  .total-amount,
  .calculated,
  .debt-balance {
    font-family: $Manrope-Bold;
    text-align: end;
  }

  .total-amount,
  .total-amount-title,
  .calculated,
  .calculated-title,
  .debt-balance,
  .debt-balance-title {
    min-width: 200px;
  }

  .document {
    font-family: $Manrope-Bold;
    font-size: 16px;
  }

  .currency {
    margin-left: 20px;
    font-family: $Manrope-Medium;
  }

  .payment-number,
  .invoice-number {
    font-family: $Manrope-Bold;
    overflow-wrap: break-word;
  }

  .numbering,
  .payment-date,
  .date {
    color: $col-manatee;
  }

  .inf-row {
    @include flex-space-between-mod;
    background-color: $bg-col-white;
    min-height: 60px;
    color: $col-manatee;
    padding: 0 20px;

    p {
      font-family: $Manrope-Regular;

      span {
        margin-right: 20px;
      }

      strong {
        font-family: $Manrope-Bold;
        color: $col-sapphire;
      }

      &:nth-child(2) {
        color: $col-sapphire;

        span {
          margin-left: 0;
        }
      }
    }
  }

  .result-row {
    justify-content: flex-end;
    align-items: center;

    p {
      display: flex;
    }

    .total-sum {
      margin: 0 20px;
    }
  }

  .btns-wrap {
    display: flex;
    justify-content: flex-end;

    .btn {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .report-table {
    .document-title,
    .document {
      width: 23%;
    }

    .balance-title,
    .balance {
      width: 8%;
    }

    .expense-title,
    .expense {
      width: 8%;
    }
  }

  .cash-order {
    .document-title,
    .document {
      width: 16.5%;
    }

    .total-amount-title,
    .total-amount,
    .calculated-title,
    .calculated,
    .debt-balance-title,
    .debt-balance {
      width: 13%;
    }
  }
}

@media screen and (max-width: 1439px) {
  .cash-order {
    .total-amount,
    .total-amount-title,
    .calculated,
    .calculated-title,
    .debt-balance,
    .debt-balance-title {
      min-width: 160px;
    }
  }
}

@media screen and (max-width: 1536px) {
  .nav-bottom {
    &.report {
      .nav-bottom {
        &__left {
          margin: 22px 0 26px 0;
          flex-direction: column;
          align-items: unset;

          .wrapper {
            margin-bottom: 26.5px;
          }

          .input-wrap {
            position: absolute;
            right: 0;
            top: 101px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .nav-bottom {
    &.report-details {
      .nav-bottom {
        &__left {
          max-width: 750px;

          .wrapper {
            max-width: 360px;
          }
        }
      }
    }
  }

  .cash-order {
    .total-amount,
    .total-amount-title,
    .calculated,
    .calculated-title,
    .debt-balance,
    .debt-balance-title {
      min-width: 160px;
    }

    .order-inf {
      .currency {
        margin-left: 10px;
      }

      .titles-row,
      .cnt-row {
        padding: 0 15px;
      }

      .column,
      .col-title {
        padding: 5px;
      }

      .payment-number {
        overflow-wrap: anywhere;
      }

      .document-title,
      .document {
        width: 21.2%;
        min-width: 160px;
      }

      .invoice-number-title,
      .invoice-number {
        min-width: 70px;
      }
    }
  }

  .report-table {
    .column,
    .col-title {
      padding: 10px;
    }

    .date-title,
    .date {
      min-width: 155px;
    }

    .document-title,
    .document {
      width: 23.5%;
    }

    .number-title,
    .number {
      min-width: 125px;
    }

    .income-title,
    .income {
      min-width: 180px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .report-table {
    overflow-x: auto;

    .titles-row,
    .cnt-row {
      min-width: 1600px;
    }

    .column,
    .col-title {
      padding: 20px;
    }

    .date-title,
    .date {
      min-width: 175px;
    }

    .income-title,
    .income {
      min-width: 200px;
    }
  }

  .cash-order {
    .total-amount,
    .total-amount-title,
    .calculated,
    .calculated-title,
    .debt-balance,
    .debt-balance-title {
      min-width: 200px;
    }

    .order-inf {
      &__wrap {
        overflow: auto;
      }

      .currency {
        margin-left: 20px;
      }

      .titles-row,
      .cnt-row {
        padding: 10px;
      }

      .column,
      .col-title {
        padding: 20px;
      }

      .document-title,
      .document {
        width: 21.2%;
        min-width: 160px;
      }

      .invoice-number-title,
      .invoice-number {
        min-width: 70px;
      }
    }

    .date-title,
    .date {
      min-width: 170px;
    }

    .numbering-title,
    .numbering {
      min-width: 70px;
    }

    .titles-row,
    .cnt-row {
      min-width: 1600px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .nav-bottom {
    &.report-details {
      .nav-bottom {
        &__left {
          flex-direction: column;
          align-items: unset;

          margin: 30px 0 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .nav-bottom {
    &.report {
      .nav-bottom {
        &__left {
          .wrapper {
            flex-direction: column;
            align-items: unset;
            margin-bottom: 0;
          }

          .select-wrap {
            min-height: unset;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nav-bottom {
    &.report {
      .nav-bottom {
        &__left {
          .date-wrap {
            max-width: 270px;
          }
        }
      }
    }
  }

  .cash-order {
    .inf-row {
      flex-direction: column;
      align-items: unset;
      padding: 0;

      p {
        border-bottom: 1px solid #e6ebf2;
        margin: 0;
        padding: 14px 20px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .nav-bottom {
    &.report {
      .nav-bottom {
        &__left {
          margin: 0;

          .input-wrap {
            position: absolute;
            right: 0;
            top: 85px;
          }

          .wrapper {
            max-width: 100%;
            flex-direction: unset;
            align-items: center;
            margin-bottom: 32px;
          }
        }
      }
    }

    &.report-details {
      .nav-bottom {
        &__left {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .nav-bottom {
    &.report {
      .nav-bottom {
        &__left {
          .input-wrap {
            position: unset;
          }

          .wrapper {
            flex-direction: column;
            align-items: unset;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
