@import 'variables.scss';
@import 'mixin';

//begin header
.main-header {
  position: sticky;
  position: -webkit-sticky;
  z-index: 99999;
  top: 0;
  min-height: 60px;
  background-color: $bg-col-sapphire;
  color: $col-white;

  .container {
    max-width: 1920px;
  }

  .mobile-search {
    display: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: -1000%;

    background-color: $bg-col-white;
    transition: 0.3s;
    box-shadow: 0 0 0 0 black;

    .history-search {
      width: 100%;
    }

    &.active {
      left: 120px;
    }
    &__top-wrap {
      display: none;
    }
    &__wrap {
      display: flex;
      width: calc(100vw - 120px);
    }

    &__left-side {
      font-family: $Manrope-Bold;
      position: relative;
      display: flex;
      width: 100%;
    }

    .search {
      &__input-w {
        max-width: unset;
        height: 100%;
        border-right: 1px solid #e6ebf2;
        margin-right: 0;
        input {
          padding: 0 40px 0 20px;
        }
      }

      &__btn-history-s {
        margin-right: 0;
      }
    }

    .number-symbols {
      position: absolute;
      color: #1d8eff;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
    }

    .select-wrap {
      width: 200px;
      background-color: $bg-col-white;
      color: $col-sapphire;
      padding-right: 60px;
      min-width: 156px;
      padding-left: 20px;

      &:before {
        right: 25px;
      }
    }
    .btn-close {
      width: 60px;
      height: 60px;
      background-color: $bg-col-white;
      &:hover {
        svg {
          path {
            fill: #3b4f74;
          }
        }
      }
    }
  }

  &__notify {
    --color: #fff;
    --bg-color: #1d8eff;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.2s ease;

    .notify-show & {
      grid-template-rows: 1fr;
    }
  }

  @keyframes marqueeLine {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }

  .header-notify {
    &__wrapper {
      overflow: hidden;
    }

    &__body {
      position: relative;
      background-color: var(--bg-color);
      padding-right: 40px;
      min-height: 30px;
    }

    &__text {
      padding: 5px;
      color: var(--color);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: $Manrope-Bold;
      font-size: 12px;
      line-height: 166.667%;
      text-align: center;
      display: flex;

      &.running {
        p {
          width: unset;
          animation-name: marqueeLine;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
        }
      }

      p {
        flex-shrink: 0;
        margin: 0;
        padding-inline: 15px;
        white-space: nowrap;
        width: 100%;
      }
    }

    &__text-tester {
      height: 0;
      visibility: hidden;
      width: fit-content;
      p {
        height: 0;
        padding-block: 0;
        margin-block: 0;
        padding-inline: 15px;
        white-space: nowrap;
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 50%;
      min-width: 20px;
      min-height: 20px;
      transform: translateY(-50%);
      padding: 0;

      @media (any-hover: hover) {
        svg {
          transition: opacity 0.3s ease;
        }
        &:hover {
          svg {
            opacity: 0.8;
          }
        }
      }
    }

    @media screen and (max-width: 760px) {
      margin-bottom: 20px;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  background-color: $bg-col-sapphire;
  z-index: 3;
}

.menu-wrap {
  position: relative;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 230px;
  width: 100%;

  > strong {
    font-family: $Manrope-Bold;
    margin: 0 20px;
    cursor: default;
  }

  &__search {
    display: none;
  }
}

.burger-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 99999;
  width: 154px;
  height: 60px;

  &_hide {
    left: -1000px;
  }

  > strong {
    margin: 0 20px;
  }

  &:hover {
    .btn-burger {
      background-color: $bg-col-dodger-blue;
      opacity: 1;
      @media screen and (max-width: 1279px) {
        background-color: transparent;
        transition: 0s;
      }

      &.active {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.nav-wrap {
  // display: none;
  position: fixed;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  width: 320px;
  background-color: $bg-col-white;
  padding: 20px;
  z-index: 9999;
  overflow-y: auto;
  color: $col-sapphire;
  transition: 0.5s;

  &.active {
    display: block;
  }

  .copyright {
    font-family: $Manrope-Regular;
    color: $col-manatee;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 60px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    button {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 20px;
      right: 20px;
    }

    .select-wrap {
      position: absolute;
      height: 60px;
      top: 20px;
      left: 40px;
      width: 90px;
      .select-wrap__list {
        background-color: $bg-col-solitude;
      }
    }
  }

  .list {
    margin-top: 50px;
    color: $col-sapphire;
    font-family: $Manrope-Bold;

    padding-top: 20px;

    .drop-list {
      > ul {
        display: none;
        padding-left: 30px;
      }
      &.active {
        > ul {
          display: block;
        }

        > .drop-list__wrap {
          > p {
            color: $col-dodger-blue;

            &:before {
              border-top-color: $col-dodger-blue;
              transform: rotateX(180deg);
              top: calc(28% - 3px);
            }
          }
        }
      }

      &__wrap {
        padding: 20px 0 20px 20px;

        &:hover {
          cursor: pointer;

          > p {
            color: $col-dodger-blue;

            &:before {
              border-top-color: $col-dodger-blue;
            }
          }
        }

        > p {
          transition: 0.3s;
          position: relative;
          width: max-content;
          margin: 0;

          &:before {
            transition: 0.3s;
            position: absolute;
            content: '';
            top: calc(50% - 3px);
            right: -35px;
            border: 5px solid transparent;
            border-top: 5px solid #0a2352;
          }
        }
      }
    }

    li {
      a {
        display: block;
        font-family: $Manrope-Bold;
        color: $col-sapphire;
        transition: 0.3s;
        padding: 13px 0 13px 20px;

        &.active {
          color: $col-dodger-blue;
        }

        &:hover {
          color: $col-dodger-blue;
        }
      }
    }
  }
}

.search {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $bg-col-white;
  color: $col-sapphire;

  max-width: 460px;
  width: 100%;

  &__message {
    position: absolute;
    top: 60px;

    @include centering-mod-h;
    width: 100%;
    min-height: 60px;
    color: $col-manatee;
    // font-family: $Manrope-Regular;
    font-family: $Manrope-Bold;
    font-style: italic;
    padding: 20px;

    border-top: 1px solid #e6ebf2;
    background-color: $bg-col-white;
    &.active {
      display: block;
    }
  }

  &__warning-message {
    position: absolute;
    z-index: 1;
    bottom: 4px;
    left: 80px;
    font-family: $Manrope-Bold;
    font-size: 12px;
    line-height: 14px;
    color: $col-red;
  }

  .number-symbols {
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    color: $col-dodger-blue;
  }

  &__wrap {
    font-family: $Manrope-Bold;
  }

  &__btn-history-s {
    border-right: 1px solid #e6ebf2;
  }

  &__input-w {
    max-width: 170px;
    width: 100%;
    position: relative;

    input {
      border: none;
      width: 100%;
      color: $col-manatee;
      height: 60px;
      padding: 0 15px 0 20px;
      box-sizing: border-box;

      &::placeholder {
        color: $col-manatee;
      }
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .select-wrap {
    min-width: 124px;
    width: 100%;
  }

  &__right-wrap {
    display: flex;
    width: 100%;
  }

  button[type='submit'][disabled] {
    cursor: default;
    opacity: 1;
    pointer-events: none;
  }
}

.inf-client {
  display: flex;
  align-items: center;
  max-width: 1290px;
  width: 100%;

  &__arrears-wrap {
    display: flex;
    width: 100%;
  }

  &__item {
    height: 60px;
    align-items: center;
  }
}

.arrears,
.overdue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 455px;
  width: 100%;

  &__wrap {
    min-width: 140px;
    font-family: $Manrope-Bold;
    // font-size: 20px;
    text-align: center;

    background-color: $bg-col-astronaut;
    padding: 20px 10px;
    color: $col-white;

    &_bg-col-red {
      background-color: $bg-col-red;
    }

    &_bg-col-gamboge {
      background-color: $bg-col-gamboge;
    }

    &_bg-col-blue-stone {
      background-color: $bg-col-blue-stone;
    }
    &_col-gamboge {
      color: $col-gamboge;
    }
    &_col-red {
      color: $col-red;
    }
  }

  // &__text-h {
  //   display: none;
  // }

  &__text-h,
  p {
    width: 100%;
    padding: 0 15px;
    text-align: center;
  }
}

.user-balance {
  &-default {
    color: $col-sapphire !important;
  }
  &-red {
    color: $col-red !important;
  }
}

.user {
  position: relative;
  z-index: 999;
  display: flex;
  max-width: 320px;
  width: 100%;
  justify-content: space-between;

  // &__basket {
  //   display: none;
  // }

  // &__profile {
  //   display: none;
  // }

  &__wrap {
    cursor: pointer;
    width: 100%;
    @include flex-space-between-mod;
  }

  &__name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    font-family: $Manrope-Bold;
    width: 100%;
    text-align: center;
    padding: 0 15px;
  }

  &__btn-square {
    flex-shrink: 0;
  }

  &__log-out {
    border-left: 1px solid #3b4f74;
  }
}

//end header

//begin sidebar
.sidebar {
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 60px;

  width: 60px;
  height: 100%;
  min-height: 480px;
  background-color: $bg-col-astronaut;
  transition: top 0.2s ease;

  .notify-show & {
    top: 90px;
  }
}

.sidebar-list {
  li {
    position: relative;
  }

  .count {
    top: 10px;
    right: 10px;
  }
}

.hidden-inf {
  // display: none;
  position: absolute;
  top: 0;
  // transform: translateX(-100%);
  right: 60px;
  z-index: 999;
  width: 260px;
  background-color: $bg-col-sapphire;
  color: $col-white;
  padding: 20px;

  .manager__img-wrap {
    width: 100%;
    height: 120px;
    @include centering-mod-h;
    margin-bottom: 20px;

    img {
      border-radius: 50%;
      width: 120px;
      height: fit-content;
    }
  }

  .vendor-code {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    .btn-copy {
      margin-right: 16px;
      svg {
        path {
          fill: $bg-col-dodger-blue;
        }
      }

      &:hover {
        svg {
          path {
            fill: $col-white;
          }
        }
      }
    }

    a {
      &:hover {
        color: $col-white;
      }
    }
  }

  &__product-inf {
    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p.hidden-inf__header-text {
    margin-bottom: 25px;
  }

  &__header-text {
    text-align: center;

    p {
      margin: 0;
    }
  }

  &__contacts {
    .btn {
      width: 100%;
    }
  }

  .total-price {
    font-size: 20px;
    line-height: 20px;
  }

  .agent-img {
    width: 120px;
    height: 120px;
    text-align: center;
    margin: 0 auto 20px;

    img {
      border-radius: 50%;
    }
  }

  &__full-name {
    margin-bottom: 25px;
  }

  &__last-name {
    margin: 0 5px 0 0;
  }

  &__whose-contact {
    text-align: center;
    margin: 0 0 25px 0;
  }

  &__phone {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 25px 0;
  }

  &__arrears-text {
    margin: 20px 0 24px 0;
  }

  .overdue-wrap {
    margin: 0 0 30px 0;
    .overdue {
      &__wrap {
        padding: 0;
        text-align: start;
        background-color: transparent;
        color: $col-red;
      }
    }
  }
  .overdue__wrap-wrap {
    font-size: 20px;
    font-family: $Manrope-Bold;

    .balance-info__row {
      font-size: 14px;
      font-family: $Manrope-Medium;
      justify-content: flex-start;
    }
  }
  .arrears__wrap {
    font-size: 20px;
  }

  .arrears-wrap {
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    padding: 20px 5px;
  }

  .user-balance {
    &-default {
      color: $col-white !important;
    }
    &-red {
      color: $col-red !important;
    }
  }

  &__notification-date {
    color: $col-manatee;
    margin-bottom: 20px;
  }

  &__message-wrap {
    p {
      margin-bottom: 20px;
    }
  }

  &__btn-wrap {
    display: flex;
    justify-content: flex-end;

    .loading-wrap {
      width: 20px;
      height: 20px;
    }
  }

  &__btn {
    color: $col-dodger-blue;
    width: 80px;
    @include centering-mod-h;
  }
}

//end sidebar

//begin catalogue-search
.nav-bottom {
  display: flex;
  flex-direction: column;

  &__top-wrap {
    position: relative;
    min-height: 100px;
    // @include flex-space-between-mod;
    display: flex;
    justify-content: space-between;
    color: $bg-col-sapphire;
  }

  &__find-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    width: 100%;
  }

  &__item {
    font-size: 20px;
  }

  &__result-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    max-width: 460px;
    width: 100%;
    margin-right: 15px;

    &_wide {
      // max-width: unset;
      max-width: max-content;
    }
  }

  &__result {
    line-height: 25px;
    word-break: break-word;
  }

  &__left {
    display: flex;
    align-items: center;
    width: 45%;

    .title {
      font-family: $Manrope-ExtraBold;
      margin-right: 30px;

      h3 {
        margin: 0;
      }
    }
  }

  .select-wrap {
    flex-shrink: 0;
    // margin-left: 30px;

    &__list {
      width: 220px;
    }
  }

  &__result {
    font-family: $Manrope-Regular;
    margin-right: 20px;
  }

  &__result-right {
    display: flex;
    flex-wrap: wrap;

    font-size: 14px;
    //margin-left: 20px;

    .trade-number {
      overflow-wrap: anywhere;
      margin-left: 15px;
      margin-right: 20px;
    }

    .brand-name {
      color: $col-manatee;
    }
  }

  &__center {
    // @include centering-mod-h;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }

  &__right {
    // @include centering-mod-h;
    display: flex;
    justify-content: flex-end;
    align-self: flex-start;

    width: 45%;
    margin: 20px 0;

    .btn-square {
      transition-property: background-color;
    }

    &.submenu-open {
      > .item {
        &::before {
          opacity: 1;
        }
        > .btn-square {
          opacity: 0.2;
          pointer-events: none;
        }
      }

      li a {
        &.btn-square_bg-col-sapphire {
          background-color: #3b4f74;
        }
      }
    }

    .item {
      &:first-child {
        margin-left: 0;
      }

      margin-left: 20px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -20px;
        top: -20px;
        right: -20px;
        bottom: -20px;
        background-color: #082253;
        opacity: 0;
        // transition: opacity 0.3s ease-in-out;
        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }

      &.item-list {
        .submenu-list {
          pointer-events: none;
          position: absolute;
          top: calc(100% + 10px);
          right: -20px;
          width: 420px;
          max-height: 222px;
          overflow: auto;
          padding: 10px 20px 20px;
          background-color: #082253;
          z-index: 10;

          opacity: 0;
          will-change: transform;
          transform: translateY(10px);

          .submenu-item-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            background-color: #3b4f74;

            width: 60px;
            height: 60px;

            border: none;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              background-color: #1c8af8;
            }

            padding-bottom: 1px;
          }

          > li {
            &:not(:last-child) {
              margin-bottom: 12px;
            }
          }

          > li p {
            color: #fff;
            margin: 0;
            font-size: 14px;
            line-height: 20px;
            font-family: $Manrope-Bold;
            margin-bottom: 10px;
          }
          > li ul {
            display: grid;
            grid-template-columns: repeat(5, 60px);
            gap: 20px;
          }

          li a {
            &.active-submenu-link {
              .submenu-item-icon {
                background-color: #1c8af8;
              }
            }
          }
        }

        &.active {
          > .btn-square {
            opacity: 1;
            background-color: #1c8af8 !important;
            pointer-events: all;
          }
          .submenu-list {
            transform: translateY(0);
            opacity: 1;
            pointer-events: all;
          }
        }

        .item-list-btn {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          background-color: #3b4f74;

          width: 60px;
          height: 60px;

          border: none;
          cursor: pointer;
          // transition: 0.3s;
          // &::after {
          //   position: absolute;
          //   content: '';
          //   bottom: 0;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   border: 5px solid transparent;
          //   border-top: 5px solid #fff;
          // }

          &:hover {
            background-color: $bg-col-dodger-blue;
          }

          svg {
            position: relative;
            z-index: 1;
          }
        }
      }
    }
  }
}

.node-title {
  background-color: $bg-col-white;
  color: $col-manatee;
  font-size: 14px;
  font-family: $Manrope-Regular;
  width: 100%;
  min-height: 60px;
  padding: 20px;
  // margin: 0 0.6%;
  margin-bottom: 20px;
}

.catalog-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.6%;

  .cat-grid-l-fil:last-child {
    margin-bottom: 20px;
  }

  .no-results {
    margin-top: unset;
  }
}

.catalog-wrap {
  .cat-grid-l-fil:nth-last-child(-n + 2) {
    .description-list {
      top: unset;
      bottom: -20px;
    }
  }
}

.cat-grid-l-title {
  @include flex-space-between-mod;
  min-height: 60px;
}

.cat-grid-c-fil {
  .product-img {
    min-height: 130px;
  }
}

.cat-grid-c-fil,
.cat-grid-l-fil,
.catalog-grid-c,
.catalog-grid-l {
  .pagination-wrap {
    width: 98.8%;
    margin: 0 auto;
  }

  .label-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      .btn-square {
        background-color: transparent;
      }

      color: $col-dodger-blue;

      svg {
        path {
          fill: $col-dodger-blue;
        }
      }
    }
  }

  .favorites {
    &:hover {
      .btn-square {
        background-color: transparent;
      }

      svg {
        path {
          fill: $col-dodger-blue;
        }
      }
    }
  }

  position: relative;
  @include flex-space-between-mod;
  background-color: $bg-col-white;
  color: $col-sapphire;
  border-bottom: 1px solid #e6ebf2;

  &.card-shadow {
    z-index: 999;
  }

  // &.not-available {
  //   color: $col-manatee;

  //   .column {
  //     opacity: 0.2;

  //     &:first-child {
  //       opacity: 1;
  //     }

  //     &:last-child {
  //       opacity: 1;
  //       text-align: center;
  //       color: $col-dodger-blue;
  //     }
  //   }
  // }

  &__title {
    display: block;
    font-family: $Manrope-Medium !important;
  }

  .prod-img-title,
  .product-img {
    position: relative;
    justify-content: center;
    min-width: 90px;

    .small-squares {
      position: absolute;
      top: 40px;
      left: 85%;
    }

    .tecdoc {
      z-index: 999;
      position: absolute;
      bottom: 10px;
      left: 10px;
      img {
        display: block;
      }
    }
  }

  .prod-name-title,
  .product-name {
    font-family: $Manrope-Bold;

    &__tecdoc {
      margin-right: 15px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    &__text {
      font-size: 16px;

      overflow-wrap: anywhere;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  .vendor-code-title,
  .vendor-code {
    .btn-copy {
      flex-shrink: 0;
      cursor: pointer;
    }
  }

  .prod-info-title,
  .product-info {
    &__text {
      font-family: $Manrope-Regular;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      overflow-wrap: anywhere;

      color: $col-manatee;

      margin: 0;
    }
  }

  .brand-title,
  .brand {
    font-family: $Manrope-Bold;

    &__img {
      margin-right: 9.3%;
      height: fit-content;
      height: intrinsic;
    }

    &__text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  .brand {
    text-transform: uppercase;
  }

  .num-prod-title,
  .number-product {
    flex-wrap: wrap;
    font-family: $Manrope-Bold;
    margin: 0 -5px;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      height: 100%;
    }

    &__product-count {
      margin: 5px;
    }
  }

  .prod-price-title,
  .product-price {
    &__wrap {
      text-align: end;
      width: 100%;
    }

    &__cost {
      white-space: nowrap;
      font-size: 20px;
      margin-right: 10px;
      font-family: $Manrope-Bold;
    }

    &__currency {
      font-size: 14px;
    }
  }

  .favorites-title,
  .favorites {
    .btn-square {
      flex-shrink: 1;
    }
  }

  .buy {
    justify-content: flex-end;
    .link {
      color: $col-sapphire;
      &:hover {
        color: $col-dodger-blue;
      }
    }
  }

  .col-title,
  .column {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
  }
}
.cat-grid-c-fil,
.catalog-grid-c {
  width: 18.8%;
  flex-direction: column;
  margin: 0 0.6% 20px 0.6%;

  .cnt-row-delete {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    .return {
      margin: 20px 0 0;
    }

    p {
      padding: 0;
    }
  }

  &.active {
    border: 4px solid $col-sapphire;

    &.card-shadow {
      border-bottom: none;

      .description-card {
        width: calc(100% + 8px);
        border: 4px solid $col-sapphire;
        border-top: none;
      }
    }
  }

  .favorites {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 9;

    width: 60px;
  }

  .discount {
    width: 130px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__wrap {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }

  .column {
    padding: 0;
    align-items: unset;
  }

  &__wrap,
  .vendor-code {
    padding: 0 20px;
    margin-bottom: 16.8px;
  }

  // .vendor-code {
  //   z-index: 7;
  // }

  &__wrap {
    justify-content: space-between;
  }

  .product-img {
    width: 100%;
    margin-bottom: 30px;

    .small-squares {
      top: 70px;
      left: 70%;
    }

    &__link {
      display: flex;
      width: 100%;
      justify-content: center;
      padding-top: 30px;
    }
    .tecdoc {
      z-index: 99;
    }
  }

  .brand {
    width: 60px;
    margin-right: 20px;
  }

  .product-name {
    width: 100%;
    min-height: 60px;
  }

  .vendor-code {
    display: flex;
    align-items: center;
    width: 100%;

    &__v-code {
      width: 70%;
    }

    .btn-copy {
      margin: 0 10px 0 21px;
      z-index: 9;
    }
  }

  .product-info {
    position: relative;
    width: 30px;
    margin: 5px 15px 0 0;

    &__text {
      display: none;
      position: absolute;
    }
  }

  .number-product {
    // justify-content: end;
    justify-content: flex-end;
    width: 100%;
  }

  .product-price {
    align-items: center;
    width: 47.5%;

    &__wrap {
      text-align: start;
    }
  }

  .buy {
    width: 54%;
    // justify-content: flex-end;
    margin-left: 15px;
  }

  .buy-count {
    min-width: 60px;
  }
}

.catalog-grid-l {
  width: 100%;
  margin: 0 0.6%;

  &.active {
    &:before {
      content: '';
      position: absolute;
      left: -4px;
      z-index: 999;
      width: 4px;
      height: 100%;
      background-color: $bg-col-sapphire;
    }
  }

  .prod-img-title,
  .product-img {
    width: 6%;
    min-width: 130px;

    &__img {
      margin-right: 9.3%;
    }

    .tecdoc {
      z-index: 999;
    }
  }

  .prod-name-title,
  .product-name {
    width: 29%;
    min-width: 180px;
  }

  .vendor-code-title,
  .vendor-code {
    width: 11.5%;
    min-width: 135px;
    // z-index: 7;
    .btn-copy {
      margin-right: 6%;
      z-index: 9;
    }

    &__v-code {
      width: 76%;
    }
  }

  .prod-info-title,
  .product-info {
    min-width: 50px;
    &__text {
      display: none;
    }
  }

  .brand-title,
  .brand {
    width: 14%;
  }

  .num-prod-title,
  .number-product {
    margin: 0;
    width: 180px;
  }

  .prod-price-title,
  .product-price {
    width: 11%;
    min-width: 165px;
  }

  .favorites-title,
  .favorites {
    width: 3.5%;
    z-index: 9;
  }

  .buy-title,
  .buy {
    width: 10.1%;
    min-width: 155px;
    &.column {
      padding-right: 20px;
    }
  }

  .column {
    min-height: 99px;
  }

  .col-title {
    max-height: 59px;
  }
}

.catalog-grid-l {
  height: 100%;
  transition: all 0.5s ease-in-out;

  &.deleted {
    opacity: 0;
    height: 0;
    border-bottom: 0px solid $col-solitude;
    pointer-events: none;
    user-select: none;
  }
}

.catalog-grid-c {
  transition: all 0.3s ease-in-out;
  opacity: 1;
  &.deleted {
    overflow: hidden;
    opacity: 0;
    width: 0;
    pointer-events: none;
    margin: unset;
    div:not(.favorites) {
      overflow: hidden;
      min-width: 280px;
    }
  }
}

//end catalogue-search

//begin cat-fil-wrap
.cat-fil-wrap {
  display: flex;
  justify-content: space-between;

  .product-list {
    &__item {
      width: 23.8%;
    }
  }
}

.wrapper {
  width: 100%;
}

.filter {
  flex-shrink: 0;
  max-width: 320px;
  width: 100%;

  background-color: $bg-col-white;
  color: $col-sapphire;
  margin-right: 20px;

  &-list {
    padding-bottom: 170px;
  }

  &__selected-items {
    font-family: $Manrope-Regular;
    color: $col-manatee;
  }

  &__title,
  &__top {
    @include flex-space-between-mod;
    align-items: center;
    min-height: 60px;
    border-bottom: 1px solid #e6ebf2;
    padding: 0 17px 0 20px;
  }

  &__top {
    font-family: $Manrope-Bold;
  }

  &__tag-list {
    margin: 20px -11px -11px;
  }
}

.categories-list {
  display: flex;
  flex-direction: column;

  padding: 30px 17px 30px 20px;
  border-bottom: 1px solid #e6ebf2;

  li {
    margin-bottom: 20px;
    a {
      &.selected {
        background-image: URL('../images/other/selected.svg');
        background-repeat: no-repeat;
        background-position: 0 2px;

        color: $col-sapphire;
      }

      padding-left: 40px;
    }

    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.filter-list {
  &__item {
    cursor: pointer;
    border-bottom: 1px solid #e6ebf2;
    padding: 0 17px 0 20px;
  }

  &__item:last-child {
    margin-bottom: 60px;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;

    &.active {
      .indicator {
        background-color: $bg-col-sapphire;

        &:before {
          background-color: $bg-col-white;
        }

        &:after {
          display: none;
        }
      }
    }

    .indicator {
      flex-shrink: 0;
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 50%;

      margin-left: 15px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 5px);

        width: 10px;
        height: 2px;

        background-color: $bg-col-sapphire;
      }

      &:after {
        transform: rotate(90deg);
      }
    }
  }
}

.no-results {
  display: flex;
  margin-top: 20px;
  justify-content: center;

  p {
    color: $col-manatee;
    max-width: 300px;
    overflow-wrap: break-word;
    text-align: center;
  }
}

.cat-grid-l-fil {
  display: grid;
  grid-template-columns: 130px 1fr 14% 19% 50px 11.6% 12.4% 50px 12.2%;
  width: 100%;
  margin: 0 0.6%;

  .col-title {
    max-height: 59px;
  }

  .column {
    min-height: 99px;
  }

  .prod-img-title,
  .product-img {
    min-width: 90px;
    width: 100%;
  }

  .prod-name-title,
  .product-name {
    width: 100%;
  }

  .vendor-code-title,
  .vendor-code {
    width: 100%;

    &__v-code {
      max-width: 100%;
    }
  }

  .prod-info-title,
  .product-info {
    width: 50px;

    &__text {
      display: none;
    }
  }

  .brand-title,
  .brand {
    width: 100%;
  }

  .num-prod-title,
  .number-product {
    min-width: 175px;
    width: 100%;
    margin: 0;

    &__wrapper {
      align-items: center;
      flex-wrap: wrap;
      margin: 0 -5px;
    }
  }

  .prod-price-title,
  .product-price {
    width: 100%;
  }

  .favorites-title,
  .favorites {
    width: 50px;
  }

  .buy-title,
  .buy {
    width: 100%;
    padding-right: 15px;
    min-width: 125px;
  }

  .prod-info-title,
  .product-info,
  .favorites-title,
  .favorites {
    min-width: unset;
    width: 100%;
  }
}

.cat-grid-c-fil {
  width: 23.8%;
}

.cat-grid-c-fil,
.catalog-grid-c {
  .product-img {
    .small-squares {
      z-index: 99;
      position: absolute;
      top: unset;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%) rotate(-90deg);
    }
  }
}

//end cat-fil-wrap

.preloader {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border-left: 4px solid #0a2352;
  border-right: 4px solid #0a2352;
  border-top: 4px solid #0a2352;
  border-bottom: 4px solid #e6ebf2;
  animation: preloading 2s linear infinite;
}

.no-page-content {
  @include centering-mod-h;
  font-family: $Manrope-Medium;
}

.popup {
  --bg-color: #1d8eff;
  --color: #fff;

  background-color: var(--bg-color);
  color: var(--color);

  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000000;
  padding: 20px;
  max-width: 360px;

  @media screen and (max-width: 480px) {
    top: 50%;
    left: 20px;
    bottom: auto;
    width: auto;
    max-width: 100%;
    transform: translateY(-50%);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__status {
    background: #f00;
    font-family: $Manrope-ExtraBold;
    font-size: 10px;
    line-height: 150%;
    text-transform: uppercase;
    padding: 8px 26px 7px;
  }

  &__close {
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    transition: opacity 0.3s ease;

    @media (any-hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__body {
    margin-top: 12px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 28px 35px 30px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.2;
      background: url('../images/other/popup_bg-image.png') center right / contain no-repeat;
    }
  }

  &__title {
    font-family: $Manrope-ExtraBold;
    font-size: 40px;
    line-height: 125%;
    letter-spacing: -1.714px;
  }

  &__content {
    margin-top: 20px;
    font-family: $Manrope-Bold;
    font-size: 14px;
    line-height: 142.857%;
    * {
      margin: 0;
    }

    a {
      text-decoration: underline;
      transition: opacity 0.3s ease;
      @media (any-hover: hover) {
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  &__footer {
    text-align: center;
    font-family: $Manrope-Bold;
    font-size: 14px;
    line-height: 142.857%;

    a {
      text-decoration: underline;
      transition: opacity 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 60px;
      padding: 10px 30px;

      @media (any-hover: hover) {
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
