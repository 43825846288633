@import 'variables.scss';
@import 'mixin';

.bkn {
  .nav-bottom {
    &__left {
      width: 78.1%;
      justify-content: space-between;

      > p {
        font-size: 14px;
        white-space: nowrap;
      }

      .space {
        display: none;
      }
    }

    &__right {
      width: unset;
      min-width: 380px;
      margin-left: 20px;
    }
  }

  .wrapper {
    max-width: 580px;
    width: 100%;
  }

  .title {
    display: flex;
    align-items: center;

    text-transform: uppercase;
    span {
      font-family: $Manrope-Regular;
    }

    h3 {
      margin-right: 20px;
    }
  }

  .payment-term {
    margin-right: 20px;
  }
}

.bkn-order {
  .inf-row {
    p {
      &:nth-child(2) {
        color: inherit;
      }
    }

    .debt {
      color: $col-red;
    }

    .calculated-amount {
      color: $col-mediumSeaGreen;
    }

    .total-amount {
      width: unset;
      text-align: unset;
    }
  }

  .space {
    display: none;
  }

  .order-inf {
    overflow: unset;
    &__title {
      justify-content: unset;
      padding: 0;
    }
  }

  .titles-row,
  .cnt-row {
    justify-content: unset;
  }

  .cnt-row {
    font-family: $Manrope-Bold;
  }

  .no-cnt-row {
    padding: 20px;
    min-height: 60px;
    background-color: $bg-col-white;
    color: $col-manatee;
    @include centering-mod-h;
  }

  .col-title,
  .column {
    &:last-child {
      padding-right: 55px;
    }
  }

  .numbering-title,
  .numbering {
    width: unset;
    min-width: 70px;
  }

  .numbering {
    color: inherit;
  }

  .vendor-code-title,
  .vendor-code {
    display: flex;
    align-items: center;
    width: 14%;
    .btn-copy {
      margin-right: 10px;
    }
    .link {
      overflow-wrap: anywhere;
    }
  }

  .product-name-title,
  .product-name {
    width: 37%;
    margin-right: 7.3%;
  }

  .num-prod-title,
  .num-prod {
    width: 6.3%;
    min-width: 80px;
  }

  .return-prod-title,
  .return-prod {
    width: 6.3%;
    min-width: 95px;
    margin-right: 0;
  }

  .product-price-title,
  .product-price {
    width: 10.2%;
    min-width: 175px;
    margin-right: 2.4%;
  }

  .total-price-title,
  .total-price {
    width: 12.6%;
    min-width: 210px;
    &__cost {
      font-size: 20px;
    }
  }

  .total-price {
    display: flex;
    justify-content: flex-end;
  }

  .num-prod,
  .return-prod {
    strong {
      display: none;
    }
  }

  .result-row {
    justify-content: space-between;
    padding: 0;
    .column {
      &:first-child {
        width: 62%;
      }
    }

    .num-prod,
    .return-prod {
      font-family: $Manrope-Bold;
    }

    .num-prod,
    .return-prod,
    .product-price,
    .total-price {
      > strong {
        display: none;
        font-family: $Manrope-Regular;
      }
    }

    .product-price,
    .total-price {
      text-align: end;
      .currency {
        font-family: $Manrope-Regular;
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .bkn-order {
    .titles-row,
    .cnt-row {
      justify-content: space-between;
    }

    .product-name-title,
    .product-name {
      margin-right: 0;
    }

    .product-price-title,
    .product-price {
      margin-right: 0;
    }

    .result-row {
      .column {
        &:first-child {
          width: 58%;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .bkn-order {
    .col-title,
    .column {
      &:last-child {
        padding-right: 20px;
      }
    }

    .product-price-title,
    .product-price,
    .total-price-title,
    .total-price {
      min-width: 190px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .bkn-order {
    .result-row {
      .column {
        &:first-child {
          width: 57.6%;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .bkn-order {
    .numbering-title,
    .numbering {
      min-width: 40px;
    }

    .result-row {
      .column {
        &:first-child {
          width: 57.2%;
        }
      }
    }
  }
}

@media screen and (max-width: 1365px) {
  .bkn-order {
    .titles-row,
    .cnt-row {
      min-width: unset;
    }
  }
}

@media screen and (max-width: 1279px) {
  .bkn {
    .nav-bottom {
      &__left {
        margin-top: 34px;
        flex-wrap: wrap;

        p {
          width: 100%;
          margin: 0 0 10px;
        }
      }
    }

    .wrapper {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }

  .bkn-order {
    .titles-row {
      display: none;
    }
    .order-inf {
      &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.84%;
      }
    }

    .cnt-row {
      align-items: unset;
      flex-wrap: wrap;
      width: 31.65%;
      min-width: 280px;
      margin: 0 0.84% 1.67%;
      padding: 10px;

      .column {
        padding: 10px;
      }

      .numbering,
      .vendor-code,
      .num-prod,
      .return-prod,
      .product-price,
      .total-price {
        width: 50%;
      }

      .product-price,
      .total-price {
        justify-content: flex-start;
        min-width: unset;
        .amount {
          margin-right: 8px;
        }
        .currency {
          margin-left: 0;
        }
      }

      .product-name {
        width: 100%;
      }

      .num-prod,
      .return-prod {
        strong {
          display: block;
          font-family: $Manrope-Medium;
        }
      }
    }

    .result-row {
      height: auto;
      .column {
        &:first-child {
          width: unset;
        }
      }

      .product-price,
      .total-price {
        width: max-content;

        .amount {
          margin: 0 10px;
        }

        .currency {
          margin: 0;
        }
      }

      .num-prod,
      .return-prod {
        width: max-content;
        > strong {
          margin-right: 10px;
        }
      }

      .num-prod,
      .return-prod,
      .product-price,
      .total-price {
        display: flex;

        > strong {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .bkn-order {
    .cnt-row {
      width: 48.32%;
    }

    .result-row {
      padding: 10px;
      flex-wrap: wrap;
      .column {
        padding: 10px 20px;
        &:first-child {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .bkn-order {
    .inf-row {
      p {
        display: flex;
        border-bottom: unset;
        span {
          width: 50%;
        }

        .total-amount,
        strong {
          width: 50%;
          min-width: unset;
        }
      }

      .space {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .bkn {
    .nav-bottom {
      &__left {
        align-items: unset;
        width: 100%;
        margin-top: 0;
        p {
          width: 50%;
          margin-bottom: 0;
          white-space: unset;
          &.payment-term {
            padding-right: 20px;
          }
          &.order {
            margin-right: 0;
          }
        }
      }
    }
  }

  .bkn-order {
    .result-row {
      .column {
        padding: 10px;
      }

      .num-prod,
      .return-prod,
      .product-price,
      .total-price {
        width: 42%;
        justify-content: space-between;
      }

      .num-prod {
        order: 1;
      }

      .product-price {
        order: 3;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .bkn-order {
    .cnt-row {
      width: 100%;
      margin-bottom: 0;
      &:last-child {
        margin-bottom: 20px;
      }
    }

    .result-row {
      .num-prod,
      .return-prod,
      .product-price,
      .total-price {
        width: 48%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .bkn {
    .nav-bottom {
      &__left {
        .space {
          display: block;
        }
      }
    }
  }

  .bkn-order {
    .btns-wrap {
      flex-direction: column;
      .btn {
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .result-row {
      .num-prod,
      .return-prod,
      .product-price,
      .total-price {
        width: 100%;
      }

      .num-prod {
        order: unset;
      }

      .product-price {
        order: unset;
      }
    }
  }
}
