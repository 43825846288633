@import 'variables.scss';
@import 'mixin';

.nav-bottom {
  &.order {
    .nav-bottom {
      &__left {
        max-width: 1420px;
        width: 100%;
        @include flex-space-between-mod;
        margin-right: 40px;

        .wrapper {
          display: flex;
          align-items: center;
          max-width: 300px;
          width: 100%;
          margin-right: 30px;
        }

        .order,
        .about-document {
          font-family: $Manrope-Bold;
          font-size: 14px;
          line-height: 20px;
        }

        .order-number {
          overflow-wrap: anywhere;
        }

        .wrap {
          display: flex;
          justify-content: space-between;
          width: 45%;
        }
      }

      &__right {
        width: 380px;
      }
    }
  }
}

.orders-wrap {
  .orders-info {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    background-color: $bg-col-white;
    margin-bottom: 20px;

    &__left-list {
      margin-right: 30px;
    }

    .left-list,
    .right-list {
      color: $col-manatee;

      &__value {
        font-family: $Manrope-Bold;
      }

      li {
        display: flex;
      }

      &__value {
        color: $col-sapphire;
      }
    }

    .left-list {
      max-width: 700px;
      width: 100%;

      &__title {
        max-width: 190px;
        width: 100%;

        margin-right: 20px;
      }

      .note {
        overflow-wrap: anywhere;
      }
    }

    .right-list {
      max-width: 420px;
      width: 100%;

      &__title {
        max-width: 210px;
        width: 100%;

        margin-right: 20px;
      }
    }
  }

  .order {
    &__warehouse {
      margin-left: 40px;
    }

    &__btn-wrap {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      justify-content: flex-end;

      font-family: $Manrope-Bold;
      color: $col-sapphire;

      margin-bottom: 20px;

      .btn {
        margin-left: 40px;
      }
    }

    &__wrap {
      @include flex-space-between-mod;
      margin-bottom: 20px;
    }

    &__right-side {
      font-family: $Manrope-Bold;
      color: $col-sapphire;
      @include centering-mod-h;

      .warehouse {
        width: 210px;
        @include centering-mod-h;
        background-color: $bg-col-solitude-l;
        height: 60px;
      }
    }

    .result-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .titles-row,
    .cnt-row {
      padding: 0 10px;
    }

    .column,
    .col-title {
      //display: flex;
      padding: 10px;
    }

    .column {
      font-family: $Manrope-Bold;
    }

    .numbering-title,
    .numbering {
      min-width: 48px;
    }

    .product-img-title,
    .product-img {
      min-width: 80px;
      //width: 5.6%;
      width: 4.4%;
    }

    .product-img {
      position: relative;
    }

    .vendor-code-title,
    .vendor-code {
      //width: 9%;
      width: 8.1%;

      > strong {
        display: none;
        margin-right: 28px;
      }
    }

    .product-name-title,
    .product-name {
      width: 43.8%;
    }

    .num-prod-title,
    .num-prod {
      min-width: 90px;
      width: 5.5%;

      > strong {
        display: none;
        margin-right: 20px;
      }
    }

    .product-price-title,
    .product-price {
      width: 11.8%;
    }

    .selection-title,
    .selection {
      min-width: 61px;
      width: 5.5%;

      > strong {
        display: none;
        margin-right: 20px;
      }
    }

    .total-amount-title,
    .total-amount {
      width: 11.7%;
    }

    .vendor-code {
      display: flex;
      align-items: center;

      .btn-copy {
        margin-right: 10px;
      }

      &__v-code {
        max-width: calc(100% - 40px);
      }
    }

    .num-prod {
      display: flex;
      align-items: center;
    }

    .product-price,
    .total-amount {
      padding-right: 2%;
    }

    .result-row {
      &__count {
        font-size: 20px;
        margin-left: 20px;
      }

      &__right {
        display: flex;
        justify-content: space-between;

        max-width: 460px;
        width: 100%;

        margin-right: 25px;

        p {
          display: flex;

          &:first-child {
            margin-right: 20px;
          }

          &:last-child {
            flex-wrap: wrap;
          }
        }

        .currency {
          margin-left: 20px;
        }
      }

      .count-selected {
        font-size: 20px;
        margin-left: 20px;
      }
    }
  }
}

.orders-history {
  font-family: $Manrope-Bold;
  margin-bottom: 20px;

  .status-title,
  .status {
    width: 8%;
    min-width: 110px;
  }

  .cnt-row:hover {
    background-color: $bg-col-swans-down;

    @media (hover: none) {
      background-color: $bg-col-white;
    }
  }

  .warehouse-title,
  .warehouse {
    width: 10.7%;
    min-width: 175px;
  }

  .warehouse {
    display: flex;
    align-items: center;

    .btn-inf {
      margin-left: 5px;
    }
  }

  .number-title,
  .number {
    width: 12.9%;

    div {
      color: $col-dodger-blue;
      font-family: $Manrope-Bold;
    }
  }

  .date-title,
  .date {
    width: 12.9%;
    min-width: 170px;
  }

  .date {
    font-family: $Manrope-Medium;
    color: $col-manatee;
  }

  .total-amount-title,
  .total-amount {
    width: 11%;
    min-width: 180px;
    margin-right: 1.4%;
  }

  .delivery-information-title,
  .delivery-information {
    width: 26%;
  }
}

@media screen and (max-width: 1600px) {
  .orders-wrap {
    .order {
      .product-name-title,
      .product-name {
        width: 35%;
      }

      .vendor-code-title,
      .vendor-code {
        width: 15%;
      }
    }
  }

  .orders-history {
    .delivery-information-title,
    .delivery-information {
      width: 40%;
    }
  }
}

@media screen and (max-width: 1279px) {
  .nav-bottom {
    &.order {
      .nav-bottom {
        &__left {
          .wrap {
            width: 48%;
          }
        }
      }
    }
  }

  .orders-wrap {
    .order-details {
      &__table {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.84%;
      }
    }

    .order {
      .titles-row {
        display: none;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
      }

      .cnt-row {
        width: 31.65%;
        flex-wrap: wrap;
        padding: 20px;
        margin: 0 0.84% 1.67%;

        .column {
          padding: 10px 0;
        }

        .numbering {
          position: absolute;
          top: 8px;
        }

        .product-img {
          width: 100%;

          .hidden-img {
            display: none;
          }
        }

        .vendor-code {
          width: 100%;
          order: 1;

          > strong {
            display: block;
          }
        }

        .product-name {
          width: 100%;
          order: 0;
        }

        .num-prod,
        .selection {
          width: 50%;
          order: 3;

          > strong {
            display: block;
          }
        }

        .total-amount,
        .product-price {
          width: 50%;
          order: 5;
          justify-content: start;

          padding-right: 8px;

          .amount {
            margin-right: 8px;
          }

          .currency {
            margin-left: 0;
          }
        }

        .product-price {
          .amount {
            font-size: 14px;
          }
        }
      }
    }
  }

  .orders-history {
    overflow: hidden;
    overflow-x: auto;

    .titles-row,
    .cnt-row {
      min-width: 1280px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .nav-bottom {
    &.order {
      .nav-bottom {
        &__left {
          flex-direction: column;
          align-items: unset;
          margin: 37px 0 20px 0;

          .wrap {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .orders-wrap {
    .orders-info {
      flex-direction: column;

      .left-list,
      .right-list {
        li {
          margin-bottom: 5px;
        }
      }

      .right-list {
        &__title {
          max-width: 190px;
        }
      }
    }

    .order {
      .cnt-row {
        width: 48.3%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .order {
    .result-row {
      // flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      height: 100%;

      padding: 10px 20px;

      &__left {
        margin-bottom: 10px;
      }

      &__right {
        flex-direction: column;
        margin-right: 0;

        p {
          margin: 0 0 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .total-sum {
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .nav-bottom {
    &.order {
      .title {
        width: 50%;
        margin-right: 20px;
      }

      .nav-bottom {
        &__left {
          margin: 0;

          .wrapper {
            max-width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .nav-bottom {
    &.order {
      .nav-bottom {
        &__left {
          .wrapper {
            margin-bottom: 40px;
          }

          .title,
          .order-number {
            width: 50%;
            margin-right: 0;
          }

          .wrap {
            width: 100%;
            flex-direction: unset;
          }

          .order,
          .about-document {
            width: 50%;
          }
        }
      }
    }
  }

  .orders-wrap {
    .orders-info {
      .left-list,
      .right-list {
        &__title {
          max-width: 115px;
          flex-shrink: 0;
          margin-right: 17px;
        }

        &__value {
          font-family: $Manrope-Regular;
        }
      }
    }

    .order {
      &__wrap {
        flex-direction: column;
        align-items: unset;
      }

      &__right-side {
        justify-content: unset;
      }

      .cnt-row {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
