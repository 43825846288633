@import 'variables.scss';
@import 'mixin';

.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $bg-col-white;
  color: $col-sapphire;

  height: 60px;

  padding: 0 20px;
  .mob-show {
    // display: none;
  }

  &__left {
    max-width: 170px;
    width: 100%;
    color: $col-manatee;
  }

  &__right {
    margin-left: 3.5%;
  }

  &__center {
    display: flex;

    a,
    button {
      @include centering-mod-h;
      width: 60px;
      height: 60px;

      font-family: $Manrope-Bold;
      color: $col-sapphire;
      &:hover {
        background-color: $bg-col-dodger-blue;
        color: $col-white;
        svg {
          path {
            fill: #ffffff;
          }
        }
      }

      &.active {
        background-color: $bg-col-sapphire;
        color: $col-white;
      }
    }
  }

  .select-wrap {
    &__list {
      width: 158px;
    }
  }

  &__list {
    display: flex;
  }
}

@media screen and (max-width: 1080px) {
  .pagination-wrap {
    &__center {
      margin: 0 auto;
    }

    &__left,
    &__right {
      display: none;
    }
  }
}
@media screen and (max-width: 760px) {
  .pagination-wrap {
    justify-content: center;
    padding: 0;

    .mob-show {
      display: flex;
    }

    &__left {
      display: none;
    }

    &__center {
      .current-page {
        cursor: default;

        @include centering-mod-h;
        font-family: $Manrope-Bold;
        padding: 0 20px;
      }
      a {
        &:hover {
          background-color: unset;
          svg {
            path {
              fill: #0a2352;
            }
          }
        }
      }
      // .m-hide {
      //   display: none;
      // }
    }

    &__right {
      display: none;
    }
  }
}

@media screen and (max-width: 400px) {
  .pagination-wrap {
    &__center {
      a,
      button {
        width: 54px;
      }
    }
  }
}

@media screen and (max-width: 359px) {
  .pagination-wrap {
    &__center {
      .current-page {
        padding: 0;
      }
    }
  }
}
