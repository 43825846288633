@import 'variables.scss';
@import 'mixin';

/* base styles*/
*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: $col-white;
  transition: 0.3s;
}

svg {
  path {
    transition: 0.3s;
  }
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  width: 100%;
  min-width: 320px;
  font-family: $Manrope-Regular, arial, sans-serif;
  color: #3d3d3d;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  background-color: $bg-col-solitude;
  -webkit-tap-highlight-color: transparent;

  &:before {
    content: '';

    position: absolute;
    width: 60px;
    height: 100%;
    background-color: $bg-col-astronaut;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  @media screen and (max-width: 760px) {
    &:before {
      display: none;
    }
  }
}

.expired {
  &:before {
    z-index: 2;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: $bg-col-solitude;
  }
}

.document {
  &__text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    overflow-wrap: anywhere;
  }
}

.favorites {
  .btn-square {
    &:hover {
      background-color: transparent;

      svg {
        path {
          fill: #1d8eff;

          @media (hover: none) {
            fill: #3b4f74 !important;
          }
        }
      }
    }
  }
}

.amount {
  white-space: nowrap;
}

.vendor-code {
  &__v-code {
    overflow-wrap: anywhere;
  }
}

.tecdoc-icon {
  position: fixed;
  left: 20px;
  bottom: 20px;

  width: 60px;
  height: 60px;
  background-color: $bg-col-white;
}

.container {
  max-width: 1860px;
  width: 100%;
  padding: 0 20px;
}

.alter-container {
  position: relative;
  display: flex;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;

  &.content {
    // padding: 0 60px 0 0;
    padding: 0 60px 60px 0;
  }

  &_bg-col-solitude {
    background-color: $bg-col-solitude;
  }
}

.container-m {
  max-width: 1840px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
}

.clearfix {
  overflow: auto;

  &:after {
    content: '';
    clear: both;
    display: table;
  }
}

.btn-up {
  width: 60px;
  height: 60px;
  position: fixed;
  z-index: 99999;
  bottom: 20px;
  right: 80px;
  background-color: #1d8eff;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $bg-col-sapphire;
  }

  &::before {
    content: '';
    position: absolute;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-135deg);
    padding: 3.5px;
    border: solid $col-white;
    border-width: 0 2px 2px 0;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: 600px) {
    right: 20px;
    bottom: 20px;
  }

  @media screen and (max-width: 480px) {
    width: 50px;
    height: 50px;
  }
}

.eclipse {
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 30%;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.10407913165266103) 0%,
      rgba(255, 255, 255, 0.7035189075630253) 20%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.custom-toast-notification {
  width: 280px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  border-radius: 4px;
  margin: 0 auto;
  margin-bottom: 20px;

  &_success {
    color: $col-white;
    background-color: $bg-col-sapphire;
  }

  &_error {
    color: $col-white;
    background-color: $bg-col-red;
  }

  &_info {
  }

  &_focus {
    color: $col-sapphire;
    background-color: $bg-col-white;
  }

  .toast-label {
    @include centering-mod-h;
    width: 100%;
  }
}

.titles-row,
.cnt-row {
  position: relative;
  @include flex-space-between-mod;
  padding: 0 20px;
  background-color: $bg-col-white;
  border-bottom: 1px solid #e6ebf2;
  color: $col-sapphire;

  .total-amount {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    .currency {
      margin-left: 20px;
    }
  }
}

.titles-row {
  font-family: $Manrope-Medium;

  .label-wrap {
    display: flex;
    align-items: center;
    transition: 0.3s;

    &:hover {
      cursor: pointer;
      color: $col-dodger-blue;

      svg {
        path {
          fill: $col-dodger-blue;
        }
      }
    }

    .btn-square {
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.titles-row {
  height: 60px;
}

.cnt-row {
  min-height: 100px;
}

.col-title,
.column {
  padding: 20px;
}

.centering-elem-h {
  display: flex;
  justify-content: center;
  align-items: center;
}

.amount {
  font-size: 20px;
  line-height: 20px;
}

.count {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: absolute;
  width: 20px;
  height: 20px;
  background-color: $bg-col-red;
  border-radius: 50%;
  @include centering-mod-h;
}

@media screen and (max-width: 1023px) {
  input {
    font-size: 16px !important;
  }
}

input[type='checkbox'] {
  cursor: pointer;
  position: relative;
  appearance: none;
  width: 16px;
  height: 16px;

  background-color: $bg-col-solitude;

  &.checkbox-blue {
    cursor: pointer;
    position: relative;
    // appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #e6ebf2;
    border-radius: 4px;
    background-color: transparent;
  }

  &.checkbox-blue:checked {
    &:before {
      background-color: $bg-col-white;
      top: 5px;
      left: 10px;
    }
  }

  &.checkbox-blue:checked {
    &:after {
      background-color: $bg-col-white;
      top: 9px;
      left: 6px;
    }
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='checkbox']:checked {
  background-color: transparent;

  &.checkbox-blue {
    background-color: $bg-col-sapphire;
    border: none;
  }
}

input[type='checkbox']:checked:before,
input[type='checkbox']:checked:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 8px;

  width: 2px;
  height: 10px;
  background-color: $bg-col-dodger-blue;
  transform: rotate(45deg);
}

input[type='checkbox']:checked:after {
  top: 6px;
  left: 3px;
  height: 5px;
  transform: rotate(-45deg);
}

input[type='radio'] {
  width: 16px;
  height: 16px;
  position: relative;
  appearance: none;
  border: 2px solid #8f94b3;
  border-radius: 50%;
  cursor: pointer;
}

input[type='radio']:checked {
  border-color: #1d8eff;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 70%;
    height: 70%;
    background-color: $bg-col-dodger-blue;
    border-radius: 50%;
  }
}

.sub-list {
  font-family: $Manrope-Bold;
  margin: 20px 0 0 0;

  input[type='checkbox'] {
    margin-right: 22px;
  }

  &__label-wrap {
    min-height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.disabled {
      opacity: unset;

      input[type='checkbox'] {
        opacity: 0.6;
      }

      .sub-list {
        &__name,
        &__count {
          opacity: 0.3;
        }
      }
    }
  }

  &__checkbox-wrap {
    display: flex;
    align-items: center;

    text-transform: uppercase;
    margin-right: 15px;
  }
}

.discount {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  @include centering-mod-h;
  width: 92%;
  font-family: $Manrope-Bold;

  background-color: $bg-col-red;
  padding: 5px;
  color: $col-white;
}

.promo-points {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d8eff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  right: 0;
  z-index: 100;
  bottom: 0;
}

.product-count-wrap {
  display: flex;
  align-items: center;
  height: 100%;
}

.product-count {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $bg-col-mediumSeaGreen;
  color: $col-white;
  @include centering-mod-h;

  &_bg-col-sunglow {
    background-color: $bg-col-sunglow;
  }

  &_bg-col-solitude {
    background-color: $bg-col-solitude;
  }
}

.volume {
  position: absolute;
  z-index: 100;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  // z-index: 99;

  &__item {
    @include centering-mod-h;
    width: 50px;
    height: 30px;
    border: 1px solid #e6ebf2;
    background-color: $bg-col-white;

    &.active {
      background-color: $bg-col-sapphire;
      color: $col-white;
      border: none;
    }
  }
}

.link {
  transition: 0.3s;
  font-family: $Manrope-Bold;

  &_col-manatee {
    color: $col-manatee;

    &:hover {
      background-color: $bg-col-sapphire;
      color: $col-white;
      border: none;
    }
  }

  &_col-sapphire {
    color: $col-sapphire;
  }

  &_col-blue {
    color: $col-dodger-blue;

    &:hover {
      color: $col-sapphire;

      @media (hover: none) {
        color: $col-dodger-blue;
      }
    }

    &__visible {
      z-index: 999;
    }
  }
}

.tags-wrap {
  padding: 0 17px 0 20px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -11px -11px;
  // padding: 0 17px 0 20px;

  &__item {
    font-family: $Manrope-Bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: $bg-col-solitude;

    margin: 0 11px 11px 11px;

    &:hover {
      .btn-close {
        background-color: $bg-col-red;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }

    .tag-name {
      padding: 0 10px;
    }
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.result-row {
  @include flex-space-between-mod;
  background-color: $bg-col-sapphire;
  color: $col-white;
  padding: 0 20px;
  height: 60px;

  &__left {
    display: flex;

    p {
      display: flex;
      margin: 0 40px 0 0;
    }
  }

  .number-items,
  .number-units {
    font-size: 20px;
    margin-left: 24px;
  }

  &__all {
    font-size: 20px;
  }

  &__right {
    margin-right: 74px;

    p {
      display: flex;
    }
  }

  .total-sum {
    font-size: 20px;
    line-height: 20px;
    margin-left: 20px;
  }
}

.history-search {
  width: 400px;
  //display: none;
  position: absolute;
  z-index: 99999;
  top: 60px;
  left: 0;

  color: $col-sapphire;

  max-height: 480px;

  overflow: auto;

  background-color: $bg-col-white;

  &__type {
    flex-shrink: 0;
    color: $col-manatee;
    width: 80px;
    margin-right: 10px;
  }

  &__val {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  li {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    cursor: pointer;
    transition: 0.3s;
    border-top: 1px solid #e6ebf2;

    &:hover {
      background-color: rgba($color: $bg-col-dodger-blue, $alpha: 0.5);
    }

    &.disabled {
      &:hover {
        background-color: $bg-col-white;
      }
    }
  }
}

.select-wrap {
  font-family: $Manrope-Bold;
  min-height: 60px;
  display: flex;
  align-items: center;
  // z-index: 10;
  position: relative;
  font-size: 14px;
  padding-right: 45px;
  padding-left: 20px;
  cursor: pointer;

  &__triangle {
    position: absolute;
    content: '';
    top: calc(50% - 3px);
    right: 17.5px;
    border: 5px solid transparent;
    border-top: 5px solid #0a2352;

    // margin: 3px 0 0 6%;
    border: 5px solid transparent;
    border-top: 5px solid #0a2352;
  }

  &:before {
    position: absolute;
    content: '';
    top: calc(50% - 3px);
    right: 12px;

    border: 5px solid transparent;
    border-top: 5px solid #0a2352;
  }

  &__list {
    // display: none;
    position: absolute;
    z-index: 99999;
    top: 60px;
    left: 0;
    width: 100%;
    // color: $col-sapphire;
    color: $col-manatee;
    // max-height: 320px;
    max-height: 480px;
    overflow: auto;
    background-color: $bg-col-white;

    li {
      display: flex;
      align-items: center;
      height: 60px;
      padding: 0 20px;
      cursor: pointer;
      transition: 0.3s;
      border-top: 1px solid #e6ebf2;

      &:hover {
        background-color: rgba($color: $bg-col-dodger-blue, $alpha: 0.5);
      }

      &.disabled {
        &:hover {
          background-color: $bg-col-white;
        }
      }

      &.selected {
        color: $col-sapphire;
      }
    }

    .btn-square {
      width: 80px;
      color: $col-white;
    }

    input {
      width: 100%;
      height: 100%;
      border: none;

      &::placeholder {
        color: $col-manatee;
      }
    }
  }
}

.nav-buttons {
  position: relative;

  &__item {
    width: 50%;
    // width: 100%;
    min-height: 60px;
    justify-content: center;
    font-family: $Manrope-Bold;
    background-color: $bg-col-white;
    color: $col-sapphire;
    border: none;
    padding: 0 20px;

    &_splitter {
      position: absolute;
      top: 0;
      left: calc(50% - 0.5px);
      z-index: 1;
      width: 1px;
      height: 100%;
      background-color: $bg-col-solitude;
    }

    &_full-screen {
      width: 100%;
    }
  }

  &__select-wrap {
    &:before {
      display: none;
    }
  }
}

.product-count {
  cursor: pointer;
}

//begin btn
button {
  font-family: $Manrope-ExtraBold;
  background-color: unset;
  border: none;
  transition: 0.3s;
}

.btn-text-blue {
  font-size: 14px;
  line-height: 20px;
  color: $bg-col-dodger-blue;
  &:hover {
    color: $col-sapphire;
    @media (hover: none) {
      color: $col-dodger-blue;
    }
  }
}

.btn-display {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;

  border-bottom: 1px solid #e6ebf2;
  padding: 0 17px 0 20px;

  .indicator {
    flex-shrink: 0;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    margin-left: 15px;

    svg {
      transition: 0.3s;
      transform: rotateX(180deg);
      position: absolute;
      top: 12px;
      left: 9px;

      path {
        fill: $bg-col-sapphire;
      }
    }

    &:before,
    &:after {
      display: none;
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 5px);

      width: 10px;
      height: 2px;

      background-color: $bg-col-sapphire;
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &.active {
    .indicator {
      background-color: $bg-col-sapphire;

      &:before {
        background-color: $bg-col-white;
      }

      &:after {
        display: none;
      }

      svg {
        transform: rotateX(0deg) translateY(-2px);

        path {
          fill: $bg-col-white;
        }
      }
    }
  }
}

.btns-nav {
  display: flex;

  @media screen and (max-width: 960px) {
    margin-right: 20px;
  }

  @media screen and (max-width: 760px) {
    margin-right: 0;
  }

  .next {
    svg {
      transform: rotate(180deg);
    }

    margin-left: 20px;
  }
}

.btn {
  font-family: $Manrope-Bold;
  @include centering-mod-h;
  height: 60px;
  background-color: $bg-col-astronaut;
  color: $col-white;
  transition: 0.3s;
  width: 210px;

  &:hover {
    background-color: $bg-col-dodger-blue;
    color: $col-white;

    @media (hover: none) {
      background-color: $bg-col-astronaut;
    }
  }

  &_bg-col-white {
    background-color: $bg-col-white;
    color: $col-sapphire;

    @media (hover: none) {
      background-color: $bg-col-white !important;
      color: $col-sapphire !important;
    }
  }

  &_bg-col-gray {
    background-color: $bg-col-solitude;
    color: $col-sapphire;
  }

  &_bg-col-sapphire {
    background-color: $bg-col-sapphire;
  }

  &_bg-col-astronaut {
    background-color: $bg-col-astronaut;
  }
}

.btn-review {
  position: absolute;
  right: 0;
  bottom: 0;

  font-family: $Manrope-Bold;
  color: $col-dodger-blue;

  &:hover {
    color: $col-sapphire;
  }
}

.btn-burger {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 11;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
  background-color: $bg-col-sapphire;

  &.active {
    position: fixed;
    top: 10px;
    left: 250px;
    z-index: 99999;
    transition: background-color 0s;
    background-color: unset;

    @media screen and (max-width: 480px) {
      left: unset;
      right: 20px;
    }

    .btn-burger {
      &__wrap {
        height: 20px;
      }

      &__line {
        left: 0;
        position: absolute;
        background-color: $bg-col-sapphire;

        &:first-child {
          transform: rotate(405deg);
          top: 8px;
        }

        &:last-child {
          transform: rotate(-405deg);
          width: 18px;
          bottom: 10px;
        }
      }

      &__line-middle {
        transform: scale(0);
      }
    }
  }

  &__wrap {
    position: relative;
    width: 18px;
    height: 14px;
  }

  &__line {
    position: absolute;
    height: 2px;
    background-color: white;
    transition: 0.3s ease-out;
    border-radius: 30px;
  }

  &__line-top {
    top: 0;
    width: 18px;
  }

  &__line-middle {
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
  }

  &__line-bottom {
    bottom: 0;
    width: 12px;
  }
}

.small-squares {
  z-index: 10;
  @include centering-mod-h;
  flex-direction: column;

  &__item {
    width: 4px;
    height: 4px;
    background-color: $bg-col-manatee;
    margin-bottom: 4px;

    &:first-child {
      background-color: $bg-col-dodger-blue;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.btn-square {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 60px;
  height: 60px;

  border: none;
  cursor: pointer;
  transition: 0.3s;

  &.active {
    background-color: $bg-col-sapphire;

    @media screen and (max-width: 1023px) {
      background-color: $bg-col-sapphire !important;
    }

    svg {
      path {
        fill: white;
      }
    }
  }

  &:hover {
    background-color: $bg-col-dodger-blue;

    @media screen and (max-width: 1023px) {
      background-color: unset;
    }

    svg {
      path {
        fill: white;
      }
    }
  }

  &_bg-col-white {
    background-color: $bg-col-white;

    &:hover {
      @media screen and (max-width: 1023px) {
        background-color: $bg-col-white;
      }

      svg {
        path {
          @media screen and (max-width: 1023px) {
            fill: #0a2352;
          }
        }
      }
    }
  }

  &_bg-col-astr {
    background-color: $bg-col-astronaut;

    @media (hover: none) {
      background-color: $bg-col-astronaut !important;
    }
  }

  &_bg-col-sapphire {
    background-color: $bg-col-sapphire;
  }

  &_favorites {
    background-color: transparent !important;

    &:hover {
      svg {
        path {
          fill: #1d8eff;

          @media (hover: none) {
            background-color: #3b4f74 !important;
          }
        }
      }
    }
  }
}

.btn-change {
  width: 220px;
  height: 60px;
  background-color: $bg-col-astronaut;
  color: $col-white;
  transition: 0.3s;

  &:hover {
    background-color: $bg-col-dodger-blue;
  }
}

.btn-close {
  background-color: $bg-col-solitude;

  &:hover {
    background-color: $bg-col-red;

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
}

.close-btn {
  &_small-screen {
    width: 40px;
    height: 40px;
  }
}

.btn-delete {
  width: 60px;
  height: 60px;
  @include centering-mod-h;
  flex-shrink: 0;
}

.btn-minus,
.btn-plus {
  @include centering-mod-h;
  max-width: 60px;
  width: 100%;
  min-width: 40px;
  height: 60px;

  @media screen and (max-width: 1440px) {
    min-width: 60px;
    min-height: 60px;
  }
}

.btn-copy,
.btn-close,
.btn-inf {
  @include centering-mod-h;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.3s;
  padding: 0;
}

.btn-inf {
  z-index: 9;
}

.btn-copy {
  &:hover {
    svg {
      path {
        fill: #0a2352;

        @media (hover: none) {
          fill: #1d8eff;
        }
      }
    }
  }
}

.report-block {
  @include centering-mod-h;
  width: 100%;
  max-height: 440px;
  height: 100%;
  text-align: center;

  &__cnt {
    background-color: $bg-col-white;
    padding: 20px;
  }

  &__text {
    max-width: 600px;
  }
}

.btn-turn-back {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  color: $col-dodger-blue;
  font-family: $Manrope-Bold;

  &:hover {
    color: $col-sapphire;

    svg {
      path {
        fill: $col-sapphire;
      }
    }
  }

  span {
    margin-left: 15px;
  }
}

//end btn

//begin title
.title {
  &.analogues {
    display: flex;
    align-items: center;
    height: 95px;

    .analogues__tecdoc {
      margin-left: 30px;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 90px;
    line-height: 83px;
    letter-spacing: -2.02px;
    color: $col-sapphire;
  }

  h2 {
    font-weight: 400;
    font-size: 48px;
    line-height: 51px;

    @media screen and (max-width: 1024px) {
      font-size: 35px;
      line-height: 40px;
    }

    @media screen and (max-width: 600px) {
      font-size: 30px;
      line-height: 35px;
    }
  }

  h3 {
    font-family: $Manrope-ExtraBold;
    font-size: 20px;
    line-height: 30px;
    color: $col-sapphire;
  }

  h4 {
    font-family: $Manrope-ExtraBold;
    font-size: 14px;
    line-height: 20px;
    color: $col-sapphire;
  }
}

//end title

.product-img {
  @include centering-mod-h;
  cursor: pointer;

  .hidden-img {
    left: 100%;
    position: absolute;
    z-index: 999;

    width: 240px;
    height: 240px;
    background-color: $bg-col-white;
    box-shadow: 0 0 50px -18px #00000091;

    padding: 20px;

    &_top {
      top: 0;
    }

    &_bottom {
      bottom: 0;
    }
  }

  .product-img__img {
    position: relative;
    z-index: 99;
    object-fit: contain;
  }
}

.catalog-grid-l:nth-last-child(-n + 2),
.cat-grid-l-fil:nth-last-child(-n + 2) {
  .product-img {
    .hidden-img_top {
      top: unset;
      bottom: 0;
    }
  }
}

.product-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .currency {
    margin-left: 20px;
  }
}

.total-price {
  flex-wrap: wrap;
}

.product-name {
  font-size: 16px;

  &__text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline;
    }
  }
}

.buy {
  &__wrap {
    position: relative;
    display: flex;
    max-width: 150px;
    width: 100%;
    justify-content: flex-end;
  }

  .loading[disabled] {
    opacity: 1;
  }

  .buy-count {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 90px;
    min-width: 65px;
    width: 100%;
    max-height: 60px;
    flex-shrink: 1;

    font-family: $Manrope-Bold;
    color: $col-manatee;
    background-color: $bg-col-solitude;
    padding-left: 10px;

    input {
      background-color: transparent;
      border: none;
      width: 100%;
      height: 100%;
      padding: 0;
    }

    svg {
      position: absolute;
      right: 6px;
    }
  }

  .count {
    cursor: pointer;
    position: absolute;
    top: -12px;
    right: -11px;
    width: 30px;
    height: 30px;
    background-color: $bg-col-red;
    border-radius: 50%;
    color: $col-white;
    @include centering-mod-h;

    &__close {
      display: none;
    }

    &:hover {
      font-size: 0;

      .count {
        &__close {
          display: block;
        }
      }
    }

    &.disabled {
      background-color: $bg-col-solitude;
      cursor: default;
      opacity: 1;
      pointer-events: none;
      position: absolute;
    }
  }
}

.buy-count {
  padding: 0 10px;
  justify-content: space-between;
}

.selection {
  display: flex;
}

.no-results {
  display: flex;
  justify-content: center;

  margin-top: 20px;

  p {
    max-width: 300px;
    overflow-wrap: break-word;
    text-align: center;
    color: $col-manatee;
  }
}

.flip-block {
  -webkit-perspective: 600px;
  -moz-perspective: 600px;

  .message-block {
    display: grid;
    grid-template-rows: auto 1fr auto auto;
    grid-template-columns: 100%;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
    background-color: $bg-col-white;
    transition: all 0.5s ease-in-out;
    -webkit-backface-visibility: hidden;
    padding: 20px;

    .field-wrap {
      margin-bottom: 20px;

      input {
        width: 100%;
        height: 60px;
        padding: 20px;
        background-color: $bg-col-solitude;
        border: none;

        &::placeholder {
          color: $col-manatee;
        }
      }
    }

    textarea {
      width: 100%;
      resize: none;
      padding: 20px;
      background-color: $bg-col-solitude;
      border: none;
      margin-bottom: 15px;

      &::placeholder {
        color: $col-manatee;
      }
    }

    &__btn-wrap {
      display: flex;
      justify-content: flex-end;

      .btn {
        width: 140px;
      }
    }

    &__bottom-block {
      display: flex;
      justify-content: space-between;

      .btn {
        width: 140px;
      }
    }
  }

  .front {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: $bg-col-white;
    transition: all 0.5s ease-in-out;
    -webkit-backface-visibility: hidden;
    padding: 20px;
    color: $col-sapphire;

    &__title {
      position: relative;
      z-index: 10;

      h3 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &.feedback {
    .front {
      background-color: $bg-col-sapphire;
      color: $col-white;

      &__title {
        h3 {
          color: $col-white;
        }
      }

      &__img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .message-block {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 100%;

      &__bottom-block {
        align-items: center;

        .btn {
          flex-shrink: 0;

          @media screen and (max-width: 1440px) {
            width: 120px;
          }

          @media screen and (max-width: 1279px) {
            width: 140px;
          }

          @media screen and (max-width: 369px) {
            width: 120px;
          }
        }
      }
    }

    form {
      display: grid;
      grid-template-rows: auto 1fr auto auto;
      grid-template-columns: 100%;
    }

    .bottom-block {
      display: flex;
      justify-content: space-between;
      z-index: 1;

      p {
        display: flex;
        align-items: center;
        margin: 0 20px 0 0;

        max-width: 200px;
        width: 100%;
      }

      .btn {
        width: 140px;
        min-width: 100px;
      }
    }

    .wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 60px;
      padding: 5px 0;

      margin-right: 20px;
    }

    .file-wrap {
      display: flex;

      &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 210px;
        width: 100%;

        @media screen and (max-width: 1279px) {
          max-width: 345px;
        }
      }
    }

    .delete-file {
      left: -4px;
    }
  }

  &.flip {
    .front {
      transform: rotateY(180deg) !important;
    }

    .back {
      z-index: 3;
      transform: rotateY(0deg);
    }
  }
}

.checkbox-block {
  display: flex;
  align-items: center;
  max-width: max-content;

  font-family: $Manrope-Medium;

  cursor: pointer;
  color: $col-sapphire;

  input {
    margin-right: 12px;
  }
}

.uploading-file {
  position: relative;
  display: block;
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: $bg-col-sapphire;

        @media (hover: none) {
          fill: #1d8eff !important;
        }
      }
    }
  }

  input[type='file'] {
    position: absolute;
    right: 100000px;
    overflow: hidden;
    width: 0;
    height: 0;
  }

  p {
    display: flex;
    margin: 0;

    svg {
      flex-shrink: 0;
      margin: 0 14px 0 3px;
    }
  }
}

.delete-file {
  transition: 0.3s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 25px;
  height: 25px;
  border: unset;
  background-color: unset;
  margin-right: 5px;
  padding: 0;
  border-radius: 50%;

  span {
    position: relative;
    display: block;
    width: 15px;
    height: 15px;

    &:before,
    &:after {
      transition: 0.3s;
      content: '';
      position: absolute;
      left: 6px;
      width: 3px;
      height: 15px;
      background-color: $bg-col-red;
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &:hover {
    background-color: $bg-col-red;

    span {
      &:before,
      &:after {
        background-color: $bg-col-white;
      }
    }
  }
}

.footer_placeholder {
  height: 200px;
}

.overlay {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;

  &_white {
    background-color: $bg-col-white;
  }

  &_solitude {
    background-color: $bg-col-solitude;
  }
}

.hidden {
  visibility: hidden;
  cursor: default;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  overflow: hidden;
}

/*fonts*/

@mixin font-face($style-name, $file: $style-name, $weight: normal, $style: normal) {
  $filepath: '../fonts/' + $file;

  @font-face {
    font-family: quote($style-name);
    src: URL($filepath + '.eot');
    src: URL($filepath + '.eot?#iefix') format('embedded-opentype'),
      // URL($filepath + ".woff") format("woff"),
      URL($filepath + '.ttf') format('truetype'),
      URL($filepath + '.svg#' + $style-name + '') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

//  example->    @include font-face('PFDinTextPro', 'PFDinTextPro-Bold', 700, italic);
@include font-face('Manrope-Regular', 'Manrope-Regular', 400);
@include font-face('Manrope-Medium', 'Manrope-Medium', 500);
@include font-face('Manrope-Bold', 'Manrope-Bold', 700);
@include font-face('Manrope-ExtraBold', 'Manrope-ExtraBold', 800);

/*/fonts*/

/* /base styles */

/*End Layout*/
