@import 'variables.scss';
@import 'mixin';

.debts-wrap {
  .cashless {
    .currency {
      margin-left: 20px;
    }
  }

  .result-row {
    width: 100%;
    font-size: 20px;

    &__left-title {
      font-family: 'Manrope-Bold';
      padding-left: 0;
    }

    &__right-wrap {
      display: flex;
      max-width: 440px;
      width: 100%;
    }

    .cash,
    .cashless {
      display: flex;
      align-items: center;
      font-size: 20px;
      .space {
        display: none;
      }
    }

    .cashless {
      justify-content: flex-end;
      flex-wrap: wrap;
      text-align: end;

      // .currency {
      //   margin-left: 20px;
      // }
    }
  }

  .cash {
    &__debt {
      white-space: nowrap;
      margin-right: 20px;
    }
  }

  .total-debt {
    margin-bottom: 20px;

    &__wrap {
      width: 100%;
    }

    .col-title {
      &:first-child {
        flex-grow: 1;
      }
    }

    .cash,
    .cash-title {
      min-width: 230px;
    }

    .cashless,
    .cashless-title {
      min-width: 210px;
    }

    .currency {
      font-family: $Manrope-Medium;
      font-size: 14px;
    }
  }

  &__btns-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .total-debt,
  .details-debt {
    &__wrap {
      .cnt-row {
        .column {
          &:first-child {
            font-size: 16px;
            flex-grow: 1;
          }
        }
      }
    }

    .cashless {
      .wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        font-family: $Manrope-Bold;
        font-size: 20px;
      }

      > strong {
        display: none;
      }

      // .currency {
      //   margin-left: 20px;
      // }
    }

    .cash {
      font-size: 20px;

      > strong {
        display: none;
      }

      .wrap {
        font-family: $Manrope-Bold;
      }
    }
  }
}

.details-debt {
  // a {
  //   color: $col-dodger-blue;
  // }

  .cnt-row {
    transition: 0.3s;
    &:hover {
      background-color: $bg-col-swans-down;
      @media (hover: none) {
        background-color: $bg-col-white;
      }
    }

    .column {
      &:nth-child(2) {
        font-size: 16px;
      }
    }
  }

  .numbering-title,
  .numbering {
    min-width: 63px;
  }

  .department-title,
  .department {
    width: 16%;
    min-width: 160px;
  }

  .number-title,
  .number {
    width: 15%;
    min-width: 90px;
    overflow-wrap: anywhere;
  }

  .number div {
    color: $col-dodger-blue;
  }

  .document-title,
  .document {
    width: 22%;
  }

  .date-title,
  .date {
    width: 15%;
    min-width: 165px;
    strong {
      display: none;
      color: $col-sapphire;
    }
  }

  .payment-term-title,
  .payment-term {
    width: 15%;
    min-width: 165px;
    strong {
      display: none;
      color: $col-sapphire;
    }
  }

  .cash-title,
  .cash {
    width: 15%;
    min-width: 190px;
  }

  .cashless-title,
  .cashless {
    min-width: 214px;

    // .currency {
    //   margin-left: 20px;
    // }
  }

  .numbering,
  .department,
  .number,
  .document,
  .cash,
  .cashless {
    font-family: $Manrope-Bold;
  }

  .date,
  .payment-term {
    color: $col-manatee;
  }

  .cash {
    font-size: 20px;
  }

  .currency {
    font-family: $Manrope-Medium;
    font-size: 14px;
  }

  .result-row {
    &__left-title {
      margin-right: 156px;
    }

    &__right-wrap {
      max-width: unset;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 1536px) {
  .debts-wrap {
    .total-debt,
    .details-debt {
      .column,
      .col-title {
        padding: 10px;
      }
    }

    .cash {
      &__debt {
        margin-right: 10px;
      }
    }

    .cashless {
      .currency {
        margin-left: 10px;
      }
    }
  }

  .details-debt {
    .cashless-title,
    .cashless {
      min-width: 187px;
    }

    .numbering-title,
    .numbering {
      min-width: 55px;
    }

    .number-title,
    .number {
      width: 8%;
    }

    .date-title,
    .date,
    .payment-term-title,
    .payment-term {
      min-width: 150px;
    }

    .document-title,
    .document {
      width: 30%;
    }

    .result-row {
      .cashless {
        min-width: 187px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .debts-wrap {
    .total-debt,
    .details-debt {
      .cash,
      .cashless {
        width: 50%;
        min-width: unset;
        &__debt {
          margin-right: 14px;
        }
        > strong {
          display: block;

          font-family: $Manrope-Regular;
          font-size: 14px;
          margin-bottom: 20px;
        }
        .wrap {
          justify-content: unset;
        }

        .currency {
          margin: 0;
        }
      }
    }

    .total-debt {
      &__wrap {
        display: flex;
        flex-wrap: wrap;

        width: inherit;
        margin: 0 -0.84%;
      }

      .titles-row {
        display: none;
      }

      .cnt-row {
        align-items: unset;
        flex-wrap: wrap;

        width: 48.32%;

        padding: 10px;
        margin: 0 0.84% 1.67%;

        .column {
          padding: 10px;
          &:first-child {
            width: 100%;
          }
        }
      }
    }

    .result-row {
      &__right-wrap {
        max-width: 664px;
      }

      .cash,
      .cashless {
        &__debt {
          margin: 0 20px;
        }
        > strong {
          margin: 0;
        }
      }
    }
  }

  .details-debt {
    &__wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.84%;
    }
    .titles-row {
      display: none;
    }

    .cnt-row {
      align-items: unset;
      flex-wrap: wrap;
      width: 31.65%;
      padding: 10px;

      margin: 0 0.84% 1.67%;
      .column {
        padding: 10px;
      }
    }

    .numbering {
      order: 2;
      text-align: end;
    }

    .department {
      order: 1;
      width: 75%;
    }

    .number {
      order: 3;
      width: 100%;
    }

    .document {
      order: 4;
      width: 100%;
    }

    .date {
      order: 5;
      width: 50%;
      strong {
        display: block;
        margin-bottom: 20px;
      }
    }

    .payment-term {
      order: 6;
      width: 50%;
      strong {
        display: block;
        margin-bottom: 20px;
      }
    }

    .date,
    .payment-term {
      min-width: unset;
    }

    .cash {
      order: 7;
    }

    .cashless {
      order: 8;
    }

    .result-row {
      &__left-title {
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .debts-wrap {
    .result-row {
      flex-wrap: wrap;
      height: auto;
      padding: 10px;
      &__right-wrap {
        max-width: unset;
        justify-content: space-between;
      }

      .column {
        padding: 10px;
      }

      .cash,
      .cashless {
        width: 49%;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .debts-wrap {
    .details-debt {
      .cnt-row {
        width: 48.32%;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .debts-wrap {
    .total-debt {
      .cnt-row {
        width: 100%;
        margin-bottom: 0;
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }

    .result-row {
      &__right-wrap {
        flex-direction: column;
      }
      .cash,
      .cashless {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .debts-wrap {
    .details-debt {
      &__wrap {
        margin: 0;
      }
      .cnt-row {
        width: 100%;

        margin: 0;
        &:last-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .debts-wrap {
    &__btns-wrap {
      position: relative;

      width: 100%;
      min-width: 280px;

      margin: 0 auto 20px;

      &:before {
        content: '';
        position: absolute;

        left: 50%;
        transform: translateX(-50%);

        width: 1px;
        height: 100%;

        background-color: $bg-col-solitude;
      }

      .btn {
        width: 50%;
        padding: 0 20px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .debts-wrap {
    .result-row {
      .cash,
      .cashless {
        width: 100%;
        justify-content: space-between;
        &__debt {
          margin: 0 10px;
        }

        > strong {
          text-align: start;
        }
        .space {
          display: block;
        }
      }
    }
  }
}
