@import 'variables.scss';
@import 'mixin';

.news-page {
  .title {
    @media screen and (max-width: 850px) {
      margin-right: 0;
    }
  }
  .btn-text-blue {
    @media screen and (max-width: 850px) {
      margin: 3px 10px 0 10px;
    }
    @media screen and (max-width: 760px) {
      font-size: 12px;
      line-height: 16px;
      margin-left: 20px;
    }
  }

  .confirm-content {
    background-color: #e6ebf2;
    align-items: center;
    margin-left: 10px;
    font-size: 14px;
    line-height: 20px;

    display: none;

    &.show {
      display: flex;
    }

    @media (max-width: 992px) and (min-width: 761px) {
      position: absolute;
      left: 0;
      margin-left: 0;
    }

    @media (max-width: 760px) {
      margin-left: 20px;
      font-size: 12px;
      line-height: 16px;
    }

    @media (max-width: 480px) {
      position: absolute;
      left: 0;
      margin-left: 0;
    }
    &__text {
      margin: 0;
      white-space: nowrap;
    }

    &__btns {
      display: flex;

      button {
        &:first-child {
          margin: 0 15px;
          padding: 0 10px;
          color: #1d8eff;
        }
        &:last-child {
          display: inline-flex;
          width: auto;
          padding: 0 58px;
          @media (max-width: 992px) {
            padding: 0 32px;
          }
        }
        @media (max-width: 760px) {
          height: 45px;
        }
      }
    }
  }

  .nav-bottom__left {
    @media (max-width: 760px) {
      min-height: 45px;
    }
  }
}

.news {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.55%;
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 23.9%;
    background-color: $bg-col-white;
    padding-bottom: 20px;

    margin: 0 0.55% 1.087%;

    .discount {
      max-width: max-content;
      top: 10px;
      left: 10px;
    }

    &__img-wrap {
      max-width: 440px;
      width: 100%;
      margin-bottom: 20px;
      position: relative;
      padding-top: 47.6%;
      overflow: hidden;

      img {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-height: 100%;
      }

      margin-bottom: 20px;
    }

    &__cnt {
      position: relative;
      padding: 0 20px;
    }

    &__status {
      width: 10px;
      height: 10px;
      background-color: $bg-col-red;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      left: 5px;
    }

    &__title {
      h3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
      }
    }

    .publication {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      &__date {
        color: $col-manatee;
      }

      &__views-wrap {
        display: flex;
        align-items: center;
      }

      &__views {
        font-family: $Manrope-Bold;
        color: $col-sapphire;
        margin-right: 20px;
      }
    }

    &__desc {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      color: $col-sapphire;
      margin-bottom: 20px;
    }

    .link {
      display: flex;
      justify-content: flex-end;
      color: $col-dodger-blue;
      padding-right: 20px;
    }
  }
}

@media screen and (max-width: 1365px) {
  .news {
    margin: 0 -0.8;
    .item {
      width: 31.73%;
      margin: 0 0.8% 1.6%;
    }
  }
}

@media screen and (max-width: 960px) {
  .news {
    margin: 0 -1.16%;
    .item {
      width: 47.68%;
      margin: 0 1.16% 2.32%;
    }
  }
}

@media screen and (max-width: 480px) {
  .news {
    margin: 0;
    .item {
      width: 100%;
      margin: 0 0 20px 0;
    }
  }
}
