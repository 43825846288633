@import 'variables.scss';
@import 'mixin';

.shadow {
  box-shadow: 0 40px 120px rgba(0, 0, 0, 0.1);
}

.card-shadow {
  -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.volume-list {
  display: flex;

  &__item {
    @include centering-mod-h;
    background-color: $bg-col-astronaut;
    color: $col-white;
    width: 50px;
    height: 30px;
    border: 1px solid $bg-col-sapphire;

    &:hover {
      background-color: $bg-col-white;
      color: $col-sapphire;
    }

    &.active {
      background-color: $bg-col-white;
      color: $col-sapphire;
      border: none;
    }
  }
}

.user {
  z-index: auto;
}

.user-profile-info {
  position: absolute;
  right: 60px;
  top: 0;
  width: 260px;
  padding: 20px 30px 30px 30px;
  background-color: $bg-col-sapphire;
  color: $col-white;
  z-index: 10000;

  &__wrap {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }

  &__name {
    font-family: $Manrope-Bold;
    width: 200px;
    margin: 0 auto;
  }

  &__code {
    margin-bottom: 20px;
    font-family: $Manrope-Medium;

    span {
      color: $col-dodger-blue;
      margin-left: 20px;
    }
  }

  &__email {
    color: $col-manatee;
    font-family: $Manrope-Regular;
    margin-bottom: 30px;
  }

  .select-wrap__list li {
    color: $col-sapphire;

    button {
      background-color: $bg-col-white;
      width: 100%;
      height: 100%;
      padding: 10px;

      &:hover {
        background-color: rgba(29, 142, 255, 0.5);
      }

      &:disabled {
        background-color: #e6ebf2;
      }
    }
  }

  .profile-footer__wrap {
    width: 100%;
    height: auto;

    a {
      width: 100%;
      height: 60px;
      font-family: $Manrope-Bold;
      background-color: $bg-col-astronaut;
      @include centering-mod-h;
    }
  }
}

.buy-count {
  min-width: 60px;

  &_input {
    text-align: center;
    cursor: auto;
  }
}

.copy-notification {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 9999;
}

.cat-grid-c-fil,
.catalog-grid-c,
.list-recomm-goods__item {
  .description-card {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #fff;
    color: #8f94b3;
    font-family: $Manrope-Regular;
    padding: 0 20px 20px 20px;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: white;
    }
  }

  &.active {
    &.card-shadow {
      .description-card {
        left: -4px;
      }
    }
  }

  .vendor-code {
    &__v-code {
      width: fit-content;
      max-width: 70%;
    }
  }
}

.list-recomm-goods__item {
  &.product-smaller-card {
    .description-card {
      top: 100%;
      left: 0;
      right: 0;
    }
  }

  &.product-big-card {
    .description-card {
      padding: 20px;
      right: 0;
      left: 0;
      top: 100%;
    }
  }
}

.filter {
  .close-btn_small-screen {
    width: 40px;
    height: 40px;
  }

  &__apply-btn_wrap {
    position: fixed;
    width: 320px;
    left: 0;
    bottom: 0;
    height: 100px;
    padding: 20px;
    background-color: $bg-col-white;
    box-shadow: 0 40px 120px rgba(0, 0, 0, 0.1);

    button {
      width: 100%;
      height: 100%;
      background-color: $bg-col-sapphire;
      color: $col-white;
    }
  }
}

.cat-grid-l-fil,
.catalog-grid-l {
  .product-info {
    .description-list {
      color: $col-white;
      z-index: 9999;
      position: absolute;
      right: -350px;
      top: 0;
      background-color: $bg-col-sapphire;
      padding: 15px;
      width: 350px;
      height: fit-content;
      display: block;

      &-name {
        padding-bottom: 25px;
      }

      &-about {
        padding-bottom: 25px;
      }
    }
  }

  .product-info_tecdoc {
    .description-list {
      &-about {
        padding-bottom: unset;
        white-space: pre-line;
      }
    }
  }
}

.overlay-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.pagination-wrap__center {
  li {
    cursor: pointer;
  }

  .active {
    background-color: $bg-col-sapphire;

    a {
      color: $col-white;
    }
  }
}

.pagination-wrap {
  .select-wrap__list-reverse {
    top: unset;
    bottom: 40px;
  }
}

.toast-copy-notification {
  color: $col-white;
  background-color: $bg-col-sapphire;
  width: 280px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;

  .toast-article {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toast-label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.toast-error-notification {
  color: $col-white;
  background-color: $bg-col-red;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 280px;
  padding-right: 20px;
  padding-left: 20px;
  margin: 10px auto;

  .toast-article {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.filter_small-screen {
  height: 100%;
  min-height: 100vh;
  display: inline;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  overflow: auto;
}

.overlay-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-wrap__small-screen {
  font-family: $Manrope-Bold;
  color: $col-sapphire;
  width: 320px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $bg-col-white;
  z-index: 99999;

  &-header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid $bg-col-solitude;
  }

  &-list {
    padding: 20px;

    li {
      display: flex;
      justify-content: flex-start;
      margin: 20px 0;

      span:first-child {
        width: 20px;
        margin-right: 20px;
      }
    }
  }
}

.price-details {
  position: absolute;
  top: -50px;
  right: 0;
  padding: 20px;
  background-color: $bg-col-sapphire;
  color: $col-white;
  min-height: 60px;
  min-width: 200px;
  width: fit-content;
  z-index: 99;
  font-size: 14px;

  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &_label {
    font-family: $Manrope-Regular;
  }
}

.product-big-card__wrap,
.product-smaller-card__wrap {
  .price-details {
    position: absolute;
    top: -100%;
    left: 0;
  }
}

.cat-grid-c-fil,
.catalog-grid-c {
  .product-price {
    &.column {
      .price-details {
        top: -100%;
        left: 0;
      }
    }
  }
}

.goods-info {
  .goods-info__buy-wrapper {
    position: relative;

    .price-details {
      top: -100%;
      right: 0;
    }
  }
}

.storages-details {
  padding: 20px;
  background-color: $bg-col-sapphire;
  color: $col-white;
  font-family: $Manrope-Regular;
  position: absolute;
  right: 0;
  bottom: 100%;
  min-width: 200px;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 99;

  .storage-item {
    display: flex;
    justify-content: space-between;
    cursor: default;

    &.active {
      font-family: $Manrope-ExtraBold;
      font-size: 16px;
    }

    &__link {
      cursor: pointer;

      &:hover {
        color: $col-dodger-blue;
      }
    }
  }
}

.number-product__wrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.categories-list {
  .active {
    color: #0a2352;
  }
}

.clear-basket {
  .return {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      color: white;

      @media (hover: none) {
        color: $col-sapphire !important;
      }
    }
  }
}

.form-field_error,
.form-field_error-bottom {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: $col-red;
  font-size: 12px;

  &__offset-r {
    right: 60px;
  }
}

.form-field_char-count {
  position: absolute;
  bottom: 20px;
  left: 0;
  color: $col-manatee;
  font-size: 12px;

  &__filled {
    color: $col-red;
  }
}

.list-recomm-goods__item {
  &.accumulative-shares {
    background-color: $bg-col-astronaut;
    color: $col-white;
    padding: 20px;

    .alice-carousel {
      height: 100%;
      width: 100%;
      overflow: hidden;

      div {
        width: 100%;
        height: 100%;
      }

      .accumulative-shares__title {
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
        height: fit-content;

        h3 {
          margin: 0;
          color: $col-white;
        }
      }

      .accumulative-shares__single-chart {
        width: 160px;
        height: 160px;
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -82%);
      }

      .accumulative-shares__bottom {
        position: absolute;
        bottom: 75px;
        left: 0;
        height: fit-content;
        font-size: 16px;

        @media screen and (max-width: 1720px) {
          bottom: 0;
        }

        a {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        p {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }

      .accumulative-shares__btn {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 120px;
      }

      .alice-carousel__prev-btn-item {
        bottom: 0;
        left: 0;
      }

      .alice-carousel__next-btn-item {
        bottom: 0;
        left: 60px;
      }
    }
  }

  .alice-carousel__stage-item:not(.__active) {
    .accumulative-shares__btn,
    .accumulative-shares__bottom,
    .accumulative-shares__title {
      display: none;
    }
  }
}

.image-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 240px;
  width: 100%;
  margin-right: 15px;

  .small-squares {
    z-index: 99;
    position: absolute;
    top: unset;
    right: unset;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%) rotate(-90deg);
  }

  .discount {
    max-width: 120px;
  }

  .alice-carousel {
    width: 100%;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }

    > div {
      width: 240px;
    }

    .alice-carousel__stage {
      padding: 20px;
      // margin-right: 60px;
    }

    .alice-carousel__prev-btn-item {
      top: 50%;
      left: -30px;
      right: unset;
      bottom: unset;
      transform: translateY(-50%);
      background-color: none;
      position: absolute;
      width: 60px;
      height: 60px;
      background-color: transparent;
      background-image: URL('../images/other/arrow-left.svg');
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    .alice-carousel__next-btn-item {
      top: 50%;
      transform: translateY(-50%);
      right: -30px;
      background-color: none;
      position: absolute;
      bottom: unset;
      left: unset;
      width: 60px;
      height: 60px;
      background-color: transparent;
      background-image: URL('../images/other/arrow-right.svg');
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }

  &__item {
    height: auto;
    max-width: 200px;
    width: 100%;
    padding: 20px;

    img {
      object-fit: scale-down;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0 auto;
    max-height: 250px;
  }
}

.viewer-tool {
  color: $col-astronaut;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background-color: $bg-col-white;
}

@media (hover: none) {
  .image-slider {
    span:has(.viewer-tool) {
      opacity: 1 !important;

      @media screen and (max-width: 425px) {
        .viewer-tool__arrow {
          background-color: transparent;
        }
      }
    }
  }
}

.react-simple-image-viewer__close {
  right: 20px !important;
  top: 20px !important;
}

.react-simple-image-viewer__previous,
.react-simple-image-viewer__next {
  padding: 0 20px !important;
}

@media screen and (min-width: 1721px) {
  .info-blocks__item {
    &.accumulative-shares {
      .alice-carousel {
        .accumulative-shares__single-chart {
          width: 160px;
          height: 160px;
          position: absolute;
          top: 40%;
          right: 50%;
          transform: translate(50%, -50%);
        }

        .accumulative-shares__bottom {
          bottom: 60px;
          left: 0;
          height: fit-content;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .image-slider {
    max-width: 259px;
    padding: 10px;
  }

  .info-blocks__item {
    &.accumulative-shares {
      .alice-carousel {
        .accumulative-shares__btn {
          width: 120px;
        }

        .accumulative-shares__title {
          width: 100%;
        }
      }
    }
  }
}

.slider {
  .alice-carousel {
    height: 100%;

    div {
      height: 100%;

      .alice-carousel__wrapper {
        height: 100%;
      }
    }
  }
}

.alice-carousel__stage {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.alice-carousel__prev-btn-item {
  position: absolute;
  bottom: 20px;
  right: 80px;
  width: 60px;
  height: 60px;
  background-color: $bg-col-white;
  background-image: URL('../images/other/arrow-left.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover {
    background-color: $bg-col-solitude;
  }

  span {
    display: none;
  }
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: unset;
  width: unset;
  padding: unset;
}

.alice-carousel__next-btn-item {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: $bg-col-white;
  background-image: URL('../images/other/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &:hover {
    background-color: $bg-col-solitude;
  }

  span {
    display: none;
  }
}

.alice-carousel__dots {
  display: none;
}

.hidden-inf {
  .notification-wrap {
    .hidden-inf__header-text {
      height: 40px;
      width: 130px;
      margin: auto;
      text-align: center;
      line-height: 40px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    .hidden-inf__notification-date {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    .hidden-inf__message-wrap {
      margin-top: 15px;
      margin-bottom: 20px;
    }

    .alice-carousel__next-btn-item {
      position: absolute;
      bottom: unset;
      left: unset;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: $bg-col-sapphire;
      background-image: URL('../images/other/arrow-right-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }

    .alice-carousel__prev-btn-item {
      position: absolute;
      bottom: unset;
      right: unset;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      background-color: $bg-col-sapphire;
      background-image: URL('../images/other/arrow-left-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }
}

.modal {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  width: 90%;

  &__wrap {
    background-color: $bg-col-white;
    color: $col-sapphire;
    box-shadow: 0 0 150px 20px #e5e5e5;
  }

  .btn-close {
    width: 60px;
    height: 60px;
    background-color: transparent;

    &:hover {
      background-color: $bg-col-red;

      path {
        fill: white;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-left: 20px;
    min-height: 60px;

    border-bottom: 1px solid #e6ebf2;

    h3 {
      margin: 0;
    }
  }

  &__content {
    padding: 20px;
  }
}

.todo-block {
  position: relative;
}

.todo-block:after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: auto auto;
  background-color: rgba(255, 255, 255, 0);
  background-image: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(255, 1, 1, 0.2) 10px, rgba(255, 1, 1, 0.2) 20px);
  cursor: not-allowed;
}

.promo-products {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;
  margin-top: 40px;

  .catalog-grid-c.flip-block {
    .product-img__link .product-img__img {
      margin: 0;
    }
  }

  .catalog-grid-c.transparent {
    opacity: 0.4;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
    row-gap: 15px;

    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
    }
    h3 {
      font-size: 20px;
      line-height: 1.2;
      margin: 0 20px 0 0;
      font-family: $Manrope-ExtraBold;
      color: $col-sapphire;
      white-space: nowrap;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 8px;

    p {
      margin: 0;
      font-family: $Manrope-Medium;
      color: $col-sapphire;
      span {
        font-family: $Manrope-Bold;
        white-space: nowrap;
      }
    }
  }
}

.product-select {
  margin-top: 10px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;

  &.no-grow {
    flex-grow: 0;
  }

  &__wrapper {
    width: 100%;
    border-top: 1px solid #e6ebf2;
    padding: 20px 20px 0;
    gap: 15px;
    display: flex;
    justify-content: flex-end;
  }

  .btn {
    width: 50%;

    &.select-another {
      background-color: transparent;
    }

    &--transparent {
      background-color: transparent;
      color: $col-dodger-blue;

      &:hover {
        color: $col-sapphire;
      }
    }

    &-w-auto {
      width: auto;
      max-width: 125px;
    }
  }
}

.catalog-grid-c.flip-block {
  background-color: transparent;
  min-height: 420px;

  .front,
  .back {
    display: flex;
    flex-direction: column;
    padding: 0 0 20px;
  }

  .back.message-block {
    padding: 20px 0 20px;
  }
  .back > p {
    padding: 0 20px;
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-family: $Manrope-Bold;
  }
}

.balance-item {
  align-items: stretch;
}

.balance-label {
  display: flex;
  align-items: center;
  padding: 0 15px;
  flex-grow: 1;
  justify-content: center;
}

.balance-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #3b4f74;
  padding: 5px 20px;
  flex-grow: 1;
  max-width: 320px;
}
.balance-info__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  white-space: nowrap;
}
.balance-info__title {
  display: flex;
  align-items: center;
}

.balance-info__title-icon {
  display: flex;
  align-items: center;
}

.balance-info__title-label {
  margin-left: 10px;
}

@media (max-width: 1549px) {
  .balance-info__title-label {
    display: none;
  }
  .balance-info__row {
    justify-content: normal;
  }
}
@media (max-width: 991px) {
  .balance-info {
    padding: 5px 10px;
  }
}

@media screen and (max-width: 760px) {
  .balance-label {
    display: none;
  }
  .balance-info {
    flex-direction: row;
    max-width: 100%;
    justify-content: flex-start;
  }
  .balance-info__row {
    width: 100%;
    padding: 0 10px;
    justify-content: center;
  }
}
