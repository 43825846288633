@import 'variables.scss';
@import 'mixin';

.payment-section {
  display: flex;
  justify-content: space-between;
  color: $col-sapphire;
  &__left-side {
    display: flex;
    justify-content: space-between;

    max-width: 555px;
    width: 100%;
    margin-right: 30px;
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 555px;
    width: 100%;
  }

  .arrears-wrap {
    width: 100%;
    margin-right: 30px;

    .overdue {
      max-width: 210px;
    }

    .overdue__wrap {
      .balance-info__row:not(:last-child) {
        margin-bottom: 20px;
      }

      .balance-info__title-label {
        font-size: 14px;
        font-family: $Manrope-Medium;
      }

      @media screen and (max-width: 760px) {
        .balance-info__row {
          padding: 0;
          justify-content: flex-start;
        }
      }
    }
  }

  .arrears,
  .overdue {
    flex-direction: column;
    align-items: unset;

    &__wrap {
      font-size: 20px;
    }

    p {
      margin: 0 0 24px 0;
      padding: 0;
      text-align: start;
    }
  }

  .overdue {
    margin-bottom: 24px;
    &__wrap {
      text-align: start;
      color: #0a2352;
      background-color: transparent;
      padding: 0 0;
    }
  }
}

.profile {
  &.container {
    max-width: 1360px;
    margin: 80px auto 0;
    padding: 0;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    color: $col-sapphire;

    // &__left-side {
    //   display: flex;
    //   justify-content: space-between;

    //   max-width: 555px;
    //   width: 100%;
    //   margin-right: 30px;
    // }

    // &__right-side {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: space-between;
    //   max-width: 555px;
    //   width: 100%;
    // }
  }

  // .arrears-wrap {
  //   max-width: 210px;
  //   width: 100%;
  //   margin-right: 30px;
  // }

  // .arrears,
  // .overdue {
  //   flex-direction: column;
  //   align-items: unset;

  //   &__wrap {
  //     font-size: 20px;

  //     &_col-gamboge {
  //       color: $col-gamboge;
  //     }
  //     &_col-red {
  //       color: $col-red;
  //     }
  //     &_bg-col-gamboge {
  //       background-color: $bg-col-gamboge;
  //     }
  //     &_bg-col-red {
  //       background-color: $bg-col-red;
  //     }
  //   }

  //   p {
  //     margin: 0 0 24px 0;
  //     padding: 0;
  //     text-align: start;
  //   }
  // }

  // .overdue {
  //   margin-bottom: 24px;
  //   &__wrap {
  //     text-align: start;
  //     color: $col-sapphire;
  //     background-color: transparent;
  //     padding: 0 0;
  //   }
  // }
}

.fields-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  &__fields-list {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.fields-list {
  max-width: 555px;
  width: 100%;

  &__bg-white {
    .field {
      &__input-wrap {
        input {
          background-color: $bg-col-white;
        }
      }
    }
  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn-wrap {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 210px;
    }
  }
}

.field {
  display: flex;
  align-items: center;

  p {
    max-width: 210px;
    width: 100%;
    color: $col-sapphire;

    margin: 0;
  }

  &__input-wrap {
    position: relative;
    display: flex;
    max-width: 325px;
    width: 100%;
    margin-left: 20px;
    &.markup-percentage {
      max-width: 325px;
      .btn {
        width: 90px;
      }
    }
    .form-field {
      &_error {
        top: -20px;
        right: 0;
        bottom: unset;
      }
    }

    input {
      width: 100%;
      height: 60px;
      background-color: $bg-col-solitude-l;
      border: none;
      padding: 0 20px;

      font-family: $Manrope-Bold;
      color: $col-sapphire;

      &::placeholder {
        color: $col-manatee;
      }
    }
  }

  .select-wrap {
    max-width: 325px;
    width: 100%;
    background-color: $bg-col-white;
    margin-left: 20px;

    &:before {
      right: 25px;
    }

    &__val {
      font-family: $Manrope-Bold;
      max-width: 300px;
      // padding: 0 20px;
    }

    &__list {
      top: 60px;
      left: 0;
      width: 100%;
    }
  }
}

.qr-code {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-width: 210px;
  width: 100%;

  &__img-wrap {
    @include centering-mod-h;
    width: 120px;
    height: 120px;
    background-color: $bg-col-white;
  }

  p {
    margin: 0;
  }
}

.payment {
  display: flex;
  justify-content: space-between;

  &__img-wrap {
    margin-right: 30px;
  }

  &__desc {
    max-width: 325px;
    width: 100%;

    p {
      text-transform: uppercase;
      margin: 0 0 40px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__link-wrap {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: 210px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .field {
    p {
      max-width: 170px;
    }
  }
}

@media screen and (max-width: 1023px) {
  .payment-section {
    flex-direction: column;
    align-items: center;
    margin-top: 60px;

    &__left-side {
      margin: 0 0 20px 0;
    }
    .arrears,
    .overdue {
      p {
        display: unset;
      }
    }

    .payment {
      margin-bottom: 20px;
      &__img-wrap {
        display: flex;
        align-items: flex-end;
      }

      &__desc {
        max-width: 210px;
      }
    }
  }

  .profile {
    &__fields-wrap {
      margin-bottom: 20px;
    }

    .wrapper {
      flex-direction: column;
      align-items: center;
      margin-top: 60px;

      // &__left-side {
      //   margin: 0 0 20px 0;
      // }
    }

    // .arrears,
    // .overdue {
    //   p {
    //     display: unset;
    //   }
    // }

    // .overdue {
    //   &__wrap {
    //     padding: 20px 0;
    //   }
    // }
  }

  .fields-wrap {
    flex-direction: column;
    align-items: center;

    &__fields-list {
      margin: 0 0 20px 0;
    }
  }

  // .payment {
  //   margin-bottom: 20px;
  //   &__img-wrap {
  //     display: flex;
  //     align-items: flex-end;
  //   }

  //   &__desc {
  //     max-width: 210px;
  //   }
  // }

  .field {
    p {
      max-width: 210px;
    }
  }
}

@media screen and (max-width: 760px) {
  .payment-section {
    .arrears,
    .overdue {
      position: unset;
      height: unset;

      &__wrap {
        width: unset;
      }
    }

    .arrears {
      &__wrap {
        padding: 20px 5px;
      }
    }
  }

  .profile {
    //.arrears,
    //.overdue {
    //  position: unset;
    //  height: unset;
    //
    //  &__wrap {
    //    width: unset;
    //  }
    //}
    //
    //.arrears {
    //  &__wrap {
    //    padding: 20px 5px;
    //  }
    //}
  }
}

@media screen and (max-width: 600px) {
  .field {
    p {
      max-width: 170px;
    }
    &__input-wrap {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .payment-section {
    &__left-side {
      flex-direction: column;
      align-items: center;
    }

    .arrears-wrap {
      max-width: 325px;
      margin-right: 0;
    }

    .overdue {
      &__wrap {
        width: 210px;
      }
    }

    .payment {
      flex-direction: column;
      align-items: center;
      &__img-wrap {
        margin: 0 0 10px 0;
      }
      &__desc {
        max-width: 325px;
      }
      &__link-wrap {
        justify-content: center;
      }
    }

    .qr-code {
      align-items: center;
      p {
        margin: 10px 0;
      }
    }
  }

  .profile {
    &.container {
      margin-top: 20px;
    }

    .wrapper {
      //&__left-side {
      //  flex-direction: column;
      //  align-items: center;
      //}
      //
      //.arrears-wrap {
      //  max-width: 325px;
      //  margin-right: 0;
      //}
      //
      //.overdue {
      //  &__wrap {
      //    width: 210px;
      //  }
      //}
    }
  }

  .fields-list {
    &__btn-wrap {
      justify-content: center;
    }
  }
  .field {
    flex-direction: column;

    > p {
      max-width: 325px;
      margin-bottom: 10px;
    }

    &__input-wrap {
      margin-left: 0;
    }

    .select-wrap {
      margin-left: 0;
    }
  }

  // .profile {
  //   &.container {
  //     margin-top: 20px;
  //   }

  //   .wrapper {
  //     &__left-side {
  //       flex-direction: column;
  //       align-items: center;
  //     }

  //     .arrears-wrap {
  //       max-width: 325px;
  //       margin-right: 0;
  //     }

  //     .overdue {
  //       &__wrap {
  //         width: 210px;
  //         // margin: 0 auto 20px;
  //       }
  //     }
  //   }
  // }

  .fields-list {
    &__btn-wrap {
      justify-content: center;
    }
  }
  .field {
    flex-direction: column;

    > p {
      max-width: 325px;
      margin-bottom: 10px;
    }

    &__input-wrap {
      margin-left: 0;
    }

    .select-wrap {
      margin-left: 0;
    }
  }

  .payment {
    flex-direction: column;
    align-items: center;
    &__img-wrap {
      margin: 0 0 10px 0;
    }
    &__desc {
      max-width: 325px;
    }
    &__link-wrap {
      justify-content: center;
    }
  }

  .qr-code {
    align-items: center;
    p {
      margin: 10px 0;
    }
  }
}
