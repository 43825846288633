@import 'variables.scss';
@import 'mixin';

.brands {
  .title {
    h3 {
      margin-top: 0;
    }
  }
}

.brands-list {
  display: flex;
  flex-wrap: wrap;

  .brand-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 14.285%;
    height: 338px;
    color: $col-sapphire;

    background-color: $bg-col-white;
    border: 1px solid #e6ebf2;
    padding: 20px;
    &:hover {
      .brand-link {
        &__img-wrap {
          img {
            transform: scale(1);
          }
        }
      }
    }

    &__img-wrap {
      img {
        display: block;
        width: 120px;
        height: 120px;
        transform: scale(0.9);
        object-fit: contain;
        transition: all 0.3s linear;
        margin: 0 auto 20px;
      }
    }

    &__name {
      font-family: $Manrope-Bold;
      text-align: center;
      font-size: 16px;

      margin: 0 0 20px;
    }

    .list {
      font-size: 14px;
      .item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        &__name {
          color: $col-manatee;
          min-width: 95px;

          margin-right: 15px;
        }

        &__value {
          overflow-wrap: anywhere;
          font-family: $Manrope-Bold;
          &_colRed {
            color: $col-red;
          }
        }
      }
    }

    &__text-block {
      font-family: $Manrope-Bold;
      background-color: $bg-col-solitude;
      padding: 10px;
      height: 60px;
    }

    .name-product {
      margin: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 1536px) {
  .brands-list {
    .brand-link {
      width: 20%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .brands-list {
    .brand-link {
      width: 33.3%;
    }
  }
}

@media screen and (max-width: 700px) {
  .brands-list {
    .brand-link {
      width: 50%;
    }
  }
}

@media screen and (max-width: 480px) {
  .brands-list {
    .brand-link {
      width: 100%;
    }
  }
}
