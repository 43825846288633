@import 'variables.scss';

@media screen and (min-width: 1921px) {
  .main-header {
    .user {
      position: fixed;
      top: 0;
      right: 0;
    }
  }
}

@media screen and (max-width: 1920px) {
  .main-header {
    .user {
      position: unset;
      top: unset;
      right: unset;
    }
  }
}

@media screen and (max-width: 1880px) {
  .catalog-grid-l {
    .num-prod-title,
    .number-product {
      min-width: 180px;
      width: 180px;
    }

    .prod-price-title,
    .product-price {
      width: 10.5%;
    }

    .buy-title,
    .buy {
      width: 9.5%;
    }
  }
}

@media screen and (max-width: 1680px) {
  //begin header
  .user {
    width: unset;

    &__profile {
      display: flex;
    }

    &__wrap {
      display: none;
    }
  }
  //end header

  //begin cat-filter
  .cat-grid-l-fil {
    .num-prod-title,
    .number-product {
      min-width: 140px;
      width: 140px;
    }
  }
  //end cat-filter
}

@media screen and (max-width: 1600px) {
  .catalog-grid-l {
    .prod-name-title,
    .product-name {
      width: 24%;
    }
  }

  .cat-grid-c-fil {
    width: 32.1%;
  }

  .cat-grid-l-fil {
    .prod-name-title,
    .prod-info-title,
    .num-prod-title,
    .favorites-title,
    .buy-title {
      display: none;
    }

    .column {
      min-height: 89px;
    }

    .prod-price-title {
      display: flex;
      justify-content: flex-end;

      .label-wrap {
        margin-right: 66px;
      }
    }
  }

  .cat-grid-l-fil {
    grid-template-columns: minmax(130px, 8.2%) 38.3% 21.5% 7%;
    grid-auto-flow: column;
    grid-template-areas:
      'product-img vendor-code brand product-price product-price'
      'product-info product-name number-product favorites buy';
    justify-content: unset;
    align-items: unset;

    &_titles-row {
      grid-template-areas: unset;
      grid-template-columns: minmax(130px, 8.2%) 38.3% 25% 1fr;
      grid-template-rows: unset;
    }

    .product-img {
      grid-area: product-img;
    }

    .product-name {
      grid-area: product-name;
    }

    .vendor-code {
      grid-area: vendor-code;
    }

    .brand {
      grid-area: brand;
    }

    .product-price {
      grid-area: product-price;
      padding-right: 15px;
    }

    .product-info {
      grid-area: product-info;
      justify-content: center;
    }

    .number-product {
      grid-area: number-product;
      width: unset;
    }

    .favorites {
      grid-area: favorites;
    }

    .buy {
      grid-area: buy;
    }
  }
}

@media screen and (max-width: 1540px) {
  .nav-bottom {
    &__result {
      display: flex;
      align-items: center;
    }
  }

  .cat-fil-wrap {
    .catalog-grid-l {
      .num-prod-title,
      .number-product {
        width: 11%;
      }

      .prod-price-title,
      .product-price {
        width: 15%;
      }

      .buy-title,
      .buy {
        width: 14%;
      }
    }
  }

  // .catalog-grid-l {
  //   .num-prod-title,
  //   .number-product {
  //     min-width: 140px;
  //     width: 140px;
  //   }
  // }

  .catalog-grid-c,
  .cnt-row-delete.card {
    width: 23.8%;
  }
}

@media screen and (max-width: 1440px) {
  .menu-wrap {
    max-width: 120px;

    .burger-wrap {
      > strong {
        display: none;
      }
    }
  }

  .search {
    max-width: 380px;

    .history-search {
      width: 320px;
    }

    &__input-w {
      max-width: 122.4px;
    }

    .select-wrap {
      min-width: 134px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .catalog-grid-l {
    .column {
      padding: 5px;
    }

    .vendor-code-title,
    .vendor-code {
      width: 15.3%;
    }

    .brand {
      flex-direction: column;
      justify-content: center;

      &__img {
        // margin-right: 6%;
        max-width: 40px;
        margin-right: 0;
      }
      &__text {
        margin: 0;
      }
    }

    .prod-name-title,
    .product-name {
      width: 21%;
    }

    .prod-info-title,
    .product-info {
      min-width: 40px;
    }
    .prod-price-title,
    .product-price {
      min-width: 149px;

      &__cost {
        margin-right: 5px;
      }
    }

    .num-prod-title,
    .number-product {
      min-width: 135px;
      width: 135px;
    }

    .product-count {
      font-size: 12px;
      width: 25px;
      height: 25px;

      margin: 3px;
    }

    .favorites-title,
    .favorites {
      min-width: 40px;
    }
  }
}

@media screen and (max-width: 1365px) {
  .nav-bottom {
    &.catalog-cart {
      .nav-bottom {
        &__left {
          .title {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .cat-grid-c-fil {
    width: 48.8%;
  }

  .catalog-grid-c,
  .cnt-row-delete.card {
    width: 32.1%;
  }
}

@media screen and (max-width: 1279px) {
  .nav-bottom {
    &__left {
      width: 60%;
      flex-direction: unset;
      align-items: center;
    }

    &__center {
      display: none;
    }

    &__right {
      width: 40%;
    }
  }
}

@media screen and (max-width: 1250px) {
  //begin header
  .main-header {
    .alter-container {
      justify-content: space-between;
    }

    .mobile-search {
      display: block;
    }
  }

  .menu-wrap {
    max-width: 180px;

    &__search {
      display: flex;
    }
  }

  .inf-client {
    &__arrears-wrap {
      justify-content: end;
    }
  }

  .search {
    display: none;
  }
  //end header

  .nav-bottom {
    &.catalog-cart {
      .nav-bottom {
        &__left {
          flex-direction: column;
          align-items: unset;

          margin-top: 20px;

          .select-wrap {
            width: max-content;
            min-width: 130px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .nav-bottom {
    &.catalog-cart-filter {
      .nav-bottom {
        &__select-wrap {
          display: none;
        }
      }
    }

    &__result {
      min-height: unset;
    }

    &__left {
      width: 42%;
    }

    &__right {
      width: 58%;
    }

    &__result-wrap {
      flex-direction: column;
      align-items: unset;
      min-height: unset;
    }

    &__nav-buttons {
      margin-bottom: 20px;
    }
  }

  .nav-buttons {
    display: flex;
  }

  .arrears,
  .overdue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 455px;
    width: 100%;

    &__wrap {
      min-width: 110px;
      padding: 20px 5px;
    }

    &__text-h {
      display: block;
      padding: 0 10px;
    }

    > p {
      display: none;
    }
  }

  .catalog-grid-c,
  .cnt-row-delete.card {
    width: 48.8%;
  }

  .filter {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  //begin header
  .main-header {
    padding: 20px 0;
  }

  .menu-wrap {
    &__search {
      background-color: transparent;
    }
  }

  .inf-client {
    justify-content: flex-end;

    &__arrears-wrap {
      height: 100px;
      position: absolute;
      top: -20px;
      left: 0;
    }
  }

  .arrears,
  .overdue {
    max-width: 760px;
    width: 100%;
    height: 20px;
    position: absolute;

    &__text-h {
      width: 50%;
      background-color: $bg-col-astronaut;
    }

    &__wrap {
      width: 50%;
      padding: 0 5px;
    }
  }

  .arrears {
    top: 0;
    left: 0;
  }

  .overdue {
    bottom: 0;
    left: 0;
  }

  .user {
    &__basket {
      position: relative;
      display: flex;

      .count {
        top: 10px;
        right: 10px;
      }
    }

    &__log-out {
      display: none;
    }
  }
  //end header

  .nav-bottom {
    &.catalog-cart {
      .nav-bottom {
        &__left {
          margin-top: 0;

          .select-wrap {
            margin-top: 20px;
            min-height: 40px;
          }
        }
      }
    }

    &__top-wrap {
      min-height: unset;
      padding: 20px 0;
    }

    &__left {
      max-width: unset;
      width: 100%;
    }

    &__right {
      display: none;
    }
  }

  .alter-container {
    &.content {
      padding: 0 0 60px 0;
    }
  }

  .sidebar {
    display: none;
  }

  .nav-bottom {
    .title {
      margin-right: 0;
    }

    &__result-wrap {
      max-width: unset;
    }
  }
}

@media screen and (max-width: 600px) {
  .cat-grid-c-fil,
  .catalog-grid-c,
  .cnt-row-delete.card {
    width: 100%;
  }

  .main-header {
    .mobile-search {
      z-index: 999999;
      position: fixed;
      width: 100%;
      height: 100%;

      color: $col-sapphire;

      .history-search {
        width: 100vw;
        li {
          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: 1px solid #e6ebf2;
          }
        }
      }

      &__top-wrap {
        display: flex;
        align-items: center;
        font-family: $Manrope-Bold;
        padding-left: 20px;
        height: 60px;
      }
      &__wrap {
        position: relative;
        width: 100%;
      }

      &__left-side {
        background-color: $bg-col-solitude;
        input {
          background-color: $bg-col-solitude !important;
        }
      }

      &.active {
        left: 0;
        .btn-close {
          position: absolute;
          top: -60px;
          right: 0;
        }
      }

      .select-wrap {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(calc(-50% + 30px));
      }
    }

    .search {
      &__message {
        width: 100vw;
        top: 120px;
      }
      &__btn-history-s {
        border-top: 1px solid #e6ebf2;
        border-bottom: 1px solid #e6ebf2;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .main-header {
    .nav-wrap {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: auto;
      height: auto;
    }
  }
}
