@import 'variables.scss';
@import 'mixin';

.notification-list {
  max-width: 900px;
  width: 100%;
  margin: 0 auto 20px;

  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;

  &__group-title {
    h3 {
      margin-top: 0;
    }
  }

  &__item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .item {
    position: relative;
    background-color: $bg-col-white;
    color: $col-manatee;
    transition: 0.3s;
    padding-bottom: 20px;

    &_products {
      .item {
        &__desc-wrap {
          padding: 0;
        }

        &__desc-wrap {
          max-height: 120px;
        }
      }

      .title-mob {
        display: none;

        font-family: $Manrope-Regular;
        color: $col-sapphire;
        margin-bottom: 10px;
      }
    }

    &_poll {
      background-image: url('../images/other/question-mark.svg');
      background-repeat: no-repeat;
      background-position: top 50px right 0;

      .label-wrap {
        display: flex;
        transition: 0.3s;
        max-width: fit-content;

        padding: 12px 0;

        font-family: $Manrope-Medium;
        color: $col-sapphire;

        &:hover {
          cursor: pointer;
          color: $col-dodger-blue;
        }

        input {
          flex-shrink: 0;
          margin: 2.3px 24px 0 0;

          &:checked {
            & + span {
              font-family: $Manrope-Bold;
            }
          }
        }

        input[type='checkbox'] {
          border: 2px solid $col-manatee;
          border-radius: 4px;

          &:checked {
            border-color: $col-dodger-blue;
          }

          &::after {
            top: 5px;
            left: 2px;
          }

          &::before {
            top: 1px;
            left: 6px;
          }
        }
      }

      .btn-wrap {
        display: flex;
        justify-content: flex-end;
        padding: 0 20px;

        @media screen and (max-width: 480px) {
          justify-content: center;
        }
      }

      .item {
        &__desc-wrap {
          max-height: unset;
          display: flex;
          flex-direction: column;

          max-width: 430px;
          width: 100%;

          padding: 0 20px 0 40px;
          margin-bottom: 12px;
        }

        &__title {
          margin-bottom: 40px;

          @media screen and (max-width: 959px) {
            margin-bottom: 30px;
          }
        }

        &__text-rule {
          margin-bottom: 12px;
        }
      }
    }

    .list {
      li {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__title {
        display: block;
        width: 50%;
        margin-right: 30px;
      }

      &__val {
        display: block;

        // width: 50%;
        a {
          color: $col-dodger-blue;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .btn-review {
      bottom: 10px;
      right: 20px;
    }

    &__wrap {
      padding: 20px 20px 0 20px;
    }

    &__top-wrap {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__info-status {
      position: relative;
      flex-shrink: 0;
      margin-right: 30px;

      &_hidden {
        .item {
          &__status {
            width: 0;
            margin-right: 0;
            height: 0;
          }

          &__date {
            margin-left: 0;
          }
        }
      }
    }

    &__title {
      margin-bottom: 20px;
    }

    &__title {
      h4 {
        margin: 0;
      }
    }

    &__status {
      transition: 0.3s;
      display: inline-block;
      width: 10px;
      height: 10px;

      background-color: $bg-col-red;
      border-radius: 50%;
      position: absolute;
      top: 5px;
    }

    &__date {
      transition: 0.3s;
      margin-left: 30px;
    }

    &__time {
      &_adapt {
        display: none;
      }
    }

    &__desc-wrap {
      transition: 0.3s;
      padding: 0 20px;

      max-height: 60px;
      overflow: hidden;
      width: 100%;
      margin-bottom: 20px;
    }

    &__btns-wrap {
      transition: 0.3s;
      opacity: 0;
      display: flex;
      font-family: $Manrope-Bold;
      height: 20px;

      .btn-change,
      .btn-close {
        position: relative;
        padding: 0;
        display: block;
        width: auto;
        height: auto;
        background-color: transparent;
        text-align: start;
        white-space: nowrap;

        &:before {
          transition: 0.3s;
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          bottom: 0;
        }
      }

      .btn-change {
        color: $col-dodger-blue;
        margin-right: 15px;

        &:hover {
          &:before {
            width: 100%;
            background-color: $bg-col-dodger-blue;
          }
        }
      }

      .btn-close {
        color: $col-red;

        &:hover {
          &:before {
            width: 100%;
            background-color: $bg-col-red;
          }
        }
      }
    }

    &__btn-adapt {
      display: none;
    }

    &:hover {
      .item {
        &__btns-wrap {
          opacity: 1;
        }
      }
    }

    transition: all 0.5s ease-in-out;

    &.deleted {
      opacity: 0;
      pointer-events: none;
      user-select: none;

      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .column {
    display: flex;
    align-items: center;
    padding: 5px 20px;
  }

  .titles-row,
  .cnt-row {
    padding: 0;
  }

  .cnt-row {
    min-height: 60px;
  }

  .vendor-code-title,
  .vendor-code {
    width: 28%;

    .btn-copy {
      margin-right: 6%;
    }
  }

  .brand-title,
  .brand {
    width: 23%;
  }

  .brand {
    font-family: $Manrope-Bold;
    text-transform: uppercase;

    p {
      margin: 0;
    }
  }

  .product-name-title,
  .product-name {
    width: 60%;
  }

  .product-name {
    color: $col-manatee;

    &__text {
      font-family: $Manrope-Bold;
      color: $col-sapphire;
    }
  }
}

@media screen and (max-width: 1279px) {
  .notification-list {
    .item {
      &__btns-wrap {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .notification-list {
    .item {
      .btn-change {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .notification-list {
    .item {
      .list {
        li {
          flex-direction: column;
        }

        &__title,
        &__val {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    .cnt-row-delete {
      padding-left: 0;

      .return {
        position: absolute;
        top: 20px;
        right: 0;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .notification-list {
    .column {
      padding: 10px;
    }

    .item {
      .titles-row {
        display: none;
      }

      .cnt-row {
        padding: 0 10px 10px;
        flex-wrap: wrap;
        align-items: unset;
      }

      &_products {
        .item {
        }

        .title-mob {
          display: block;
        }

        .column {
          flex-direction: column;
          align-items: unset;
        }

        .vendor-code,
        .brand {
          width: 50%;
        }

        .product-name {
          width: 100%;
        }
      }

      &__btn-desktop {
        display: none;
      }

      &__btn-adapt {
        display: block;
      }

      .btn-change {
        margin-right: 40px;
      }

      .btn-close {
        @include centering-mod-h;
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 60px;

        &:hover {
          background-color: unset;

          svg {
            path {
              fill: $bg-col-red;
            }
          }

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .notification-list {
    .item {
      &__info-status {
        margin-right: 15px;
      }

      &__date {
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .notification-list {
    .item {
      &__top-wrap {
        margin-bottom: 10px;
      }

      &__time {
        &_desktop {
          display: none;
        }

        &_adapt {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
}
