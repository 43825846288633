@import 'variables.scss';
@import 'mixin';
.login {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $bg-col-sapphire;
    z-index: -1;
  }

  &__container-m {
    max-width: 550px;
    padding: 20px 15px;
  }

  &__img-wrap {
    width: 366px;
    text-align: center;
    margin: 0 auto 35px;
    span {
      color: $col-white;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    text-decoration: underline;
  }

  .form {
    background-color: $bg-col-white;
    padding: 100px;
    margin-bottom: 40px;
    &__title {
      text-align: center;
      margin-bottom: 20px;
      h3 {
        margin: 0;
      }
    }

    &__btn-wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      a {
        margin-bottom: 40px;
      }

      .btn {
        font-size: 20px;
        width: 100%;
      }
    }
  }
}

.list-fields {
  .item {
    position: relative;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 40px;
    }
    input {
      width: 100%;
      height: 60px;
      background-color: $bg-col-solitude;
      border: none;
      padding: 20px;
      &::placeholder {
        color: $col-manatee;
      }
    }

    &__title {
      font-family: $Manrope-Bold;
      color: $col-sapphire;
      display: block;
      margin-bottom: 20px;
    }

    &__input-wrap {
      max-width: 320px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .login {
    background-color: $bg-col-white;
    &:before {
      display: none;
    }
    &__img-wrap {
      display: none;
    }

    &__link {
      color: $col-dodger-blue;
    }

    .form {
      max-width: 320px;
      width: 100%;

      margin: 0 auto 40px;
      padding: 0;
      &__btn-wrap {
        .btn {
          //min-width: 290px;
        }
      }
    }
  }

  .list-fields {
    .item {
      &__input-wrap {
      }
    }
  }
}
